.os-size-observer, .os-size-observer-listener {
  direction: inherit;
  pointer-events: none;
  visibility: hidden;
  box-sizing: border-box;
  overflow: hidden;
}

.os-size-observer, .os-size-observer-listener, .os-size-observer-listener-item, .os-size-observer-listener-item-final {
  writing-mode: horizontal-tb;
  position: absolute;
  top: 0;
  left: 0;
}

.os-size-observer {
  z-index: -1;
  contain: strict;
  padding: inherit;
  border: inherit;
  box-sizing: inherit;
  flex-flow: row;
  margin: -133px;
  display: flex;
  inset: 0;
  transform: scale(.1);
}

.os-size-observer:before {
  content: "";
  box-sizing: inherit;
  width: 10px;
  height: 10px;
  flex: none;
  padding: 10px;
}

.os-size-observer-appear {
  animation: 1ms forwards os-size-observer-appear-animation;
}

.os-size-observer-listener {
  box-sizing: border-box;
  padding: inherit;
  border: inherit;
  flex: auto;
  margin: -133px;
  position: relative;
  transform: scale(10);
}

.os-size-observer-listener.ltr {
  margin-left: 0;
  margin-right: -266px;
}

.os-size-observer-listener.rtl {
  margin-left: -266px;
  margin-right: 0;
}

.os-size-observer-listener:empty:before {
  content: "";
  width: 100%;
  height: 100%;
}

.os-size-observer-listener:empty:before, .os-size-observer-listener > .os-size-observer-listener-item {
  padding: inherit;
  border: inherit;
  box-sizing: content-box;
  flex: auto;
  display: block;
  position: relative;
}

.os-size-observer-listener-scroll {
  box-sizing: border-box;
  display: flex;
}

.os-size-observer-listener-item {
  direction: ltr;
  flex: none;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

.os-size-observer-listener-item-final {
  transition: none;
}

@keyframes os-size-observer-appear-animation {
  from {
    cursor: auto;
  }

  to {
    cursor: none;
  }
}

.os-trinsic-observer {
  box-sizing: border-box;
  max-width: 0;
  max-height: 1px;
  z-index: -1;
  height: 0;
  contain: strict;
  border: none;
  flex: none;
  margin: 0;
  padding: 0;
  position: relative;
  top: calc(100% + 1px);
  overflow: hidden;
}

.os-trinsic-observer:not(:empty) {
  height: calc(100% + 1px);
  top: -1px;
}

.os-trinsic-observer:not(:empty) > .os-size-observer {
  width: 1000%;
  height: 1000%;
  min-height: 1px;
  min-width: 1px;
}

.os-environment {
  --os-custom-prop: -1;
  opacity: 0;
  visibility: hidden;
  height: 200px;
  width: 200px;
  z-index: var(--os-custom-prop);
  position: fixed;
  overflow: scroll;
}

.os-environment div {
  width: 200%;
  height: 200%;
  margin: 10px 0;
}

.os-environment.os-environment-flexbox-glue {
  height: auto;
  width: auto;
  min-height: 200px;
  min-width: 200px;
  flex-flow: row;
  display: flex;
}

.os-environment.os-environment-flexbox-glue div {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  flex: auto;
  margin: 0;
}

.os-environment.os-environment-flexbox-glue-max {
  max-height: 200px;
}

.os-environment.os-environment-flexbox-glue-max div {
  overflow: visible;
}

.os-environment.os-environment-flexbox-glue-max div:before {
  content: "";
  height: 999px;
  width: 999px;
  display: block;
}

.os-environment, .os-viewport {
  -ms-overflow-style: scrollbar !important;
}

[data-overlayscrollbars-initialize], [data-overlayscrollbars~="scrollbarHidden"], .os-viewport-scrollbar-hidden.os-environment, .os-viewport-scrollbar-hidden.os-viewport {
  scrollbar-width: none !important;
}

[data-overlayscrollbars-initialize]::-webkit-scrollbar, [data-overlayscrollbars-initialize]::-webkit-scrollbar-corner, [data-overlayscrollbars~="scrollbarHidden"]::-webkit-scrollbar, [data-overlayscrollbars~="scrollbarHidden"]::-webkit-scrollbar-corner, .os-viewport-scrollbar-hidden.os-environment::-webkit-scrollbar, .os-viewport-scrollbar-hidden.os-environment::-webkit-scrollbar-corner, .os-viewport-scrollbar-hidden.os-viewport::-webkit-scrollbar, .os-viewport-scrollbar-hidden.os-viewport::-webkit-scrollbar-corner {
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 0 !important;
  height: 0 !important;
  display: none !important;
}

[data-overlayscrollbars-initialize] {
  overflow: auto;
}

html[data-overlayscrollbars], html.os-viewport-scrollbar-hidden, html.os-viewport-scrollbar-hidden > body {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
}

html[data-overlayscrollbars] > body {
  overflow: visible;
}

[data-overlayscrollbars~="host"] {
  position: relative;
}

[data-overlayscrollbars~="host"], .os-padding {
  display: flex;
  flex-flow: row !important;
}

.os-padding, .os-viewport {
  box-sizing: inherit;
  height: auto;
  width: 100%;
  z-index: 0;
  border: none;
  margin: 0;
  padding: 0;
  position: relative;
  flex: auto !important;
}

.os-viewport {
  --os-vaw: 0;
  --os-vah: 0;
}

.os-viewport.os-viewport-arrange:before {
  content: "";
  pointer-events: none;
  z-index: -1;
  min-width: 1px;
  min-height: 1px;
  width: var(--os-vaw);
  height: var(--os-vah);
  position: absolute;
}

[data-overlayscrollbars~="host"], [data-overlayscrollbars~="viewport"] {
  overflow: hidden;
}

[data-overlayscrollbars~="overflowVisible"] {
  overflow: visible;
}

[data-overlayscrollbars-overflow-x="hidden"] {
  overflow-x: hidden;
}

[data-overlayscrollbars-overflow-x="scroll"] {
  overflow-x: scroll;
}

[data-overlayscrollbars-overflow-x="hidden"] {
  overflow-y: hidden;
}

[data-overlayscrollbars-overflow-y="scroll"] {
  overflow-y: scroll;
}

.os-padding, .os-viewport {
  overflow: hidden;
}

.os-overflow-visible {
  overflow: visible;
}

.os-content {
  box-sizing: inherit;
}

[data-overlayscrollbars-grid], [data-overlayscrollbars-grid] .os-padding {
  grid-template: 1fr / 1fr;
  display: grid;
}

[data-overlayscrollbars-grid] > .os-padding, [data-overlayscrollbars-grid] > .os-viewport, [data-overlayscrollbars-grid] > .os-padding > .os-viewport {
  height: auto !important;
  width: auto !important;
}

.os-scrollbar {
  contain: strict;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s, top .3s, right .3s, bottom .3s, left .3s;
  position: absolute;
}

body > .os-scrollbar {
  z-index: 99999;
  position: fixed;
}

.os-scrollbar-transitionless {
  transition: none;
}

.os-scrollbar-track {
  position: relative;
  direction: ltr !important;
  border: none !important;
  padding: 0 !important;
}

.os-scrollbar-handle {
  position: absolute;
}

.os-scrollbar-track, .os-scrollbar-handle {
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.os-scrollbar.os-scrollbar-track-interactive .os-scrollbar-track, .os-scrollbar.os-scrollbar-handle-interactive .os-scrollbar-handle {
  pointer-events: auto;
  touch-action: none;
}

.os-scrollbar-horizontal {
  bottom: 0;
  left: 0;
}

.os-scrollbar-vertical {
  top: 0;
  right: 0;
}

.os-scrollbar-rtl.os-scrollbar-horizontal {
  right: 0;
}

.os-scrollbar-rtl.os-scrollbar-vertical {
  left: 0;
  right: auto;
}

.os-scrollbar-visible, .os-scrollbar-interaction.os-scrollbar-visible {
  opacity: 1;
  visibility: visible;
}

.os-scrollbar-auto-hidden {
  opacity: 0;
  visibility: hidden;
}

.os-scrollbar-unusable, .os-scrollbar-unusable *, .os-scrollbar-wheel, .os-scrollbar-wheel * {
  pointer-events: none !important;
}

.os-scrollbar-unusable .os-scrollbar-handle {
  opacity: 0 !important;
}

.os-scrollbar.os-scrollbar-horizontal.os-scrollbar-cornerless, .os-scrollbar.os-scrollbar-horizontal.os-scrollbar-cornerless.os-scrollbar-rtl {
  left: 0;
  right: 0;
}

.os-scrollbar.os-scrollbar-vertical.os-scrollbar-cornerless, .os-scrollbar.os-scrollbar-vertical.os-scrollbar-cornerless.os-scrollbar-rtl {
  top: 0;
  bottom: 0;
}

[data-overlayscrollbars~="updating"] > .os-scrollbar, .os-theme-none.os-scrollbar {
  display: none !important;
}

.os-theme-dark.os-scrollbar-horizontal, .os-theme-light.os-scrollbar-horizontal {
  height: 10px;
  right: 10px;
}

.os-theme-dark.os-scrollbar-vertical, .os-theme-light.os-scrollbar-vertical {
  width: 10px;
  bottom: 10px;
}

.os-theme-dark.os-scrollbar-rtl.os-scrollbar-horizontal, .os-theme-light.os-scrollbar-rtl.os-scrollbar-horizontal {
  left: 10px;
  right: 0;
}

.os-theme-dark.os-scrollbar, .os-theme-light.os-scrollbar {
  box-sizing: border-box;
  background: none;
  padding: 2px;
}

.os-theme-dark.os-scrollbar-unusable, .os-theme-light.os-scrollbar-unusable, .os-theme-dark.os-scrollbar > .os-scrollbar-track, .os-theme-light.os-scrollbar > .os-scrollbar-track {
  background: none;
}

.os-theme-dark.os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle, .os-theme-light.os-scrollbar-horizontal > .os-scrollbar-track > .os-scrollbar-handle {
  min-width: 30px;
}

.os-theme-dark.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle, .os-theme-light.os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle {
  min-height: 30px;
}

.os-theme-dark.os-scrollbar-transition > .os-scrollbar-track > .os-scrollbar-handle, .os-theme-light.os-scrollbar-transition > .os-scrollbar-track > .os-scrollbar-handle {
  transition: background-color .3s;
}

.os-theme-dark.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle, .os-theme-light.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle, .os-theme-dark.os-scrollbar > .os-scrollbar-track, .os-theme-light.os-scrollbar > .os-scrollbar-track {
  border-radius: 10px;
}

.os-theme-dark.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: #0006;
}

.os-theme-light.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background: #fff6;
}

.os-theme-dark.os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle {
  background: #0000008c;
}

.os-theme-light.os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle {
  background: #ffffff8c;
}

.os-theme-dark.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active {
  background: #000000b3;
}

.os-theme-light.os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle.active {
  background: #ffffffb3;
}

.os-theme-dark.os-scrollbar-horizontal .os-scrollbar-handle:before, .os-theme-dark.os-scrollbar-vertical .os-scrollbar-handle:before, .os-theme-light.os-scrollbar-horizontal .os-scrollbar-handle:before, .os-theme-light.os-scrollbar-vertical .os-scrollbar-handle:before {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
}

.os-theme-dark.os-host-scrollbar-hidden > .os-scrollbar-handle:before {
  display: none;
}

.os-theme-dark.os-scrollbar-horizontal .os-scrollbar-handle:before, .os-theme-light.os-scrollbar-horizontal .os-scrollbar-handle:before {
  top: -6px;
  bottom: -2px;
}

.os-theme-dark.os-scrollbar-vertical .os-scrollbar-handle:before, .os-theme-light.os-scrollbar-vertical .os-scrollbar-handle:before {
  left: -6px;
  right: -2px;
}

.os-theme-dark.os-scrollbar-rtl.os-scrollbar-vertical .os-scrollbar-handle:before, .os-theme-light.os-scrollbar-rtl.os-scrollbar-vertical .os-scrollbar-handle:before {
  left: -2px;
  right: -6px;
}

@keyframes rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

:root {
  --font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-display-1: 700 48 / 56px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-display-2: 700 40px / 48px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-display-3: 700 32px / 40px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-1: 600 32px / 40px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-2: 600 28px / 36px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-3: 600 24px / 32px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-1: 600 20px / 28px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-2: 600 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-3: 600 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-1: 500 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-2: 500 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-3: 500 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-0: 400 18px / 26px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-1: 400 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-2: 400 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-3: 400 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-section-head-1: 700 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-section-head-2: 700 10px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
}

body {
  background: var(--color-base);
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  box-sizing: border-box;
}

input {
  border: none;
  outline: none;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar:horizontal {
  height: 16px;
}

::-webkit-scrollbar-thumb {
  background: var(--color-neutral-variant-80);
  min-height: 40px;
  background-clip: content-box;
  border: 4px solid #0000;
  border-radius: 12px;
}

::-webkit-scrollbar-track {
  background: none;
}

.appInsightsWrapper {
  width: 100%;
  height: 100%;
  flex: 1;
}

.os-scrollbar.os-theme-dark.os-scrollbar-vertical, .os-scrollbar.os-theme-light.os-scrollbar-vertical {
  width: 12px;
  padding: 8px 2px;
}

.os-scrollbar.os-theme-dark.os-scrollbar-vertical .os-scrollbar-handle, .os-scrollbar.os-theme-light.os-scrollbar-vertical .os-scrollbar-handle {
  min-height: 40px;
}

.os-scrollbar.os-theme-dark.os-scrollbar-horizontal, .os-scrollbar.os-theme-light.os-scrollbar-horizontal {
  height: 12px;
  padding: 2px 8px;
}

.os-scrollbar.os-theme-dark.os-scrollbar-horizontal .os-scrollbar-handle, .os-scrollbar.os-theme-light.os-scrollbar-horizontal .os-scrollbar-handle {
  min-width: 40px;
}

.os-scrollbar.os-theme-dark .os-scrollbar-track .os-scrollbar-handle, .os-scrollbar.os-theme-light .os-scrollbar-track .os-scrollbar-handle, .os-scrollbar.os-theme-dark.os-scrollbar:hover .os-scrollbar-track .os-scrollbar-handle, .os-scrollbar.os-theme-light.os-scrollbar:hover .os-scrollbar-track .os-scrollbar-handle {
  background: var(--color-neutral-variant-80);
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: var(--fontsource-display, swap);
  font-weight: 400;
  src: url("roboto-mono-cyrillic-ext-400-normal.dd6d6486.woff2") format("woff2"), url("roboto-mono-cyrillic-ext-400-normal.5c585b34.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: var(--fontsource-display, swap);
  font-weight: 400;
  src: url("roboto-mono-cyrillic-400-normal.10dad15d.woff2") format("woff2"), url("roboto-mono-cyrillic-400-normal.aea21066.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: var(--fontsource-display, swap);
  font-weight: 400;
  src: url("roboto-mono-greek-400-normal.f2269253.woff2") format("woff2"), url("roboto-mono-greek-400-normal.d3c888db.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: var(--fontsource-display, swap);
  font-weight: 400;
  src: url("roboto-mono-vietnamese-400-normal.1d3f7e28.woff2") format("woff2"), url("roboto-mono-vietnamese-400-normal.d82ce04a.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: var(--fontsource-display, swap);
  font-weight: 400;
  src: url("roboto-mono-latin-ext-400-normal.5ce7dbab.woff2") format("woff2"), url("roboto-mono-latin-ext-400-normal.c4647385.woff") format("woff");
  unicode-range: U+100-2AF, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: var(--fontsource-display, swap);
  font-weight: 400;
  src: url("roboto-mono-latin-400-normal.7b49cb08.woff2") format("woff2"), url("roboto-mono-latin-400-normal.46565364.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+300-301, U+303-304, U+308-309, U+323, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@keyframes pXtXgq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes pXtXgq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.pXtXgq_ring {
  animation: 1s ease-in-out infinite pXtXgq_rotating;
}

.pXtXgq_daisy {
  animation: 1s steps(12, end) infinite pXtXgq_rotating;
}

._2eodma_light {
  --color-all-0: #000;
  --color-all-100: #fff;
  --color-primary-10: #190064;
  --color-primary-20: #2d009d;
  --color-primary-30: #4300da;
  --color-primary-40: #5d34f2;
  --color-primary-50: #7958ff;
  --color-primary-60: #947dff;
  --color-primary-70: #af9eff;
  --color-primary-80: #cabeff;
  --color-primary-90: #e6deff;
  --color-primary-95: #f5eeff;
  --color-primary-99: #fffbff;
  --color-secondary-10: #201c00;
  --color-secondary-20: #373100;
  --color-secondary-30: #4f4700;
  --color-secondary-40: #695f00;
  --color-secondary-50: #847900;
  --color-secondary-60: #9f930d;
  --color-secondary-70: #baad2d;
  --color-secondary-80: #d7c947;
  --color-secondary-90: #f4e560;
  --color-secondary-95: #fff480;
  --color-secondary-99: #fffbf7;
  --color-tertiary-10: #350041;
  --color-tertiary-20: #560068;
  --color-tertiary-30: #7b0093;
  --color-tertiary-40: #9927af;
  --color-tertiary-50: #b545ca;
  --color-tertiary-60: #d361e7;
  --color-tertiary-70: #f07eff;
  --color-tertiary-80: #faabff;
  --color-tertiary-90: #ffd5ff;
  --color-tertiary-95: #ffeafe;
  --color-tertiary-99: #fcfcfc;
  --color-error-10: #410001;
  --color-error-20: #680003;
  --color-error-30: #930006;
  --color-error-40: #ba1b1b;
  --color-error-50: #dd3730;
  --color-error-60: #ff5449;
  --color-error-70: #ff897a;
  --color-error-80: #ffb4a9;
  --color-error-90: #ffdad4;
  --color-error-95: #ffede9;
  --color-error-99: #fcfcfc;
  --color-neutral-10: #191c1d;
  --color-neutral-20: #2d3132;
  --color-neutral-30: #444748;
  --color-neutral-40: #5c5f60;
  --color-neutral-50: #747778;
  --color-neutral-60: #8e9192;
  --color-neutral-70: #a9acac;
  --color-neutral-80: #c4c7c7;
  --color-neutral-90: #e0e3e3;
  --color-neutral-95: #eff1f1;
  --color-neutral-99: #f7f8f8;
  --color-neutral-variant-10: #1b1b22;
  --color-neutral-variant-20: #302f38;
  --color-neutral-variant-30: #47464e;
  --color-neutral-variant-40: #5f5d67;
  --color-neutral-variant-50: #78767f;
  --color-neutral-variant-60: #928f9a;
  --color-neutral-variant-70: #adaab4;
  --color-neutral-variant-80: #c9c5d0;
  --color-neutral-variant-90: #e5e1ec;
  --color-neutral-variant-95: #f3effa;
  --color-neutral-variant-99: #fffbff;
  --color-success-10: #002106;
  --color-success-20: #00390b;
  --color-success-30: #005314;
  --color-success-40: #116d26;
  --color-success-50: #32873d;
  --color-success-60: #4ea254;
  --color-success-70: #68be6c;
  --color-success-80: #83da85;
  --color-success-90: #9fe79f;
  --color-success-95: #bdefbd;
  --color-success-99: #ebf9eb;
  --color-alert-10: #2b1700;
  --color-alert-20: #472a00;
  --color-alert-30: #663e00;
  --color-alert-40: #865300;
  --color-alert-50: #a76900;
  --color-alert-60: #ca8000;
  --color-alert-70: #eb9918;
  --color-alert-80: #ffb95a;
  --color-alert-90: #ffddb5;
  --color-alert-95: #ffeedc;
  --color-alert-99: #fff5eb;
  --color-static-white: #fff;
  --color-primary: var(--color-primary-40);
  --color-on-primary: var(--color-all-100);
  --color-primary-container: var(--color-primary-90);
  --color-on-primary-container: var(--color-primary-10);
  --color-secondary: var(--color-secondary-40);
  --color-on-secondary: var(--color-all-100);
  --color-secondary-container: var(--color-secondary-30);
  --color-on-secondary-container: var(--color-secondary-10);
  --color-tertiary: var(--color-tertiary-40);
  --color-on-tertiary: var(--color-all-100);
  --color-tertiary-container: var(--color-tertiary-90);
  --color-on-tertiary-container: var(--color-tertiary-10);
  --color-error: var(--color-error-40);
  --color-error-hover: var(--color-error-50);
  --color-error-container: var(--color-error-95);
  --color-on-error-container: var(--color-error-50);
  --color-alert-container: var(--color-alert-95);
  --color-on-alert-container: var(--color-alert-70);
  --color-success-container: var(--color-success-99);
  --color-on-success-container: var(--color-success-70);
  --color-info-container: var(--color-neutral-variant-90);
  --color-on-info-container: var(--color-neutral-variant-60);
  --color-background: var(--color-neutral-99);
  --color-on-background: var(--color-neutral-10);
  --color-surface: var(--color-neutral-99);
  --color-surface-1: #ecebf6;
  --color-surface-2: #e8e6f6;
  --color-surface-3: #e3e0f6;
  --color-surface-4: #dfdaf5;
  --color-surface-5: #dcd6f5;
  --color-on-surface: var(--color-neutral-10);
  --color-surface-variant: var(--color-neutral-variant-90);
  --color-on-surface-variant: var(--color-neutral-variant-30);
  --color-outline: var(--color-neutral-variant-50);
  --color-primary-hover: var(--color-primary-50);
  --color-primary-pressed: var(--color-primary-30);
  --color-danger-hover: var(--color-error-50);
  --color-danger-pressed: var(--color-error-30);
  --color-text: var(--color-neutral-10);
  --color-text-link: var(--color-primary-40);
  --color-text-secondary: var(--color-neutral-50);
  --color-placeholder: var(--color-neutral-70);
  --color-border: var(--color-neutral-80);
  --color-divider: var(--color-neutral-90);
  --color-disabled: var(--color-neutral-80);
  --color-base: var(--color-surface-1);
  --color-layer-1: var(--color-all-100);
  --color-layer-2: var(--color-neutral-95);
  --color-float: var(--color-all-100);
  --color-layer-light: var(--color-neutral-99);
  --color-inverse-surface: var(--color-neutral-20);
  --color-inverse-on-surface: var(--color-neutral-95);
  --color-inverse-primary: var(--color-primary-50);
  --color-shadow: var(--color-all-100);
  --color-white: #fff;
  --color-button-icon: var(--color-primary-80);
  --color-icon-background: var(--color-neutral-variant-95);
  --color-hover: #191c1d14;
  --color-pressed: #191c1d1f;
  --color-focused: #191c1d29;
  --color-hover-variant: #5d34f214;
  --color-pressed-variant: #5d34f21f;
  --color-focused-variant: #5d34f229;
  --color-env-tag-development: #5d34f226;
  --color-env-tag-staging: #ffb95a59;
  --color-env-tag-production: #83da8559;
  --color-specific-icon-bg: #f3effa;
  --color-specific-toggle-off-enable: var(--color-neutral-90);
  --color-specific-unselected-disabled: var(--color-hover);
  --color-function-n-overlay-primary-focused: #5d34f229;
  --shadow-1: 0 4px 8px #00000014;
  --shadow-2: 0 4px 12px #0000001f;
  --shadow-2-reversed: 0 -4px 12px #0000001f;
  --shadow-3: 0 4px 16px #0003;
  --color-checkbox-disabled-background: var(--color-neutral-95);
  --color-checkbox-checked-disabled-background: var(--color-primary-80);
  --color-danger-toast-background: var(--color-error-95);
  --color-danger-focused: #ba1b1b29;
  --color-tooltip-background: #34353f;
  --color-tooltip-text: var(--color-neutral-99);
  --color-overlay: #0000004d;
  --color-overlay-default-focused: #191c1d29;
  --color-drawer-overlay: #0006;
  --color-guide-dropdown-background: var(--color-white);
  --color-guide-dropdown-border: var(--color-border);
  --color-skeleton-shimmer-rgb: 255, 255, 255;
  --color-specific-tag-upsell: var(--color-primary-50);
  --color-specific-tag-test: var(--color-tertiary-50);
  --color-specific-toggle-thumb-disabled: #ffffffb3;
  --color-bg-body-base: var(--color-neutral-95);
  --color-bg-body: var(--color-neutral-100);
  --color-bg-layer-1: var(--color-all-100);
  --color-bg-layer-2: var(--color-neutral-95);
  --color-bg-body-overlay: var(--color-neutral-100);
  --color-bg-float-base: var(--color-neutral-variant-90);
  --color-bg-float: var(--color-neutral-100);
  --color-bg-float-overlay: var(--color-neutral-100);
  --color-bg-mask: #0006;
  --color-bg-toast: var(--color-neutral-20);
  --color-bg-state-unselected: var(--color-neutral-90);
  --color-bg-state-disabled: #191c1d14;
  --color-bg-info-tag: #e5e1eccc;
}

._2eodma_dark {
  --color-all-0: #fff;
  --color-all-100: #000;
  --color-primary-10: #fffbff;
  --color-primary-20: #f5eeff;
  --color-primary-30: #e6deff;
  --color-primary-40: #cabeff;
  --color-primary-50: #af9eff;
  --color-primary-60: #947dff;
  --color-primary-70: #7958ff;
  --color-primary-80: #5d34f2;
  --color-primary-90: #4300da;
  --color-primary-95: #2d009d;
  --color-primary-99: #190064;
  --color-secondary-10: #fffbf7;
  --color-secondary-20: #fff480;
  --color-secondary-30: #f4e560;
  --color-secondary-40: #d7c947;
  --color-secondary-50: #baad2d;
  --color-secondary-60: #9f930d;
  --color-secondary-70: #847900;
  --color-secondary-80: #695f00;
  --color-secondary-90: #4f4700;
  --color-secondary-95: #373100;
  --color-secondary-99: #201c00;
  --color-tertiary-10: #fcfcfc;
  --color-tertiary-20: #ffeafe;
  --color-tertiary-30: #ffd5ff;
  --color-tertiary-40: #faabff;
  --color-tertiary-50: #f07eff;
  --color-tertiary-60: #d361e7;
  --color-tertiary-70: #b545ca;
  --color-tertiary-80: #9927af;
  --color-tertiary-90: #7b0093;
  --color-tertiary-95: #560068;
  --color-tertiary-99: #350041;
  --color-error-10: #fcfcfc;
  --color-error-20: #ffede9;
  --color-error-30: #ffdad4;
  --color-error-40: #ffb4a9;
  --color-error-50: #ff897a;
  --color-error-60: #ff5449;
  --color-error-70: #dd3730;
  --color-error-80: #ba1b1b;
  --color-error-90: #930006;
  --color-error-95: #680003;
  --color-error-99: #410001;
  --color-neutral-10: #f7f8f8;
  --color-neutral-20: #eff1f1;
  --color-neutral-30: #e0e3e3;
  --color-neutral-40: #c4c7c7;
  --color-neutral-50: #a9acac;
  --color-neutral-60: #8e9192;
  --color-neutral-70: #747778;
  --color-neutral-80: #5c5f60;
  --color-neutral-90: #444748;
  --color-neutral-95: #2d3132;
  --color-neutral-99: #191c1d;
  --color-neutral-variant-10: #fffbff;
  --color-neutral-variant-20: #f3effa;
  --color-neutral-variant-30: #e5e1ec;
  --color-neutral-variant-40: #c9c5d0;
  --color-neutral-variant-50: #adaab4;
  --color-neutral-variant-60: #928f9a;
  --color-neutral-variant-70: #78767f;
  --color-neutral-variant-80: #5f5d67;
  --color-neutral-variant-90: #47464e;
  --color-neutral-variant-95: #302f38;
  --color-neutral-variant-99: #1b1b22;
  --color-success-10: #ebf9eb;
  --color-success-20: #bdefbd;
  --color-success-30: #9fe79f;
  --color-success-40: #83da85;
  --color-success-50: #68be6c;
  --color-success-60: #4ea254;
  --color-success-70: #32873d;
  --color-success-80: #116d26;
  --color-success-90: #005314;
  --color-success-95: #00390b;
  --color-success-99: #002106;
  --color-alert-10: #fff5eb;
  --color-alert-20: #ffeedc;
  --color-alert-30: #ffddb5;
  --color-alert-40: #ffb95a;
  --color-alert-50: #eb9918;
  --color-alert-60: #ca8000;
  --color-alert-70: #a76900;
  --color-alert-80: #865300;
  --color-alert-90: #663e00;
  --color-alert-95: #472a00;
  --color-alert-99: #2b1700;
  --color-static-white: #fff;
  --color-primary: var(--color-primary-70);
  --color-on-primary: var(--color-all-0);
  --color-primary-container: var(--color-primary-30);
  --color-on-primary-container: var(--color-primary-30);
  --color-secondary: var(--color-secondary-70);
  --color-on-secondary: var(--color-all-0);
  --color-secondary-container: var(--color-secondary-90);
  --color-on-secondary-container: var(--color-secondary-30);
  --color-tertiary: var(--color-tertiary-70);
  --color-on-tertiary: var(--color-all-0);
  --color-tertiary-container: var(--color-tertiary-90);
  --color-on-tertiary-container: var(--color-tertiary-30);
  --color-error: var(--color-error-70);
  --color-error-hover: var(--color-error-60);
  --color-error-container: var(--color-error-95);
  --color-on-error-container: var(--color-error-70);
  --color-alert-container: var(--color-alert-90);
  --color-on-alert-container: var(--color-alert-60);
  --color-success-container: var(--color-success-90);
  --color-on-success-container: var(--color-success-60);
  --color-info-container: var(--color-neutral-variant-90);
  --color-on-info-container: var(--color-neutral-variant-70);
  --color-background: var(--color-neutral-99);
  --color-on-background: var(--color-neutral-10);
  --color-surface: var(--color-neutral-99);
  --color-surface-1: #25272b;
  --color-surface-2: #2a2c32;
  --color-surface-3: #2f3039;
  --color-surface-4: #34353f;
  --color-surface-5: #383844;
  --color-on-surface: var(--color-neutral-10);
  --color-surface-variant: var(--color-neutral-variant-90);
  --color-on-surface-variant: var(--color-neutral-variant-40);
  --color-outline: var(--color-neutral-variant-60);
  --color-primary-hover: var(--color-primary-60);
  --color-primary-pressed: var(--color-primary-80);
  --color-danger-hover: var(--color-error-60);
  --color-danger-pressed: var(--color-error-80);
  --color-text: var(--color-neutral-10);
  --color-text-link: var(--color-primary-40);
  --color-text-secondary: var(--color-neutral-50);
  --color-placeholder: var(--color-neutral-70);
  --color-border: var(--color-neutral-80);
  --color-divider: var(--color-neutral-90);
  --color-disabled: var(--color-neutral-80);
  --color-base: var(--color-surface);
  --color-layer-1: var(--color-surface-2);
  --color-layer-2: var(--color-surface-4);
  --color-float: var(--color-surface-4);
  --color-layer-light: var(--color-surface-4);
  --color-inverse-surface: var(--color-neutral-20);
  --color-inverse-on-surface: var(--color-neutral-95);
  --color-inverse-primary: var(--color-primary-80);
  --color-shadow: var(--color-all-100);
  --color-white: #fff;
  --color-button-icon: var(--color-primary-20);
  --color-icon-background: #3a3b59;
  --color-hover: #f7f8f814;
  --color-pressed: #f7f8f81f;
  --color-focused: #f7f8f829;
  --color-hover-variant: #cabeff14;
  --color-pressed-variant: #cabeff1f;
  --color-focused-variant: #cabeff29;
  --color-env-tag-development: #cabeff52;
  --color-env-tag-staging: #eb99185c;
  --color-env-tag-production: #68be6c5c;
  --color-specific-icon-bg: #f7f8f81f;
  --color-specific-toggle-off-enable: var(--color-neutral-90);
  --color-specific-unselected-disabled: var(--color-hover);
  --color-function-n-overlay-primary-focused: #cabeff29;
  --shadow-1: 0 4px 8px #00000014;
  --shadow-2: 0 4px 12px #0000001f;
  --shadow-2-reversed: 0 -4px 12px #0000001f;
  --shadow-3: 0 4px 16px #0003;
  --color-checkbox-disabled-background: #f7f8f814;
  --color-checkbox-checked-disabled-background: var(--color-primary-40);
  --color-danger-toast-background: var(--color-error-99);
  --color-danger-focused: #ffb4a929;
  --color-tooltip-background: var(--color-surface-4);
  --color-tooltip-text: var(--color-neutral-10);
  --color-overlay: #000000b3;
  --color-overlay-default-focused: #f7f8f829;
  --color-drawer-overlay: #0009;
  --color-guide-dropdown-background: var(--color-neutral-variant-80);
  --color-guide-dropdown-border: var(--color-neutral-variant-70);
  --color-skeleton-shimmer-rgb: 42, 44, 50;
  --color-specific-tag-upsell: var(--color-primary-70);
  --color-specific-tag-test: var(--color-tertiary-80);
  --color-specific-toggle-thumb-disabled: #ffffff4d;
  --color-bg-body-base: var(--color-neutral-100);
  --color-bg-body: var(--color-surface);
  --color-bg-body-overlay: var(--color-surface-2);
  --color-bg-layer-1: linear-gradient(0deg, #cabeff14, #cabeff14), linear-gradient(0deg, #c4c7c705, #c4c7c705), #191c1d;
  --color-bg-layer-2: var(--color-surface-4);
  --color-bg-float-base: var(--color-neutral-100);
  --color-bg-float: var(--color-surface-3);
  --color-bg-float-overlay: var(--color-surface-4);
  --color-bg-mask: #0009;
  --color-bg-toast: var(--color-neutral-80);
  --color-bg-state-unselected: var(--color-neutral-90);
  --color-bg-state-disabled: #f7f8f814;
  --color-bg-info-tag: var(--color-neutral-variant-90);
}

@keyframes FA5ZUG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes FA5ZUG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.FA5ZUG_container {
  height: 100vh;
  color: var(--color-text);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.FA5ZUG_container svg {
  margin-bottom: 16px;
}

.mKruhW_app {
  position: absolute;
  inset: 0;
}

@keyframes jrMu9W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes jrMu9W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.jrMu9W_button {
  font: var(--font-label-2);
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  border: none;
  border-radius: 8px;
  outline: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  transition: background-color .2s ease-in-out;
  display: flex;
  position: relative;
}

.jrMu9W_button.jrMu9W_withIcon {
  display: inline-flex;
}

.jrMu9W_button.jrMu9W_loading {
  pointer-events: none;
  opacity: .6;
}

.jrMu9W_button.jrMu9W_loading .jrMu9W_spinner {
  position: absolute;
}

.jrMu9W_button.jrMu9W_loading .jrMu9W_spinner ~ span {
  visibility: hidden;
}

.jrMu9W_button:not(:disabled) {
  cursor: pointer;
}

.jrMu9W_button:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.jrMu9W_button .jrMu9W_icon, .jrMu9W_button .jrMu9W_trailingIcon {
  align-items: center;
  display: flex;
}

.jrMu9W_button.jrMu9W_small {
  height: 30px;
  gap: 4px;
  padding: 0 12px;
}

.jrMu9W_button.jrMu9W_small.jrMu9W_text {
  height: 24px;
}

.jrMu9W_button.jrMu9W_medium {
  height: 36px;
  padding: 0 16px;
}

.jrMu9W_button.jrMu9W_medium.jrMu9W_text {
  font: var(--font-label-1);
  height: 28px;
}

.jrMu9W_button.jrMu9W_large {
  height: 44px;
  padding: 0 24px;
}

.jrMu9W_button.jrMu9W_large.jrMu9W_text {
  font: var(--font-label-1);
  height: 28px;
}

.jrMu9W_button.jrMu9W_default {
  background: var(--color-layer-1);
  color: var(--color-text);
  border-color: var(--color-border);
  border-style: solid;
  border-width: 1px;
}

.jrMu9W_button.jrMu9W_default .jrMu9W_icon {
  color: var(--color-text-secondary);
}

.jrMu9W_button.jrMu9W_default:disabled {
  border-color: var(--color-border);
  color: var(--color-neutral-70);
}

.jrMu9W_button.jrMu9W_default:focus-visible {
  outline: 3px solid var(--color-focused);
}

.jrMu9W_button.jrMu9W_default:active {
  background: var(--color-pressed);
}

.jrMu9W_button.jrMu9W_default:not(:disabled):not(:active):hover {
  background: var(--color-hover);
}

.jrMu9W_button.jrMu9W_branding {
  color: var(--color-on-primary);
  background: linear-gradient(90deg, #5d34f2 32%, #bd31ff 102%);
}

.jrMu9W_button.jrMu9W_branding:focus-visible, .jrMu9W_button.jrMu9W_branding:active {
  background: linear-gradient(0deg, #0003, #0003), linear-gradient(90deg, #5d34f2 32%, #bd31ff 102%);
}

.jrMu9W_button.jrMu9W_branding:not(:disabled):not(:active):hover {
  background: linear-gradient(0deg, #fff3, #fff3), linear-gradient(90deg, #5d34f2 32%, #bd31ff 102%);
}

.jrMu9W_button.jrMu9W_primary {
  background: var(--color-primary);
  color: var(--color-on-primary);
}

.jrMu9W_button.jrMu9W_primary:disabled {
  background: var(--color-inverse-on-surface);
  color: var(--color-neutral-70);
}

.jrMu9W_button.jrMu9W_primary:focus-visible {
  outline: 3px solid var(--color-focused-variant);
}

.jrMu9W_button.jrMu9W_primary:active {
  background: var(--color-primary-pressed);
}

.jrMu9W_button.jrMu9W_primary:not(:disabled):not(:active):hover {
  background: var(--color-primary-hover);
}

.jrMu9W_button.jrMu9W_danger {
  background: var(--color-error);
  color: var(--color-on-primary);
}

.jrMu9W_button.jrMu9W_danger:disabled {
  background: var(--color-inverse-on-surface);
  color: var(--color-neutral-70);
}

.jrMu9W_button.jrMu9W_danger:focus-visible {
  outline: 3px solid var(--color-danger-focused);
}

.jrMu9W_button.jrMu9W_danger:active {
  background: var(--color-danger-pressed);
}

.jrMu9W_button.jrMu9W_danger:not(:disabled):not(:active):hover {
  background: var(--color-danger-hover);
}

.jrMu9W_button.jrMu9W_outline {
  background-color: inherit;
  border-color: var(--color-text-link);
  color: var(--color-text-link);
  border-style: solid;
  border-width: 1px;
}

.jrMu9W_button.jrMu9W_outline:disabled {
  border-color: var(--color-neutral-70);
  color: var(--color-neutral-70);
}

.jrMu9W_button.jrMu9W_outline:focus-visible {
  outline: 3px solid var(--color-focused-variant);
}

.jrMu9W_button.jrMu9W_outline:active {
  background: var(--color-pressed-variant);
}

.jrMu9W_button.jrMu9W_outline:not(:disabled):not(:active):hover {
  background: var(--color-hover-variant);
}

.jrMu9W_button.jrMu9W_text {
  border-color: none;
  font: var(--font-label-2);
  color: var(--color-text-link);
  background: none;
  border-radius: 4px;
  padding: 2px 4px;
}

.jrMu9W_button.jrMu9W_text:disabled {
  color: var(--color-disabled);
}

.jrMu9W_button.jrMu9W_text:focus-visible {
  outline: 2px solid var(--color-focused-variant);
}

.jrMu9W_button.jrMu9W_text:not(:disabled):hover {
  background-color: var(--color-hover-variant);
}

.jrMu9W_button.jrMu9W_violet {
  background: var(--color-layer-1);
  border: 1px solid var(--color-surface-5);
  position: relative;
  overflow: hidden;
}

.jrMu9W_button.jrMu9W_violet .jrMu9W_icon, .jrMu9W_button.jrMu9W_violet .jrMu9W_trailingIcon {
  color: var(--color-primary);
}

.jrMu9W_button.jrMu9W_violet:disabled {
  color: var(--color-disabled);
}

.jrMu9W_button.jrMu9W_violet:disabled .jrMu9W_icon, .jrMu9W_button.jrMu9W_violet:disabled .jrMu9W_trailingIcon {
  color: var(--color-primary-80);
}

.jrMu9W_button.jrMu9W_violet:focus-visible {
  border: 2px solid var(--color-primary-40);
  outline: 4px solid var(--color-focused-variant);
}

.jrMu9W_button.jrMu9W_violet:not(:disabled):hover {
  background-color: var(--color-hover-variant);
}

.jrMu9W_button.jrMu9W_violet:not(:disabled):hover:before {
  content: "";
  background-color: var(--color-layer-1);
  z-index: -1;
  position: absolute;
  inset: 0;
}

@keyframes uLSymG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes uLSymG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.uLSymG_overlay {
  background: var(--color-overlay);
  z-index: 101;
  padding: 12px 0;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.uLSymG_content {
  min-height: 100%;
  flex-direction: column;
  flex: 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.uLSymG_content:focus-visible {
  outline: none;
}

.uLSymG_fullScreen {
  z-index: 100;
  position: fixed;
  inset: 0;
}

.uLSymG_fullScreen:focus-visible {
  outline: none;
}

@keyframes _lxpOa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _lxpOa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._lxpOa_card {
  background: var(--color-layer-1);
  border-radius: 16px;
  padding: 24px;
}

@keyframes XEcPNG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes XEcPNG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.XEcPNG_tag {
  font: var(--font-label-3);
  letter-spacing: normal;
  background-color: var(--color-specific-tag-upsell);
  color: var(--color-white);
  text-transform: capitalize;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 10px;
  padding: 2px 8px;
  display: inline-block;
}

.XEcPNG_beta {
  background-color: var(--color-specific-tag-test);
}

@keyframes gty49a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes gty49a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.gty49a_link {
  max-width: -moz-fit-content;
  max-width: fit-content;
  font: var(--font-body-2);
  color: var(--color-text-link);
  cursor: pointer;
  border-color: #0000;
  gap: 4px;
  text-decoration: none;
  display: inline-flex;
}

.gty49a_link.gty49a_trailingIcon {
  flex-direction: row-reverse;
}

.gty49a_link:active {
  color: var(--color-primary-pressed);
}

.gty49a_link:disabled {
  color: var(--color-disabled);
  cursor: not-allowed;
}

.gty49a_link:not(:disabled):hover {
  text-underline-offset: 2px;
  text-decoration: underline;
}

.gty49a_link > svg {
  vertical-align: baseline;
  display: inline-block;
}

@keyframes u3lpsW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes u3lpsW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.u3lpsW_container {
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.u3lpsW_container .u3lpsW_title {
  color: var(--color-text);
  align-items: center;
  gap: 8px;
  display: flex;
}

.u3lpsW_container .u3lpsW_titleEllipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.u3lpsW_container .u3lpsW_subtitle {
  color: var(--color-text-secondary);
  margin-top: 4px;
}

.u3lpsW_container .u3lpsW_learnMore:not(:first-child) {
  margin-left: 4px;
}

.u3lpsW_container.u3lpsW_large .u3lpsW_title {
  font: var(--font-title-1);
}

.u3lpsW_container.u3lpsW_large .u3lpsW_subtitle {
  font: var(--font-body-2);
}

.u3lpsW_container.u3lpsW_medium .u3lpsW_title {
  font: var(--font-title-2);
}

.u3lpsW_container.u3lpsW_medium .u3lpsW_subtitle {
  font: var(--font-body-2);
}

.u3lpsW_container.u3lpsW_small .u3lpsW_title {
  font: var(--font-title-2);
}

.u3lpsW_container.u3lpsW_small .u3lpsW_subtitle {
  font: var(--font-body-3);
  margin-top: 0;
}

@keyframes nDPQTW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nDPQTW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nDPQTW_button {
  border: none;
  border-color: none;
  color: var(--color-primary);
  font: var(--font-label-2);
  background: none;
  border-radius: 6px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 4px;
  transition: background .2s ease-in-out;
  display: flex;
}

.nDPQTW_button .nDPQTW_icon > svg {
  color: var(--color-text-secondary);
  display: block;
}

.nDPQTW_button:disabled .nDPQTW_icon > svg {
  color: var(--color-neutral-80);
}

.nDPQTW_button:active, .nDPQTW_button:focus {
  background: var(--color-pressed);
}

.nDPQTW_button:not(:disabled) {
  cursor: pointer;
}

.nDPQTW_button:not(:disabled):hover {
  background: var(--color-hover);
}

.nDPQTW_button.nDPQTW_small {
  height: 24px;
  width: 24px;
}

.nDPQTW_button.nDPQTW_small .nDPQTW_icon > svg {
  height: 16px;
  width: 16px;
}

.nDPQTW_button.nDPQTW_medium {
  height: 28px;
  width: 28px;
}

.nDPQTW_button.nDPQTW_medium .nDPQTW_icon > svg {
  height: 20px;
  width: 20px;
}

.nDPQTW_button.nDPQTW_large {
  height: 32px;
  width: 32px;
}

.nDPQTW_button.nDPQTW_large .nDPQTW_icon > svg {
  height: 24px;
  width: 24px;
}

@keyframes tG7Whq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes tG7Whq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.tG7Whq_container {
  width: calc(100% - 48px);
  min-width: 352px;
  box-shadow: var(--shadow-3);
  flex-direction: column;
  margin: 0 24px;
  padding: 24px;
  display: flex;
  overflow: hidden;
}

.tG7Whq_container .tG7Whq_header {
  flex-shrink: 0;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  display: flex;
}

.tG7Whq_container .tG7Whq_header .tG7Whq_iconAndTitle {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.tG7Whq_container .tG7Whq_header .tG7Whq_closeIcon {
  color: var(--color-text-secondary);
}

.tG7Whq_container .tG7Whq_footer {
  flex-shrink: 0;
  justify-content: flex-end;
  padding-top: 24px;
  display: flex;
}

.tG7Whq_container .tG7Whq_footer > :not(:first-child) {
  margin-left: 16px;
}

.tG7Whq_container.tG7Whq_medium {
  max-width: 600px;
}

.tG7Whq_container.tG7Whq_large {
  max-width: 784px;
}

.tG7Whq_container.tG7Whq_xlarge {
  max-width: 1224px;
}

@keyframes neT2BG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes neT2BG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.neT2BG_content {
  font: var(--font-body-2);
}

.neT2BG_content > :not(:first-child) {
  margin: 24px 0 0;
}

.O3Avlq_spacer {
  flex: 1;
}

@keyframes P2Jj4a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes P2Jj4a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.P2Jj4a_main .P2Jj4a_row {
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

.P2Jj4a_main .P2Jj4a_row .P2Jj4a_icon {
  margin-right: 24px;
}

.P2Jj4a_main .P2Jj4a_row:not(:last-child) {
  margin-bottom: 24px;
}

.P2Jj4a_main .P2Jj4a_row .P2Jj4a_text {
  flex: 1;
}

.P2Jj4a_main .P2Jj4a_row .P2Jj4a_text .P2Jj4a_title {
  font: var(--font-title-3);
  color: var(--color-text);
}

.P2Jj4a_main .P2Jj4a_row .P2Jj4a_text .P2Jj4a_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.P2Jj4a_main .P2Jj4a_row .P2Jj4a_button {
  width: 90px;
}

.P2Jj4a_main .P2Jj4a_row .P2Jj4a_link {
  text-decoration: none;
}

@keyframes BYjgpa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes BYjgpa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.BYjgpa_helpButton {
  background-color: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  outline: none;
  align-items: center;
  gap: 4px;
  margin-left: -4px;
  padding: 4px;
  transition: background-color .2s ease-in-out;
  display: flex;
}

.BYjgpa_helpButton:hover {
  background-color: var(--color-hover-variant);
}

.BYjgpa_helpButton.BYjgpa_active {
  background-color: var(--color-focused-variant);
}

.BYjgpa_helpButton .BYjgpa_icon {
  width: 20px;
  height: 20px;
}

.BYjgpa_helpButton .BYjgpa_icon > path {
  fill: var(--color-neutral-variant-50);
}

.BYjgpa_helpButton span {
  font: var(--font-label-2);
  color: var(--color-neutral-variant-30);
}

@keyframes xIgafW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes xIgafW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.xIgafW_documentNavButton {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: 6px;
  outline: none;
  align-items: center;
  margin-left: -4px;
  padding: 4px;
  transition: background-color .2s ease-in-out;
  display: flex;
}

.xIgafW_documentNavButton:hover {
  background-color: var(--color-hover-variant);
}

.xIgafW_documentNavButton.xIgafW_active {
  background-color: var(--color-focused-variant);
}

.xIgafW_documentNavButton .xIgafW_icon {
  width: 20px;
  height: 20px;
}

.xIgafW_documentNavButton .xIgafW_icon > path {
  fill: var(--color-neutral-variant-50);
}

.xIgafW_documentNavButton span {
  font: var(--font-label-2);
  color: var(--color-neutral-variant-30);
}

.xIgafW_textLink:not(:disabled):hover {
  text-decoration: none;
}

@keyframes _1_sVWW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _1_sVWW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._1_sVWW_tipBubble {
  background: var(--color-tooltip-background);
  color: var(--color-tooltip-text);
  box-shadow: var(--shadow-2);
  font: var(--font-body-2);
  max-width: 300px;
  white-space: pre-wrap;
  z-index: 200;
  border-radius: 8px;
  padding: 8px 12px;
  position: absolute;
}

._1_sVWW_tipBubble._1_sVWW_successful {
  background: var(--color-success-60);
}

._1_sVWW_tipBubble._1_sVWW_invisible {
  opacity: 0;
}

._1_sVWW_tipBubble a, ._1_sVWW_tipBubble a:active {
  color: #cabeff;
}

._1_sVWW_tipBubble ._1_sVWW_arrow {
  width: 10px;
  height: 10px;
  background-color: inherit;
  border-radius: 2px 0;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%)rotate(45deg);
}

._1_sVWW_tipBubble._1_sVWW_top ._1_sVWW_arrow {
  top: 100%;
}

._1_sVWW_tipBubble._1_sVWW_right ._1_sVWW_arrow {
  top: 50%;
  left: 0%;
}

._1_sVWW_tipBubble._1_sVWW_bottom ._1_sVWW_arrow {
  top: 0%;
}

._1_sVWW_tipBubble._1_sVWW_left ._1_sVWW_arrow {
  top: 50%;
  left: 100%;
}

@keyframes _9uLVAW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _9uLVAW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._9uLVAW_anchor {
  display: inline-block;
}

@keyframes -rGTfG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes -rGTfG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.-rGTfG_overlay {
  padding: unset;
  overflow-y: unset;
  background: none;
}

.-rGTfG_overlay .-rGTfG_content {
  position: relative;
}

.-rGTfG_overlay .-rGTfG_content:focus {
  outline: none;
}

@keyframes KtdR-q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes KtdR-q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.KtdR-q_field:not(:first-child) {
  margin-top: 24px;
}

.KtdR-q_headline {
  align-items: center;
  margin-bottom: 4px;
  display: flex;
}

.KtdR-q_headline.KtdR-q_withLargeSpacing {
  margin-bottom: 8px;
}

.KtdR-q_headline .KtdR-q_title {
  font: var(--font-label-2);
  color: var(--color-text);
}

.KtdR-q_headline .KtdR-q_title .KtdR-q_multiple {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-left: 4px;
}

.KtdR-q_headline .KtdR-q_toggleTipButton {
  margin-left: 2px;
}

.KtdR-q_headline .KtdR-q_required {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.KtdR-q_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 4px;
}

.KtdR-q_description.KtdR-q_top {
  margin-top: 0;
  margin-bottom: 4px;
}

@keyframes -PZGtG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes -PZGtG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.-PZGtG_radio {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  font: var(--font-body-2);
}

.-PZGtG_radio:not(:last-child) {
  margin-bottom: 8px;
}

.-PZGtG_radio .-PZGtG_content {
  align-items: center;
  display: flex;
}

.-PZGtG_radio .-PZGtG_content .-PZGtG_indicator {
  border: 2px solid var(--color-neutral-60);
  border-radius: 50%;
  margin-right: 8px;
  display: inline-block;
}

.-PZGtG_radio .-PZGtG_content .-PZGtG_indicator:before {
  content: "";
  background: var(--color-layer-1);
  width: 10px;
  height: 10px;
  border: 2px solid var(--color-layer-1);
  border-radius: 50%;
  display: block;
}

.-PZGtG_radio .-PZGtG_content .-PZGtG_icon, .-PZGtG_radio .-PZGtG_content .-PZGtG_trailingIcon {
  color: var(--color-text-secondary);
  margin-right: 8px;
}

.-PZGtG_radio .-PZGtG_content .-PZGtG_icon > svg, .-PZGtG_radio .-PZGtG_content .-PZGtG_trailingIcon > svg {
  display: block;
}

.-PZGtG_radio .-PZGtG_content .-PZGtG_trailingIcon {
  margin-right: unset;
  margin-left: 8px;
}

.-PZGtG_card {
  outline: 1px solid var(--color-neutral-90);
  border: 1px solid #0000;
  border-radius: 16px;
  padding: 12px;
}

.-PZGtG_card:not(:last-child) {
  margin-bottom: unset;
}

.-PZGtG_card .-PZGtG_content {
  display: block;
  position: relative;
}

.-PZGtG_card .-PZGtG_content .-PZGtG_indicator {
  border-radius: unset;
  border: unset;
  margin-right: unset;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.-PZGtG_card .-PZGtG_content .-PZGtG_indicator svg {
  opacity: 0;
}

.-PZGtG_card .-PZGtG_content .-PZGtG_indicator:before {
  display: none;
}

.-PZGtG_card .-PZGtG_content .-PZGtG_icon, .-PZGtG_card .-PZGtG_content .-PZGtG_trailingIcon {
  vertical-align: middle;
  color: var(--color-text-secondary);
  margin-right: 8px;
}

.-PZGtG_card .-PZGtG_content .-PZGtG_icon > svg, .-PZGtG_card .-PZGtG_content .-PZGtG_trailingIcon > svg {
  display: unset;
}

.-PZGtG_card .-PZGtG_content .-PZGtG_trailingIcon {
  margin-right: unset;
  margin-left: 8px;
}

.-PZGtG_card .-PZGtG_content .-PZGtG_disabledLabel {
  background: var(--color-neutral-90);
  font: var(--font-label-3);
  color: var(--color-text);
  border-radius: 10px;
  padding: 2px 8px;
}

.-PZGtG_compact {
  border: 1px solid var(--color-border);
  font: var(--font-label-2);
  flex: 1;
  position: relative;
}

.-PZGtG_compact:first-child {
  border-radius: 12px 0 0 12px;
}

.-PZGtG_compact:last-child {
  border-radius: 0 12px 12px 0;
}

.-PZGtG_compact:not(:first-child) {
  border-left: none;
}

.-PZGtG_compact:not(:last-child) {
  margin-bottom: unset;
}

.-PZGtG_compact .-PZGtG_content {
  height: 100%;
  padding: 20px;
}

.-PZGtG_compact .-PZGtG_content .-PZGtG_icon {
  margin-right: 16px;
}

.-PZGtG_compact .-PZGtG_content .-PZGtG_trailingIcon {
  margin-left: 16px;
}

.-PZGtG_small {
  border: 1px solid var(--color-border);
  font: var(--font-body-2);
  height: 36px;
  flex: 1;
  position: relative;
}

.-PZGtG_small:first-child {
  border-radius: 6px 0 0 6px;
}

.-PZGtG_small:last-child {
  border-radius: 0 6px 6px 0;
}

.-PZGtG_small:not(:first-child) {
  border-left: none;
}

.-PZGtG_small:not(:last-child) {
  margin-bottom: unset;
}

.-PZGtG_small .-PZGtG_content {
  height: 100%;
  justify-content: center;
  display: flex;
}

.-PZGtG_radio.-PZGtG_checked .-PZGtG_content .-PZGtG_indicator {
  border-color: var(--color-primary);
}

.-PZGtG_radio.-PZGtG_checked .-PZGtG_content .-PZGtG_indicator:before {
  background: var(--color-primary);
}

.-PZGtG_card.-PZGtG_checked {
  border-color: var(--color-primary);
  outline: 1px solid var(--color-primary);
}

.-PZGtG_card.-PZGtG_checked .-PZGtG_content .-PZGtG_indicator svg {
  opacity: 1;
}

.-PZGtG_compact.-PZGtG_checked {
  color: var(--color-primary);
  border-color: var(--color-primary);
  background-color: var(--color-hover-variant);
}

.-PZGtG_compact.-PZGtG_checked .-PZGtG_content .-PZGtG_icon, .-PZGtG_compact.-PZGtG_checked .-PZGtG_content .-PZGtG_trailingIcon {
  color: var(--color-primary);
}

.-PZGtG_compact.-PZGtG_checked:not(:first-child):before {
  content: "";
  width: 1px;
  background-color: var(--color-primary);
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
}

.-PZGtG_small.-PZGtG_checked {
  color: var(--color-text-link);
  border-color: var(--color-text-link);
  background-color: var(--color-hover-variant);
}

.-PZGtG_small.-PZGtG_checked:not(:first-child):before {
  content: "";
  width: 1px;
  background-color: var(--color-text-link);
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
}

.-PZGtG_radio.-PZGtG_disabled {
  cursor: not-allowed;
  color: var(--color-disabled);
}

.-PZGtG_radio.-PZGtG_disabled .-PZGtG_content .-PZGtG_indicator {
  border-color: var(--color-disabled);
}

.-PZGtG_radio.-PZGtG_disabled .-PZGtG_content .-PZGtG_indicator:before {
  background: var(--color-layer-1);
}

.-PZGtG_plain.-PZGtG_disabled {
  color: var(--color-text);
}

.-PZGtG_plain.-PZGtG_disabled .-PZGtG_content .-PZGtG_indicator {
  border-color: var(--color-border);
}

.-PZGtG_plain.-PZGtG_disabled .-PZGtG_content .-PZGtG_indicator:before {
  background: var(--color-bg-state-unselected);
  border-color: var(--color-bg-state-unselected);
}

.-PZGtG_card.-PZGtG_disabled {
  background-color: var(--color-layer-2);
  border-color: var(--color-layer-2);
  outline: unset;
}

.-PZGtG_small.-PZGtG_disabled {
  cursor: not-allowed;
  color: var(--color-text);
  background-color: var(--color-bg-state-disabled);
}

.-PZGtG_compact.-PZGtG_disabled {
  cursor: not-allowed;
  background-color: var(--color-layer-2);
}

.-PZGtG_compact.-PZGtG_disabled .-PZGtG_content .-PZGtG_icon, .-PZGtG_compact.-PZGtG_disabled .-PZGtG_content .-PZGtG_trailingIcon {
  color: var(--color-text-secondary);
}

.-PZGtG_card:not(.-PZGtG_disabled):focus {
  outline: 1px solid var(--color-primary);
  box-shadow: var(--shadow-2);
}

.-PZGtG_card:not(.-PZGtG_disabled):hover {
  box-shadow: var(--shadow-2);
}

.-PZGtG_compact:not(.-PZGtG_disabled):hover {
  color: var(--color-primary);
  border-color: var(--color-primary);
}

.-PZGtG_compact:not(.-PZGtG_disabled):hover .-PZGtG_content .-PZGtG_icon, .-PZGtG_compact:not(.-PZGtG_disabled):hover .-PZGtG_content .-PZGtG_trailingIcon {
  color: var(--color-primary);
}

.-PZGtG_compact:not(.-PZGtG_disabled):hover:not(:first-child):before {
  content: "";
  width: 1px;
  background-color: var(--color-primary);
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
}

@keyframes ewusyq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ewusyq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ewusyq_radioGroup input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
  margin: 0;
  position: absolute;
}

.ewusyq_compact, .ewusyq_small {
  flex-wrap: nowrap;
  align-items: stretch;
  display: flex;
}

@keyframes NBDOhG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes NBDOhG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.NBDOhG_suffix {
  width: 32px;
  height: 32px;
  display: none;
}

.NBDOhG_suffix.NBDOhG_visible {
  display: block;
}

.NBDOhG_hideTextContainerContent input {
  -webkit-text-security: disc;
}

.NBDOhG_container {
  border: 1px solid var(--color-border);
  height: 36px;
  background-color: inherit;
  font: var(--font-body-2);
  border-radius: 6px;
  outline: 3px solid #0000;
  align-items: center;
  padding: 0 12px;
  transition-property: outline, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: relative;
}

.NBDOhG_container.NBDOhG_withIcon {
  align-items: center;
  display: flex;
}

.NBDOhG_container.NBDOhG_withIcon .NBDOhG_icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.NBDOhG_container:focus-within {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.NBDOhG_container:focus-within .NBDOhG_suffix {
  display: block;
}

.NBDOhG_container input {
  -webkit-appearance: none;
  appearance: none;
  color: var(--color-text);
  font: var(--font-body-2);
  background: none;
  flex: 1;
  padding: 0;
}

.NBDOhG_container input::placeholder {
  color: var(--color-placeholder);
}

.NBDOhG_container input:-webkit-autofill {
  box-shadow: 0 0 0 30px var(--color-layer-1) inset;
  -webkit-text-fill-color: var(--color-text);
  caret-color: var(--color-text);
}

.NBDOhG_container input[type="date"] {
  height: 20px;
}

.NBDOhG_container input[type="date"]::-webkit-calendar-picker-indicator {
  background-image: none;
  background-color: var(--color-text-secondary);
  width: 16px;
  height: 18px;
  -webkit-mask-image: url("calendar-outline.40abfacf.svg");
  mask-image: url("calendar-outline.40abfacf.svg");
  -webkit-mask-size: 20px 20px;
  mask-size: 20px 20px;
}

.NBDOhG_container input[type="number"] {
  -moz-appearance: textfield;
}

.NBDOhG_container input[type="number"]::-webkit-outer-spin-button, .NBDOhG_container input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.NBDOhG_container.NBDOhG_disabled {
  background: var(--color-inverse-on-surface);
  color: var(--color-text-secondary);
  border-color: var(--color-border);
}

.NBDOhG_container.NBDOhG_readOnly {
  background: var(--color-layer-2);
  color: var(--color-text);
  border-color: var(--color-border);
}

.NBDOhG_container.NBDOhG_readOnly:focus-within {
  border-color: var(--color-border);
  outline-color: #0000;
}

.NBDOhG_container.NBDOhG_error {
  border-color: var(--color-error);
}

.NBDOhG_container.NBDOhG_error:focus-within {
  outline-color: var(--color-danger-focused);
}

.NBDOhG_errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
}

.NBDOhG_errorMessage a {
  color: var(--color-error);
  text-decoration: underline;
}

@keyframes uZxRxa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes uZxRxa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.uZxRxa_tag {
  vertical-align: middle;
  font: var(--font-label-3);
  border-radius: 4px;
  padding: 0 6px;
  display: inline-block;
}

.uZxRxa_tag.uZxRxa_development {
  background: var(--color-env-tag-development);
}

.uZxRxa_tag.uZxRxa_staging {
  background: var(--color-env-tag-staging);
}

.uZxRxa_tag.uZxRxa_production {
  background: var(--color-env-tag-production);
}

.uZxRxa_tag.uZxRxa_large {
  font: var(--font-label-1);
}

.iMcnjq_divider {
  height: 0;
  border-bottom: 1px solid var(--color-divider);
}

@keyframes RLtn4W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes RLtn4W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.RLtn4W_tag {
  font: var(--font-body-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  display: inline-flex;
  overflow: hidden;
}

.RLtn4W_tag .RLtn4W_icon {
  width: 10px;
  height: 10px;
  background: var(--color-on-success-container);
  border-radius: 50%;
  margin-right: 8px;
}

.RLtn4W_tag .RLtn4W_resultIcon {
  width: 16px;
  height: 16px;
  border-radius: unset;
  background-color: unset;
  color: var(--color-on-success-container);
}

.RLtn4W_tag.RLtn4W_cell {
  background: var(--color-neutral-variant-90);
  border-radius: 6px;
  padding: 2px 8px;
  font-family: Roboto Mono, monospace;
  font-size: 14px;
  line-height: 20px;
}

.RLtn4W_tag.RLtn4W_info .RLtn4W_icon {
  background: var(--color-on-info-container);
}

.RLtn4W_tag.RLtn4W_alert .RLtn4W_icon {
  background: var(--color-on-alert-container);
}

.RLtn4W_tag.RLtn4W_error .RLtn4W_icon {
  background: var(--color-on-error-container);
}

.RLtn4W_tag.RLtn4W_error .RLtn4W_resultIcon {
  background: unset;
  color: var(--color-on-error-container);
}

.RLtn4W_tag.RLtn4W_outlined {
  background: var(--color-success-container);
  font: var(--font-label-3);
  border-radius: 10px;
  padding: 2px 8px;
}

.RLtn4W_tag.RLtn4W_outlined.RLtn4W_info {
  background: var(--color-info-container);
}

.RLtn4W_tag.RLtn4W_outlined.RLtn4W_alert {
  background: var(--color-alert-container);
}

.RLtn4W_tag.RLtn4W_outlined.RLtn4W_error {
  background: var(--color-error-container);
}

.RLtn4W_tag.RLtn4W_outlined.RLtn4W_error .RLtn4W_resultIcon {
  background: unset;
  color: var(--color-on-error-container);
}

.RLtn4W_tag.RLtn4W_small {
  font: var(--font-label-3);
  padding: 1px 6px;
}

@keyframes rO9d9G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rO9d9G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.rO9d9G_container {
  flex-direction: column;
  gap: 12px;
  padding: 10px 12px;
  display: flex;
}

.rO9d9G_tag {
  align-self: flex-start;
}

.rO9d9G_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  height: 40px;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

@media screen and (max-width: 700px) {
  .rO9d9G_description {
    height: 60px;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

@media screen and (max-width: 540px) {
  .rO9d9G_description {
    height: 80px;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.rO9d9G_hint {
  font: var(--font-body-2);
}

.rO9d9G_planNameTag {
  margin-left: 4px;
}

@keyframes uYfaZq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes uYfaZq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.uYfaZq_list {
  padding-inline: 0;
  flex: 1;
  margin-block: 0;
  padding-bottom: 32px;
  list-style: none;
}

.uYfaZq_list > li {
  font: var(--font-body-2);
  align-items: center;
  gap: 8px;
  display: flex;
}

.uYfaZq_list > li .uYfaZq_icon {
  width: 16px;
  height: 16px;
}

.uYfaZq_list > li .uYfaZq_icon.uYfaZq_failed {
  color: var(--color-on-error-container);
}

.uYfaZq_list > li .uYfaZq_icon.uYfaZq_success {
  color: var(--color-on-success-container);
}

.uYfaZq_list > li:not(:first-child) {
  margin-top: 12px;
}

@keyframes t4YtMW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes t4YtMW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.t4YtMW_container {
  border: 1px solid var(--color-divider);
  border-radius: 12px;
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.t4YtMW_planInfo {
  border-bottom: 1px solid var(--color-divider);
  padding: 24px;
}

.t4YtMW_planInfo > div:not(:first-child) {
  margin-top: 16px;
}

.t4YtMW_planInfo .t4YtMW_title {
  font: var(--font-headline-2);
}

.t4YtMW_planInfo .t4YtMW_priceInfo > div:not(:first-child) {
  margin-top: 4px;
}

.t4YtMW_planInfo .t4YtMW_priceInfo .t4YtMW_priceLabel {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
}

.t4YtMW_planInfo .t4YtMW_priceInfo .t4YtMW_price {
  font: var(--font-headline-3);
}

.t4YtMW_planInfo .t4YtMW_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  height: 40px;
  margin-top: 4px;
}

.t4YtMW_content {
  flex-direction: column;
  flex: 1;
  padding: 24px;
  display: flex;
}

.t4YtMW_content .t4YtMW_tip {
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.t4YtMW_content .t4YtMW_tip.t4YtMW_exceedFreeTenantsTip {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.t4YtMW_content .t4YtMW_tip .t4YtMW_link {
  font: var(--font-label-2);
  align-items: center;
  display: flex;
}

.t4YtMW_content .t4YtMW_tip .t4YtMW_linkIcon {
  width: 16px;
  height: 16px;
}

.t4YtMW_content .t4YtMW_list {
  flex: 1;
  padding-bottom: 32px;
}

.t4YtMW_mostPopularTag {
  font: var(--font-label-3);
  color: var(--color-white);
  background-color: var(--color-specific-tag-upsell);
  width: 64px;
  text-align: center;
  border-radius: 4px 4px 0;
  padding: 6px 8px 6px 10px;
  position: absolute;
  top: 24px;
  right: -5px;
}

.t4YtMW_mostPopularTag:after {
  content: "";
  border-left: 4px solid var(--color-primary-60);
  border-bottom: 3px solid #0000;
  display: block;
  position: absolute;
  bottom: -3px;
  right: 0;
}

@keyframes _93AYIq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _93AYIq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._93AYIq_container {
  justify-content: space-between;
  align-items: stretch;
  gap: 28px;
  display: flex;
}

@keyframes _0EJwoa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _0EJwoa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._0EJwoa_description {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: 2px;
}

._0EJwoa_regionOptions {
  font: var(--font-label-2);
}

._0EJwoa_regionOptions ._0EJwoa_comingSoon {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-left: 4px;
}

._0EJwoa_envTagRadioGroup {
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  display: grid;
}

@keyframes zAZJ6a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zAZJ6a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.zAZJ6a_content {
  box-shadow: var(--shadow-2);
  border-radius: 8px;
  position: absolute;
}

.zAZJ6a_content.zAZJ6a_onTop {
  box-shadow: var(--shadow-2-reversed);
}

.zAZJ6a_content:focus {
  outline: none;
}

.zAZJ6a_dropdownContainer {
  background: var(--color-float);
  border: 1px solid var(--color-divider);
  border-radius: 8px;
}

.zAZJ6a_title {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
}

.zAZJ6a_overlay {
  z-index: 102;
  background: none;
  position: fixed;
  inset: 0;
}

@keyframes _Mz4GG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _Mz4GG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._Mz4GG_item {
  font: var(--font-body-2);
  cursor: pointer;
  border-radius: 6px;
  align-items: center;
  padding: 8px;
  display: flex;
  overflow: hidden;
}

._Mz4GG_item:hover {
  background: var(--color-hover);
}

._Mz4GG_item._Mz4GG_danger {
  color: var(--color-error);
}

._Mz4GG_item ._Mz4GG_icon {
  align-items: center;
  margin-right: 16px;
  display: flex;
}

@keyframes _8mkO2q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8mkO2q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._8mkO2q_item {
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  margin: 4px;
  padding: 10px 12px 10px 16px;
  transition: background-color .2s ease-in-out;
  display: flex;
}

._8mkO2q_item:hover {
  background: var(--color-hover);
}

._8mkO2q_item:not(:disabled) {
  cursor: pointer;
}

._8mkO2q_item ._8mkO2q_info {
  flex-direction: column;
  margin-right: 16px;
  display: flex;
}

._8mkO2q_item ._8mkO2q_info ._8mkO2q_meta {
  align-items: center;
  gap: 8px;
  display: flex;
}

._8mkO2q_item ._8mkO2q_info ._8mkO2q_meta ._8mkO2q_name {
  font: var(--font-body-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

._8mkO2q_item ._8mkO2q_info ._8mkO2q_meta ._8mkO2q_statusTag {
  background-color: var(--color-on-error-container);
  color: var(--color-white);
  font: var(--font-label-3);
}

._8mkO2q_item ._8mkO2q_info ._8mkO2q_planName {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
  min-height: 16px;
  margin-top: 2px;
}

._8mkO2q_item ._8mkO2q_checkIcon {
  width: 20px;
  height: 20px;
  color: #0000;
  flex-shrink: 0;
}

._8mkO2q_item ._8mkO2q_checkIcon._8mkO2q_visible {
  color: var(--color-primary-40);
}

@keyframes _8z7V1W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8z7V1W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._8z7V1W_currentTenantCard {
  max-width: 500px;
  background-color: #0000;
  border: none;
  border-radius: 8px;
  align-items: center;
  margin-left: 16px;
  padding: 4px 4px 4px 8px;
  transition: background-color .2s ease-in-out;
  display: flex;
  position: relative;
}

._8z7V1W_currentTenantCard:hover {
  cursor: pointer;
  background: var(--color-hover-variant);
}

._8z7V1W_currentTenantCard:not(:disabled) {
  cursor: pointer;
}

._8z7V1W_currentTenantCard ._8z7V1W_name {
  font: var(--font-title-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 6px;
  overflow: hidden;
}

._8z7V1W_currentTenantCard ._8z7V1W_tag {
  margin-right: 8px;
}

._8z7V1W_currentTenantCard ._8z7V1W_arrowIcon {
  width: 16px;
  height: 16px;
  color: var(--color-neutral-variant-50);
}

._8z7V1W_currentTenantCard:before {
  content: "";
  width: 1px;
  height: 16px;
  background-color: var(--color-neutral-80);
  flex-shrink: 0;
  position: absolute;
  left: -12px;
}

._8z7V1W_currentTenantCard:hover:before {
  pointer-events: none;
  cursor: default;
}

._8z7V1W_dropdown {
  max-width: 500px;
  min-width: 320px;
}

._8z7V1W_dropdown ._8z7V1W_scrollableContent {
  max-height: calc(100vh - 124px);
}

._8z7V1W_createTenantButton {
  all: unset;
  inline-size: -webkit-fill-available;
  inline-size: -moz-available;
  inline-size: stretch;
  font: var(--font-body-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  margin: 4px;
  padding: 10px 12px 10px 16px;
  transition: background-color .2s ease-in-out;
  display: flex;
  overflow: hidden;
}

._8z7V1W_createTenantButton:hover {
  background: var(--color-hover);
}

._8z7V1W_createTenantButton:not(:disabled) {
  cursor: pointer;
}

._8z7V1W_createTenantButton > svg {
  width: 20px;
  height: 20px;
  color: var(--color-neutral-50);
}

@keyframes cX_Dda_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes cX_Dda_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.cX_Dda_wrapper {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

.cX_Dda_micro {
  width: 20px;
  height: 20px;
  border-radius: 6px;
}

.cX_Dda_small {
  width: 24px;
  height: 24px;
}

.cX_Dda_medium {
  width: 32px;
  height: 32px;
}

.cX_Dda_large {
  width: 40px;
  height: 40px;
}

.cX_Dda_xlarge {
  width: 60px;
  height: 60px;
}

.cX_Dda_avatar {
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  font: var(--font-headline-3);
  width: 48px;
  height: 48px;
  object-fit: cover;
  transform-origin: 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cX_Dda_avatar.cX_Dda_micro {
  transform: scale(.416);
}

.cX_Dda_avatar.cX_Dda_small {
  transform: scale(.5);
}

.cX_Dda_avatar.cX_Dda_medium {
  transform: scale(.667);
}

.cX_Dda_avatar.cX_Dda_large {
  transform: scale(.833);
}

.cX_Dda_avatar.cX_Dda_xlarge {
  transform: scale(1.25);
}

.cX_Dda_tooltip {
  font: var(--font-body-2);
  padding: 10px;
}

.cX_Dda_tooltip .cX_Dda_row {
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.cX_Dda_tooltip .cX_Dda_row + .cX_Dda_row {
  margin-top: 4px;
}

.cX_Dda_tooltip .cX_Dda_label {
  color: #a9acac;
}

.cX_Dda_tooltip .cX_Dda_value {
  color: #f7f8f8;
  margin-left: 4px;
}

@keyframes _26bd8a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _26bd8a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._26bd8a_userInfo {
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  align-items: center;
  display: flex;
}

._26bd8a_nameWrapper {
  flex-direction: column;
  margin-left: 12px;
  display: flex;
}

._26bd8a_nameWrapper ._26bd8a_name {
  font: var(--font-label-2);
  color: var(--color-text);
}

._26bd8a_nameWrapper ._26bd8a_email {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
}

@keyframes o057rW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes o057rW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.o057rW_container {
  font: var(--font-body-2);
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
  padding: 10px 16px;
  display: flex;
  position: relative;
}

.o057rW_container:hover {
  background: var(--color-hover);
}

.o057rW_icon {
  color: var(--color-text-secondary);
  align-items: center;
  display: flex;
}

.o057rW_title {
  font: var(--font-body-2);
  margin-left: 16px;
}

.o057rW_menu {
  visibility: hidden;
  background: var(--color-float);
  border: 1px solid var(--color-divider);
  box-shadow: var(--shadow-2);
  border-radius: 8px;
  position: absolute;
  top: -4px;
  right: calc(100% + 5px);
}

.o057rW_menu.o057rW_visible {
  visibility: visible;
}

.o057rW_menuOption {
  min-width: 200px;
  margin: 4px;
  padding: 10px 22px 10px 32px;
  position: relative;
}

.o057rW_menuOption.o057rW_selected {
  color: var(--color-text-link);
}

.o057rW_menuOption .o057rW_tick {
  position: absolute;
  top: 10px;
  left: 8px;
}

@keyframes d1KHsG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes d1KHsG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.d1KHsG_container {
  border-radius: 8px;
  align-items: center;
  margin-left: 16px;
  padding: 8px;
  display: flex;
}

.d1KHsG_container .d1KHsG_image {
  background-color: var(--color-layer-2);
  width: 36px;
  height: 36px;
  border-radius: 6px;
  margin-right: 8px;
  position: relative;
  overflow: hidden;
}

.d1KHsG_container .d1KHsG_image:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite d1KHsG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.d1KHsG_container .d1KHsG_name {
  background-color: var(--color-layer-2);
  width: 85px;
  height: 20px;
  color: var(--color-text);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.d1KHsG_container .d1KHsG_name:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite d1KHsG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes p7wx2G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes p7wx2G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.p7wx2G_container {
  cursor: pointer;
  position: relative;
}

.p7wx2G_container:after {
  content: "";
  width: 32px;
  height: 32px;
  border-radius: 8px;
  transition: background .2s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.p7wx2G_container:hover:after {
  background: var(--color-hover);
}

.p7wx2G_container.p7wx2G_active:after {
  background: var(--color-focused);
}

.p7wx2G_dropdown {
  min-width: 320px;
}

.p7wx2G_dropdown .p7wx2G_userInfo {
  padding: 16px 20px;
}

.p7wx2G_dropdownItem {
  min-width: 170px;
  margin: 4px;
  padding: 10px 16px;
}

.p7wx2G_dropdownItem.p7wx2G_loading {
  opacity: .6;
  cursor: default;
}

.p7wx2G_dropdownItem.p7wx2G_loading:hover {
  background-color: unset;
}

.p7wx2G_dropdownItem .p7wx2G_icon {
  color: var(--color-text-secondary);
}

.p7wx2G_dropdownItem .p7wx2G_spinner {
  margin-left: 24px;
}

@keyframes OfVTMq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes OfVTMq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.OfVTMq_topbar {
  width: 100%;
  flex: 0 0 64px;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

.OfVTMq_topbar .OfVTMq_logo {
  width: auto;
  height: 28px;
  color: var(--color-text);
}

.OfVTMq_topbar .OfVTMq_line {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 20px;
  margin: 0 20px;
}

.OfVTMq_topbar .OfVTMq_text {
  font: var(--font-title-2);
  color: var(--color-text);
}

.OfVTMq_topbar > :not(:last-child) {
  margin-right: 16px;
}

@keyframes XR1RoW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes XR1RoW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.XR1RoW_placeholder {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.XR1RoW_placeholder .XR1RoW_image > svg {
  width: 256px;
  height: 256px;
}

.XR1RoW_placeholder .XR1RoW_title {
  font: var(--font-title-1);
}

.XR1RoW_placeholder .XR1RoW_description {
  max-width: 470px;
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 8px;
}

.XR1RoW_placeholder .XR1RoW_button {
  margin-top: 24px;
}

@keyframes _81FlKG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _81FlKG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._81FlKG_pageContainer {
  height: 100%;
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
}

._81FlKG_pageContainer ._81FlKG_placeholder {
  text-align: center;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding-bottom: 64px;
  display: flex;
}

._81FlKG_pageContainer ._81FlKG_placeholder ._81FlKG_image > svg {
  width: 256px;
  height: 256px;
}

._81FlKG_pageContainer ._81FlKG_placeholder ._81FlKG_title {
  font: var(--font-label-2);
}

._81FlKG_pageContainer ._81FlKG_placeholder ._81FlKG_description {
  max-width: 470px;
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 8px;
}

._81FlKG_pageContainer ._81FlKG_placeholder ._81FlKG_button {
  margin-top: 24px;
}

@keyframes k1s4KG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes k1s4KG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

:root {
  --font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-display-1: 700 48 / 56px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-display-2: 700 40px / 48px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-display-3: 700 32px / 40px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-1: 600 32px / 40px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-2: 600 28px / 36px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-headline-3: 600 24px / 32px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-1: 600 20px / 28px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-2: 600 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-title-3: 600 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-1: 500 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-2: 500 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-label-3: 500 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-0: 400 18px / 26px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-1: 400 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-2: 400 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-body-3: 400 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-section-head-1: 700 12px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
  --font-section-head-2: 700 10px / 16px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji;
}

.k1s4KG_container .k1s4KG_card {
  background: var(--color-layer-1);
  width: 640px;
  height: 640px;
  text-align: center;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.k1s4KG_container .k1s4KG_card > svg {
  width: 120px;
  height: 120px;
  margin-top: 100px;
}

.k1s4KG_container .k1s4KG_card .k1s4KG_title {
  color: var(--color-neutral-10);
  font: var(--font-title-2);
  margin-top: 64px;
}

.k1s4KG_container .k1s4KG_card .k1s4KG_message {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 12px;
  padding: 0 32px;
}

@media (prefers-color-scheme: light) {
  body {
    --color-all-0: #000;
    --color-all-100: #fff;
    --color-primary-10: #190064;
    --color-primary-20: #2d009d;
    --color-primary-30: #4300da;
    --color-primary-40: #5d34f2;
    --color-primary-50: #7958ff;
    --color-primary-60: #947dff;
    --color-primary-70: #af9eff;
    --color-primary-80: #cabeff;
    --color-primary-90: #e6deff;
    --color-primary-95: #f5eeff;
    --color-primary-99: #fffbff;
    --color-secondary-10: #201c00;
    --color-secondary-20: #373100;
    --color-secondary-30: #4f4700;
    --color-secondary-40: #695f00;
    --color-secondary-50: #847900;
    --color-secondary-60: #9f930d;
    --color-secondary-70: #baad2d;
    --color-secondary-80: #d7c947;
    --color-secondary-90: #f4e560;
    --color-secondary-95: #fff480;
    --color-secondary-99: #fffbf7;
    --color-tertiary-10: #350041;
    --color-tertiary-20: #560068;
    --color-tertiary-30: #7b0093;
    --color-tertiary-40: #9927af;
    --color-tertiary-50: #b545ca;
    --color-tertiary-60: #d361e7;
    --color-tertiary-70: #f07eff;
    --color-tertiary-80: #faabff;
    --color-tertiary-90: #ffd5ff;
    --color-tertiary-95: #ffeafe;
    --color-tertiary-99: #fcfcfc;
    --color-error-10: #410001;
    --color-error-20: #680003;
    --color-error-30: #930006;
    --color-error-40: #ba1b1b;
    --color-error-50: #dd3730;
    --color-error-60: #ff5449;
    --color-error-70: #ff897a;
    --color-error-80: #ffb4a9;
    --color-error-90: #ffdad4;
    --color-error-95: #ffede9;
    --color-error-99: #fcfcfc;
    --color-neutral-10: #191c1d;
    --color-neutral-20: #2d3132;
    --color-neutral-30: #444748;
    --color-neutral-40: #5c5f60;
    --color-neutral-50: #747778;
    --color-neutral-60: #8e9192;
    --color-neutral-70: #a9acac;
    --color-neutral-80: #c4c7c7;
    --color-neutral-90: #e0e3e3;
    --color-neutral-95: #eff1f1;
    --color-neutral-99: #f7f8f8;
    --color-neutral-variant-10: #1b1b22;
    --color-neutral-variant-20: #302f38;
    --color-neutral-variant-30: #47464e;
    --color-neutral-variant-40: #5f5d67;
    --color-neutral-variant-50: #78767f;
    --color-neutral-variant-60: #928f9a;
    --color-neutral-variant-70: #adaab4;
    --color-neutral-variant-80: #c9c5d0;
    --color-neutral-variant-90: #e5e1ec;
    --color-neutral-variant-95: #f3effa;
    --color-neutral-variant-99: #fffbff;
    --color-success-10: #002106;
    --color-success-20: #00390b;
    --color-success-30: #005314;
    --color-success-40: #116d26;
    --color-success-50: #32873d;
    --color-success-60: #4ea254;
    --color-success-70: #68be6c;
    --color-success-80: #83da85;
    --color-success-90: #9fe79f;
    --color-success-95: #bdefbd;
    --color-success-99: #ebf9eb;
    --color-alert-10: #2b1700;
    --color-alert-20: #472a00;
    --color-alert-30: #663e00;
    --color-alert-40: #865300;
    --color-alert-50: #a76900;
    --color-alert-60: #ca8000;
    --color-alert-70: #eb9918;
    --color-alert-80: #ffb95a;
    --color-alert-90: #ffddb5;
    --color-alert-95: #ffeedc;
    --color-alert-99: #fff5eb;
    --color-static-white: #fff;
    --color-primary: var(--color-primary-40);
    --color-on-primary: var(--color-all-100);
    --color-primary-container: var(--color-primary-90);
    --color-on-primary-container: var(--color-primary-10);
    --color-secondary: var(--color-secondary-40);
    --color-on-secondary: var(--color-all-100);
    --color-secondary-container: var(--color-secondary-30);
    --color-on-secondary-container: var(--color-secondary-10);
    --color-tertiary: var(--color-tertiary-40);
    --color-on-tertiary: var(--color-all-100);
    --color-tertiary-container: var(--color-tertiary-90);
    --color-on-tertiary-container: var(--color-tertiary-10);
    --color-error: var(--color-error-40);
    --color-error-hover: var(--color-error-50);
    --color-error-container: var(--color-error-95);
    --color-on-error-container: var(--color-error-50);
    --color-alert-container: var(--color-alert-95);
    --color-on-alert-container: var(--color-alert-70);
    --color-success-container: var(--color-success-99);
    --color-on-success-container: var(--color-success-70);
    --color-info-container: var(--color-neutral-variant-90);
    --color-on-info-container: var(--color-neutral-variant-60);
    --color-background: var(--color-neutral-99);
    --color-on-background: var(--color-neutral-10);
    --color-surface: var(--color-neutral-99);
    --color-surface-1: #ecebf6;
    --color-surface-2: #e8e6f6;
    --color-surface-3: #e3e0f6;
    --color-surface-4: #dfdaf5;
    --color-surface-5: #dcd6f5;
    --color-on-surface: var(--color-neutral-10);
    --color-surface-variant: var(--color-neutral-variant-90);
    --color-on-surface-variant: var(--color-neutral-variant-30);
    --color-outline: var(--color-neutral-variant-50);
    --color-primary-hover: var(--color-primary-50);
    --color-primary-pressed: var(--color-primary-30);
    --color-danger-hover: var(--color-error-50);
    --color-danger-pressed: var(--color-error-30);
    --color-text: var(--color-neutral-10);
    --color-text-link: var(--color-primary-40);
    --color-text-secondary: var(--color-neutral-50);
    --color-placeholder: var(--color-neutral-70);
    --color-border: var(--color-neutral-80);
    --color-divider: var(--color-neutral-90);
    --color-disabled: var(--color-neutral-80);
    --color-base: var(--color-surface-1);
    --color-layer-1: var(--color-all-100);
    --color-layer-2: var(--color-neutral-95);
    --color-float: var(--color-all-100);
    --color-layer-light: var(--color-neutral-99);
    --color-inverse-surface: var(--color-neutral-20);
    --color-inverse-on-surface: var(--color-neutral-95);
    --color-inverse-primary: var(--color-primary-50);
    --color-shadow: var(--color-all-100);
    --color-white: #fff;
    --color-button-icon: var(--color-primary-80);
    --color-icon-background: var(--color-neutral-variant-95);
    --color-hover: #191c1d14;
    --color-pressed: #191c1d1f;
    --color-focused: #191c1d29;
    --color-hover-variant: #5d34f214;
    --color-pressed-variant: #5d34f21f;
    --color-focused-variant: #5d34f229;
    --color-env-tag-development: #5d34f226;
    --color-env-tag-staging: #ffb95a59;
    --color-env-tag-production: #83da8559;
    --color-specific-icon-bg: #f3effa;
    --color-specific-toggle-off-enable: var(--color-neutral-90);
    --color-specific-unselected-disabled: var(--color-hover);
    --color-function-n-overlay-primary-focused: #5d34f229;
    --shadow-1: 0 4px 8px #00000014;
    --shadow-2: 0 4px 12px #0000001f;
    --shadow-2-reversed: 0 -4px 12px #0000001f;
    --shadow-3: 0 4px 16px #0003;
    --color-checkbox-disabled-background: var(--color-neutral-95);
    --color-checkbox-checked-disabled-background: var(--color-primary-80);
    --color-danger-toast-background: var(--color-error-95);
    --color-danger-focused: #ba1b1b29;
    --color-tooltip-background: #34353f;
    --color-tooltip-text: var(--color-neutral-99);
    --color-overlay: #0000004d;
    --color-overlay-default-focused: #191c1d29;
    --color-drawer-overlay: #0006;
    --color-guide-dropdown-background: var(--color-white);
    --color-guide-dropdown-border: var(--color-border);
    --color-skeleton-shimmer-rgb: 255, 255, 255;
    --color-specific-tag-upsell: var(--color-primary-50);
    --color-specific-tag-test: var(--color-tertiary-50);
    --color-specific-toggle-thumb-disabled: #ffffffb3;
    --color-bg-body-base: var(--color-neutral-95);
    --color-bg-body: var(--color-neutral-100);
    --color-bg-layer-1: var(--color-all-100);
    --color-bg-layer-2: var(--color-neutral-95);
    --color-bg-body-overlay: var(--color-neutral-100);
    --color-bg-float-base: var(--color-neutral-variant-90);
    --color-bg-float: var(--color-neutral-100);
    --color-bg-float-overlay: var(--color-neutral-100);
    --color-bg-mask: #0006;
    --color-bg-toast: var(--color-neutral-20);
    --color-bg-state-unselected: var(--color-neutral-90);
    --color-bg-state-disabled: #191c1d14;
    --color-bg-info-tag: #e5e1eccc;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    --color-all-0: #fff;
    --color-all-100: #000;
    --color-primary-10: #fffbff;
    --color-primary-20: #f5eeff;
    --color-primary-30: #e6deff;
    --color-primary-40: #cabeff;
    --color-primary-50: #af9eff;
    --color-primary-60: #947dff;
    --color-primary-70: #7958ff;
    --color-primary-80: #5d34f2;
    --color-primary-90: #4300da;
    --color-primary-95: #2d009d;
    --color-primary-99: #190064;
    --color-secondary-10: #fffbf7;
    --color-secondary-20: #fff480;
    --color-secondary-30: #f4e560;
    --color-secondary-40: #d7c947;
    --color-secondary-50: #baad2d;
    --color-secondary-60: #9f930d;
    --color-secondary-70: #847900;
    --color-secondary-80: #695f00;
    --color-secondary-90: #4f4700;
    --color-secondary-95: #373100;
    --color-secondary-99: #201c00;
    --color-tertiary-10: #fcfcfc;
    --color-tertiary-20: #ffeafe;
    --color-tertiary-30: #ffd5ff;
    --color-tertiary-40: #faabff;
    --color-tertiary-50: #f07eff;
    --color-tertiary-60: #d361e7;
    --color-tertiary-70: #b545ca;
    --color-tertiary-80: #9927af;
    --color-tertiary-90: #7b0093;
    --color-tertiary-95: #560068;
    --color-tertiary-99: #350041;
    --color-error-10: #fcfcfc;
    --color-error-20: #ffede9;
    --color-error-30: #ffdad4;
    --color-error-40: #ffb4a9;
    --color-error-50: #ff897a;
    --color-error-60: #ff5449;
    --color-error-70: #dd3730;
    --color-error-80: #ba1b1b;
    --color-error-90: #930006;
    --color-error-95: #680003;
    --color-error-99: #410001;
    --color-neutral-10: #f7f8f8;
    --color-neutral-20: #eff1f1;
    --color-neutral-30: #e0e3e3;
    --color-neutral-40: #c4c7c7;
    --color-neutral-50: #a9acac;
    --color-neutral-60: #8e9192;
    --color-neutral-70: #747778;
    --color-neutral-80: #5c5f60;
    --color-neutral-90: #444748;
    --color-neutral-95: #2d3132;
    --color-neutral-99: #191c1d;
    --color-neutral-variant-10: #fffbff;
    --color-neutral-variant-20: #f3effa;
    --color-neutral-variant-30: #e5e1ec;
    --color-neutral-variant-40: #c9c5d0;
    --color-neutral-variant-50: #adaab4;
    --color-neutral-variant-60: #928f9a;
    --color-neutral-variant-70: #78767f;
    --color-neutral-variant-80: #5f5d67;
    --color-neutral-variant-90: #47464e;
    --color-neutral-variant-95: #302f38;
    --color-neutral-variant-99: #1b1b22;
    --color-success-10: #ebf9eb;
    --color-success-20: #bdefbd;
    --color-success-30: #9fe79f;
    --color-success-40: #83da85;
    --color-success-50: #68be6c;
    --color-success-60: #4ea254;
    --color-success-70: #32873d;
    --color-success-80: #116d26;
    --color-success-90: #005314;
    --color-success-95: #00390b;
    --color-success-99: #002106;
    --color-alert-10: #fff5eb;
    --color-alert-20: #ffeedc;
    --color-alert-30: #ffddb5;
    --color-alert-40: #ffb95a;
    --color-alert-50: #eb9918;
    --color-alert-60: #ca8000;
    --color-alert-70: #a76900;
    --color-alert-80: #865300;
    --color-alert-90: #663e00;
    --color-alert-95: #472a00;
    --color-alert-99: #2b1700;
    --color-static-white: #fff;
    --color-primary: var(--color-primary-70);
    --color-on-primary: var(--color-all-0);
    --color-primary-container: var(--color-primary-30);
    --color-on-primary-container: var(--color-primary-30);
    --color-secondary: var(--color-secondary-70);
    --color-on-secondary: var(--color-all-0);
    --color-secondary-container: var(--color-secondary-90);
    --color-on-secondary-container: var(--color-secondary-30);
    --color-tertiary: var(--color-tertiary-70);
    --color-on-tertiary: var(--color-all-0);
    --color-tertiary-container: var(--color-tertiary-90);
    --color-on-tertiary-container: var(--color-tertiary-30);
    --color-error: var(--color-error-70);
    --color-error-hover: var(--color-error-60);
    --color-error-container: var(--color-error-95);
    --color-on-error-container: var(--color-error-70);
    --color-alert-container: var(--color-alert-90);
    --color-on-alert-container: var(--color-alert-60);
    --color-success-container: var(--color-success-90);
    --color-on-success-container: var(--color-success-60);
    --color-info-container: var(--color-neutral-variant-90);
    --color-on-info-container: var(--color-neutral-variant-70);
    --color-background: var(--color-neutral-99);
    --color-on-background: var(--color-neutral-10);
    --color-surface: var(--color-neutral-99);
    --color-surface-1: #25272b;
    --color-surface-2: #2a2c32;
    --color-surface-3: #2f3039;
    --color-surface-4: #34353f;
    --color-surface-5: #383844;
    --color-on-surface: var(--color-neutral-10);
    --color-surface-variant: var(--color-neutral-variant-90);
    --color-on-surface-variant: var(--color-neutral-variant-40);
    --color-outline: var(--color-neutral-variant-60);
    --color-primary-hover: var(--color-primary-60);
    --color-primary-pressed: var(--color-primary-80);
    --color-danger-hover: var(--color-error-60);
    --color-danger-pressed: var(--color-error-80);
    --color-text: var(--color-neutral-10);
    --color-text-link: var(--color-primary-40);
    --color-text-secondary: var(--color-neutral-50);
    --color-placeholder: var(--color-neutral-70);
    --color-border: var(--color-neutral-80);
    --color-divider: var(--color-neutral-90);
    --color-disabled: var(--color-neutral-80);
    --color-base: var(--color-surface);
    --color-layer-1: var(--color-surface-2);
    --color-layer-2: var(--color-surface-4);
    --color-float: var(--color-surface-4);
    --color-layer-light: var(--color-surface-4);
    --color-inverse-surface: var(--color-neutral-20);
    --color-inverse-on-surface: var(--color-neutral-95);
    --color-inverse-primary: var(--color-primary-80);
    --color-shadow: var(--color-all-100);
    --color-white: #fff;
    --color-button-icon: var(--color-primary-20);
    --color-icon-background: #3a3b59;
    --color-hover: #f7f8f814;
    --color-pressed: #f7f8f81f;
    --color-focused: #f7f8f829;
    --color-hover-variant: #cabeff14;
    --color-pressed-variant: #cabeff1f;
    --color-focused-variant: #cabeff29;
    --color-env-tag-development: #cabeff52;
    --color-env-tag-staging: #eb99185c;
    --color-env-tag-production: #68be6c5c;
    --color-specific-icon-bg: #f7f8f81f;
    --color-specific-toggle-off-enable: var(--color-neutral-90);
    --color-specific-unselected-disabled: var(--color-hover);
    --color-function-n-overlay-primary-focused: #cabeff29;
    --shadow-1: 0 4px 8px #00000014;
    --shadow-2: 0 4px 12px #0000001f;
    --shadow-2-reversed: 0 -4px 12px #0000001f;
    --shadow-3: 0 4px 16px #0003;
    --color-checkbox-disabled-background: #f7f8f814;
    --color-checkbox-checked-disabled-background: var(--color-primary-40);
    --color-danger-toast-background: var(--color-error-99);
    --color-danger-focused: #ffb4a929;
    --color-tooltip-background: var(--color-surface-4);
    --color-tooltip-text: var(--color-neutral-10);
    --color-overlay: #000000b3;
    --color-overlay-default-focused: #f7f8f829;
    --color-drawer-overlay: #0009;
    --color-guide-dropdown-background: var(--color-neutral-variant-80);
    --color-guide-dropdown-border: var(--color-neutral-variant-70);
    --color-skeleton-shimmer-rgb: 42, 44, 50;
    --color-specific-tag-upsell: var(--color-primary-70);
    --color-specific-tag-test: var(--color-tertiary-80);
    --color-specific-toggle-thumb-disabled: #ffffff4d;
    --color-bg-body-base: var(--color-neutral-100);
    --color-bg-body: var(--color-surface);
    --color-bg-body-overlay: var(--color-surface-2);
    --color-bg-layer-1: linear-gradient(0deg, #cabeff14, #cabeff14), linear-gradient(0deg, #c4c7c705, #c4c7c705), #191c1d;
    --color-bg-layer-2: var(--color-surface-4);
    --color-bg-float-base: var(--color-neutral-100);
    --color-bg-float: var(--color-surface-3);
    --color-bg-float-overlay: var(--color-surface-4);
    --color-bg-mask: #0009;
    --color-bg-toast: var(--color-neutral-80);
    --color-bg-state-unselected: var(--color-neutral-90);
    --color-bg-state-disabled: #f7f8f814;
    --color-bg-info-tag: var(--color-neutral-variant-90);
  }
}

@keyframes i5jzhG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes i5jzhG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

div.i5jzhG_toast {
  font: var(--font-body-2);
  color: var(--color-text);
  box-shadow: var(--shadow-1);
  max-width: 412px;
  border-radius: 8px;
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

div.i5jzhG_toast .i5jzhG_image {
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

div.i5jzhG_toast .i5jzhG_message {
  overflow-wrap: break-word;
  flex: 1;
  margin: 0 12px;
  overflow: hidden;
}

div.i5jzhG_toast.i5jzhG_success {
  border: 1px solid var(--color-success-70);
  background-color: var(--color-success-99);
}

div.i5jzhG_toast.i5jzhG_error {
  border: 1px solid var(--color-error);
  background-color: var(--color-danger-toast-background);
  white-space: pre-line;
}

@keyframes _8LEt0W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8LEt0W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._8LEt0W_container {
  height: 100%;
  padding: 24px;
}

._8LEt0W_content {
  height: 100%;
  color: var(--color-text);
  text-align: center;
  overflow: hidden;
}

._8LEt0W_content svg {
  margin: 15% 0 16px;
}

._8LEt0W_content ._8LEt0W_message {
  font: var(--font-body-2);
}

@keyframes LvYW9G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes LvYW9G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.LvYW9G_topbar {
  width: 100%;
  flex: 0 0 64px;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

.LvYW9G_topbar .LvYW9G_logo {
  width: auto;
  height: 28px;
  color: var(--color-text);
}

.VbL0Mq_app {
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
}

.VbL0Mq_content {
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

@keyframes E9EZ-a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes E9EZ-a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.E9EZ-a_app {
  position: absolute;
  inset: 0;
}

@keyframes rhjZ5G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rhjZ5G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.rhjZ5G_progressBar {
  gap: 4px;
  display: flex;
}

.rhjZ5G_progressBar .rhjZ5G_stepIndicator {
  height: 4px;
  background-color: var(--color-neutral-variant-80);
  flex: 1;
}

.rhjZ5G_progressBar .rhjZ5G_stepIndicator.rhjZ5G_active {
  background-color: var(--color-text-link);
}

@keyframes V_YYMW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes V_YYMW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.V_YYMW_container {
  height: 80px;
  flex-shrink: 0;
}

.V_YYMW_container .V_YYMW_actions {
  height: 100%;
  background-color: var(--color-layer-1);
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0 68px;
  display: flex;
}

@keyframes _51AnG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _51AnG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._51AnG_container {
  border: 1px solid var(--color-border);
  font: var(--font-body-2);
  border-radius: 6px;
  outline: 3px solid #0000;
  align-items: center;
  padding: 6px 12px;
  transition-property: outline, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
}

._51AnG_container:focus, ._51AnG_container._51AnG_highlight {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

._51AnG_container ._51AnG_brick {
  width: 24px;
  height: 24px;
  border: 1px solid var(--color-divider);
  border-radius: 4px;
  flex-shrink: 0;
  margin-right: 8px;
  display: inline-block;
}

@keyframes _7kuApa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _7kuApa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._7kuApa_uploader {
  border: 1px dashed var(--color-border);
  border-radius: 8px;
  padding: 13px;
}

._7kuApa_uploader > input {
  display: none;
}

._7kuApa_uploader ._7kuApa_placeholder {
  flex-direction: column;
  align-items: center;
  display: flex;
}

._7kuApa_uploader ._7kuApa_placeholder ._7kuApa_icon {
  width: 20px;
  height: 20px;
  color: var(--color-text-secondary);
}

._7kuApa_uploader ._7kuApa_placeholder ._7kuApa_uploadingIcon {
  width: 20px;
  height: 20px;
  color: var(--color-primary);
}

._7kuApa_uploader ._7kuApa_placeholder ._7kuApa_actionDescription {
  font: var(--font-body-2);
  -webkit-user-select: none;
  user-select: none;
  margin-top: 4px;
}

._7kuApa_uploader:hover {
  cursor: pointer;
  border-color: var(--color-primary);
}

._7kuApa_uploader:hover ._7kuApa_placeholder ._7kuApa_icon {
  color: var(--color-primary);
}

._7kuApa_uploader._7kuApa_dragActive {
  cursor: copy;
  background-color: var(--color-hover-variant);
  border-color: var(--color-primary);
}

._7kuApa_uploader._7kuApa_dragActive ._7kuApa_placeholder ._7kuApa_icon {
  color: var(--color-primary);
}

._7kuApa_uploader._7kuApa_uploaderError {
  border-color: var(--color-error);
}

@keyframes _1lFu6a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _1lFu6a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._1lFu6a_imageUploader {
  border: 1px dashed var(--color-border);
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  padding: 15px 8px;
  display: flex;
  position: relative;
}

._1lFu6a_imageUploader ._1lFu6a_delete {
  display: none;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

._1lFu6a_imageUploader ._1lFu6a_container {
  height: 40px;
  width: 40px;
  display: flex;
}

._1lFu6a_imageUploader ._1lFu6a_container > img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  cursor: not-allowed;
}

._1lFu6a_imageUploader:hover ._1lFu6a_delete {
  display: block;
}

@keyframes K0z4Jq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes K0z4Jq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.K0z4Jq_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 8px;
}

.K0z4Jq_error {
  color: var(--color-error);
}

@keyframes RkZH6W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes RkZH6W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.RkZH6W_item {
  border: 1px solid var(--color-border);
  min-height: 80px;
  font: var(--font-label-2);
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--color-layer-1);
  color: var(--color-text);
  border-radius: 12px;
  align-items: center;
  padding: 20px;
  display: flex;
}

.RkZH6W_item .RkZH6W_icon {
  color: var(--color-text-secondary);
  vertical-align: middle;
  margin-right: 16px;
}

.RkZH6W_item .RkZH6W_icon > svg {
  display: block;
}

.RkZH6W_item .RkZH6W_content .RkZH6W_tag {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
}

.RkZH6W_item .RkZH6W_content .RkZH6W_trailingTag {
  margin-left: 4px;
}

.RkZH6W_item.RkZH6W_disabled {
  border-color: var(--color-layer-2);
  background-color: var(--color-layer-2);
}

.RkZH6W_item.RkZH6W_disabled:hover {
  cursor: not-allowed;
}

.RkZH6W_item:not(.RkZH6W_disabled).RkZH6W_selected {
  border-color: var(--color-primary);
  background-color: var(--color-hover-variant);
  color: var(--color-primary);
}

.RkZH6W_item:not(.RkZH6W_disabled).RkZH6W_selected .RkZH6W_icon {
  color: var(--color-primary);
}

.RkZH6W_item:not(.RkZH6W_disabled):hover {
  cursor: pointer;
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.RkZH6W_item:not(.RkZH6W_disabled):hover .RkZH6W_icon {
  color: var(--color-primary);
}

@keyframes -XdXDW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes -XdXDW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.-XdXDW_selector {
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  display: grid;
}

@keyframes _3zvtua_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3zvtua_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3zvtua_page {
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

._3zvtua_contentContainer {
  flex: 1;
  padding-bottom: 24px;
  overflow-y: auto;
}

._3zvtua_content {
  max-width: 858px;
  background-color: var(--color-layer-1);
  border-radius: 16px;
  flex-direction: column;
  margin: 0 auto 16px;
  padding: 48px;
  display: flex;
}

@keyframes jPstRq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes jPstRq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.jPstRq_inspire {
  background-color: var(--color-base);
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding: 16px 20px;
  display: flex;
}

.jPstRq_inspire .jPstRq_inspireContent {
  flex-direction: column;
  margin-right: 24px;
  display: flex;
}

.jPstRq_inspire .jPstRq_inspireContent .jPstRq_inspireTitle {
  font: var(--font-title-2);
  margin-bottom: 4px;
}

.jPstRq_inspire .jPstRq_inspireContent .jPstRq_inspireDescription {
  font: var(--font-body-2);
}

.jPstRq_inspire .jPstRq_button {
  border-color: var(--color-neutral-variant-80);
  padding-right: 28px;
}

.jPstRq_inspire .jPstRq_button:not(:disabled):not(:active):hover, .jPstRq_inspire .jPstRq_button:not(:disabled):active:hover {
  background: var(--color-layer-1) center / 90% no-repeat url("fireworks.ea9fa0dd.svg");
}

.jPstRq_inspire .jPstRq_button:not(:disabled):active:hover {
  background-color: #0000;
}

@keyframes _25xENG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _25xENG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._25xENG_preview {
  background: var(--color-surface-variant);
}

._25xENG_preview iframe {
  border: none;
}

._25xENG_preview._25xENG_web {
  position: relative;
}

._25xENG_preview._25xENG_web ._25xENG_deviceWrapper {
  width: 498px;
  height: 626.057px;
  margin: 0 auto;
  overflow: hidden;
}

._25xENG_preview._25xENG_web ._25xENG_deviceWrapper ._25xENG_device iframe {
  width: 700px;
  height: 880px;
  margin-top: -126.971px;
  margin-left: -101px;
  transform: scaleX(.711429)scaleY(.711429);
}

._25xENG_preview._25xENG_mobile {
  position: relative;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper {
  padding: 40px 0;
  overflow: hidden;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device {
  width: 260px;
  height: 606.667px;
  border-radius: 26px;
  margin: 0 auto;
  overflow: hidden;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device ._25xENG_topBar {
  align-items: center;
  padding: 12px 16px;
  display: flex;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device ._25xENG_topBar ._25xENG_time {
  font: var(--font-label-2);
  transform-origin: 0;
  flex: 1;
  transform: scale(.8);
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device._25xENG_dark {
  background: #1a1c1d;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device._25xENG_dark ._25xENG_topBar {
  color: #fff;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device._25xENG_light {
  background: #fff;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device._25xENG_light ._25xENG_topBar {
  color: #000;
}

._25xENG_preview._25xENG_mobile ._25xENG_deviceWrapper ._25xENG_device iframe {
  width: 390px;
  height: 844px;
  margin-top: -140.667px;
  margin-left: -65px;
  transform: scaleX(.666667)scaleY(.666667);
}

@keyframes DiRmtq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes DiRmtq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.DiRmtq_container .DiRmtq_button {
  padding: 8px;
}

.DiRmtq_container .DiRmtq_icon {
  color: var(--color-text-secondary);
}

.DiRmtq_container.DiRmtq_large .DiRmtq_icon {
  width: 24px;
  height: 24px;
}

.DiRmtq_container.DiRmtq_medium .DiRmtq_icon {
  width: 20px;
  height: 20px;
}

.DiRmtq_container.DiRmtq_small .DiRmtq_icon {
  width: 16px;
  height: 16px;
}

@keyframes FYb9iq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes FYb9iq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.FYb9iq_tab {
  font: var(--font-label-2);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: 6px;
  align-items: center;
  padding: 4px 8px;
  display: flex;
}

.FYb9iq_tab .FYb9iq_icon {
  color: var(--color-primary);
  margin-right: 8px;
}

.FYb9iq_tab .FYb9iq_icon > svg {
  display: block;
}

.FYb9iq_tab.FYb9iq_selected {
  color: var(--color-layer-1);
  background-color: var(--color-inverse-primary);
}

.FYb9iq_tab.FYb9iq_selected .FYb9iq_icon {
  color: var(--color-static-white);
  opacity: .7;
}

.FYb9iq_tab:not(.FYb9iq_selected):hover {
  background-color: var(--color-hover-variant);
}

@keyframes taJSVa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes taJSVa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.taJSVa_container {
  background-color: var(--color-layer-1);
  border: 1px solid var(--color-surface-5);
  border-radius: 8px;
  align-items: center;
  gap: 12px;
  padding: 4px;
  display: flex;
}

@keyframes aEpV7G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes aEpV7G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.aEpV7G_container {
  background-color: var(--color-neutral-variant-90);
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  display: flex;
}

@keyframes N3FFUG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes N3FFUG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.N3FFUG_content {
  min-width: min-content;
  justify-content: center;
  padding: 0 68px;
  display: flex;
}

.N3FFUG_content > div {
  max-width: 800px;
  min-width: 540px;
  flex: 1;
}

.N3FFUG_content .N3FFUG_config {
  background-color: var(--color-layer-1);
  border-radius: 8px;
  margin-right: 24px;
  padding: 48px;
}

.N3FFUG_content .N3FFUG_config .N3FFUG_title {
  font: var(--font-title-1);
  margin-top: 24px;
}

.N3FFUG_content .N3FFUG_config .N3FFUG_authnSelector {
  grid-template-columns: repeat(2, 1fr);
}

.N3FFUG_content .N3FFUG_preview {
  align-self: flex-start;
  position: sticky;
  top: 0;
}

.N3FFUG_continueActions {
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  display: flex;
}

@keyframes OZpvHa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes OZpvHa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.OZpvHa_fieldWrapper {
  padding: 8px;
}

.OZpvHa_fieldWrapper > :not(:first-child) {
  margin-top: 24px;
}

.OZpvHa_fieldWrapper .OZpvHa_title {
  background-color: var(--color-layer-2);
  width: 80px;
  height: 16px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.OZpvHa_fieldWrapper .OZpvHa_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite OZpvHa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.OZpvHa_fieldWrapper .OZpvHa_field {
  background-color: var(--color-layer-2);
  width: 100%;
  height: 44px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.OZpvHa_fieldWrapper .OZpvHa_field:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite OZpvHa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.OZpvHa_fieldWrapper:not(:first-child) {
  margin-top: 12px;
}

.OZpvHa_preview {
  background: var(--color-surface-variant);
  border-radius: 12px;
  padding: 24px;
  overflow: hidden;
}

.OZpvHa_preview .OZpvHa_header {
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 8px;
  display: flex;
}

.OZpvHa_preview .OZpvHa_header .OZpvHa_actions {
  gap: 12px;
  display: flex;
}

.OZpvHa_preview .OZpvHa_header .OZpvHa_smallButton {
  width: 30px;
  height: 30px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-left: 10px;
  position: relative;
  overflow: hidden;
}

.OZpvHa_preview .OZpvHa_header .OZpvHa_smallButton:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite OZpvHa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.OZpvHa_preview .OZpvHa_header .OZpvHa_button {
  width: 104px;
  height: 30px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-left: 10px;
  position: relative;
  overflow: hidden;
}

.OZpvHa_preview .OZpvHa_header .OZpvHa_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite OZpvHa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.OZpvHa_preview .OZpvHa_mobile {
  width: 375px;
  height: 667px;
  background: var(--color-surface);
  transform-origin: top;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  transform: scale(.6);
}

.OZpvHa_preview .OZpvHa_mobile .OZpvHa_logo {
  width: 64px;
  height: 64px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 64px;
  position: relative;
  overflow: hidden;
}

.OZpvHa_preview .OZpvHa_mobile .OZpvHa_logo:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite OZpvHa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.OZpvHa_preview .OZpvHa_mobile .OZpvHa_slogan {
  width: 177px;
  height: 16px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin: 12px 0 40px;
  position: relative;
  overflow: hidden;
}

.OZpvHa_preview .OZpvHa_mobile .OZpvHa_slogan:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite OZpvHa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.OZpvHa_preview .OZpvHa_mobile .OZpvHa_field {
  width: 100%;
  height: 36px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 12px;
  position: relative;
  overflow: hidden;
}

.OZpvHa_preview .OZpvHa_mobile .OZpvHa_field:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite OZpvHa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.OZpvHa_preview .OZpvHa_mobile .OZpvHa_button {
  width: 100%;
  height: 36px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 40px;
  position: relative;
  overflow: hidden;
}

.OZpvHa_preview .OZpvHa_mobile .OZpvHa_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite OZpvHa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.OZpvHa_preview .OZpvHa_mobile .OZpvHa_social {
  width: 180px;
  height: 24px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 12px;
  position: relative;
  overflow: hidden;
}

.OZpvHa_preview .OZpvHa_mobile .OZpvHa_social:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite OZpvHa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.Am3ekq_container {
  width: 40px;
  height: 40px;
  background-color: var(--color-hover);
  border-radius: 8px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Am3ekq_container > img {
  width: 28px;
  height: 28px;
}

.Am3ekq_container.Am3ekq_large {
  width: 60px;
  height: 60px;
  border-radius: 12px;
}

.Am3ekq_container.Am3ekq_large > img {
  width: 42px;
  height: 42px;
}

.Am3ekq_container.Am3ekq_small {
  width: 20px;
  height: 20px;
  border-radius: unset;
  background-color: #0000;
}

.Am3ekq_container.Am3ekq_small > img {
  width: 20px;
  height: 20px;
}

.Am3ekq_logo {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.Am3ekq_logo.Am3ekq_large {
  width: 60px;
  height: 60px;
}

.Am3ekq_logo.Am3ekq_small {
  width: 20px;
  height: 20px;
}

@keyframes k8ahaW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes k8ahaW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.k8ahaW_title {
  font: var(--font-title-1);
  margin-top: 24px;
}

.k8ahaW_description {
  font: var(--font-body-2);
  margin-top: 12px;
}

.k8ahaW_form {
  width: 100%;
  margin-top: 24px;
}

.k8ahaW_form .k8ahaW_titleSelector {
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
}

.k8ahaW_form .k8ahaW_option {
  min-height: 100px;
}

@keyframes SoD9uq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes SoD9uq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.SoD9uq_row {
  color: var(--color-neutral-variant-30);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  width: calc(100% - 40px);
  background: none;
  border: none;
  border-radius: 8px;
  align-items: center;
  margin: 4px 24px 4px 16px;
  padding: 8px;
  text-decoration: none;
  display: flex;
}

.SoD9uq_row .SoD9uq_icon {
  height: 20px;
  flex: 0 0 20px;
}

.SoD9uq_row .SoD9uq_icon svg {
  color: var(--color-neutral-variant-50);
  width: 20px;
  height: 20px;
}

.SoD9uq_row:not(.SoD9uq_active):hover {
  background: var(--color-hover-variant);
}

.SoD9uq_row.SoD9uq_active {
  background: var(--color-surface-5);
  color: var(--color-text-link);
}

.SoD9uq_row.SoD9uq_active .SoD9uq_icon svg {
  color: var(--color-primary-50);
}

.SoD9uq_row > div + div {
  margin-left: 16px;
}

.SoD9uq_row .SoD9uq_title {
  font: var(--font-label-2);
}

@keyframes s9DuQG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes s9DuQG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.s9DuQG_title {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
  font: var(--font-section-head-2);
  color: var(--color-neutral-variant-70);
  padding: 8px 24px;
}

@keyframes _3ZtC7q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3ZtC7q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3ZtC7q_sidebar {
  width: 248px;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 24px;
  display: flex;
  overflow-y: auto;
}

._3ZtC7q_sidebar ._3ZtC7q_spacer {
  flex: 1 1 0;
  margin: 0;
}

@keyframes fkLd0a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fkLd0a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.fkLd0a_container {
  border: 1px solid var(--color-divider);
  background: var(--color-layer-1);
  border-radius: 12px;
  padding: 20px 24px;
}

.fkLd0a_container > div:not(:first-child) {
  margin-top: 8px;
}

.fkLd0a_usage {
  font: var(--font-title-2);
  vertical-align: middle;
  align-items: center;
  display: inline-flex;
}

.fkLd0a_planCycle {
  font: var(--font-body-2);
}

.fkLd0a_usageBar {
  background-color: var(--color-layer-2);
  height: 18px;
  border-radius: 4px;
}

.fkLd0a_usageBar .fkLd0a_usageBarInner {
  background-color: var(--color-primary-80);
  height: 18px;
  border-radius: 4px;
}

.fkLd0a_usageBar .fkLd0a_usageBarInner.fkLd0a_overuse {
  background-color: var(--color-error-40);
}

@keyframes _p8-jq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _p8-jq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._p8-jq_inlineNotification {
  font: var(--font-body-2);
  border-radius: 8px;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  display: flex;
}

._p8-jq_inlineNotification._p8-jq_shadow {
  border: 1px solid var(--color-border);
  box-shadow: var(--shadow-1);
}

._p8-jq_inlineNotification ._p8-jq_icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

._p8-jq_inlineNotification ._p8-jq_content {
  overflow-wrap: break-word;
  flex: 1;
  overflow: hidden;
}

._p8-jq_inlineNotification ._p8-jq_content:has( > p) {
  margin: -16px 0;
}

._p8-jq_inlineNotification._p8-jq_info {
  background: var(--color-info-container);
}

._p8-jq_inlineNotification._p8-jq_info ._p8-jq_icon {
  color: var(--color-on-info-container);
}

._p8-jq_inlineNotification._p8-jq_alert {
  background: var(--color-alert-container);
}

._p8-jq_inlineNotification._p8-jq_success {
  background: var(--color-success-container);
}

._p8-jq_inlineNotification._p8-jq_error {
  background: var(--color-error-container);
}

.kupZfW_linkButton {
  text-decoration: none;
}

@keyframes BcZZjq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes BcZZjq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.BcZZjq_container {
  border: 1px solid var(--color-divider);
  background: var(--color-layer-1);
  border-radius: 12px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  display: flex;
}

.BcZZjq_billInfo {
  flex-direction: column;
  display: flex;
}

.BcZZjq_price {
  font: var(--font-title-2);
  align-items: center;
  display: flex;
}

.BcZZjq_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 8px;
}

.BcZZjq_articleLink {
  color: var(--color-text-secondary);
  text-underline-offset: 2px;
  text-decoration: underline;
}

.BcZZjq_articleLink:active {
  color: var(--color-text-secondary);
}

._23BWOW_linkButton {
  text-decoration: none;
}

._23BWOW_strong {
  font-weight: 500;
}

@keyframes X6m6Xa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes X6m6Xa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.X6m6Xa_modalLayoutWrapper {
  position: relative;
}

.X6m6Xa_headerIcon {
  width: 48px;
  height: 48px;
}

.X6m6Xa_content {
  background-color: var(--color-layer-light);
  border-radius: 12px;
  padding: 24px;
}

.X6m6Xa_content .X6m6Xa_title {
  font: var(--font-title-3);
}

.X6m6Xa_content .X6m6Xa_hint {
  font: var(--font-body-2);
  margin-top: 12px;
}

.X6m6Xa_content .X6m6Xa_hint > div:not(:last-child) {
  margin-bottom: 16px;
}

.X6m6Xa_content .X6m6Xa_hint .X6m6Xa_strong {
  font: var(--font-label-2);
}

.X6m6Xa_fireworks {
  pointer-events: none;
  position: absolute;
  top: 0;
}

.X6m6Xa_fireworks .X6m6Xa_fireworksImage {
  transform: translateX(-10.5%)translateY(-18%);
}

@keyframes fTcdFG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fTcdFG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.fTcdFG_suspendedPage {
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.fTcdFG_suspendedPage .fTcdFG_image {
  flex-shrink: 0;
}

.fTcdFG_suspendedPage .fTcdFG_title {
  font: var(--font-title-1);
}

.fTcdFG_suspendedPage .fTcdFG_description {
  font: var(--font-body-2);
  text-align: center;
  width: 470px;
  margin: 8px;
}

.fTcdFG_suspendedPage .fTcdFG_linkButton {
  margin-top: 16px;
  text-decoration: none;
}

@keyframes wZF7yG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes wZF7yG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.wZF7yG_app {
  flex-direction: column;
  display: flex;
  position: absolute;
  inset: 0;
}

.wZF7yG_topbarShadow {
  box-shadow: var(--shadow-2);
}

@keyframes _60TtZq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _60TtZq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._60TtZq_error {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

._60TtZq_error > :not(:first-child) {
  margin-top: 8px;
}

._60TtZq_error ._60TtZq_image {
  width: 200px;
  height: 200px;
}

._60TtZq_error ._60TtZq_title {
  text-align: center;
  font: var(--font-label-2);
}

._60TtZq_error ._60TtZq_content {
  font: var(--font-body-2);
  color: var(--color-neutral-50);
}

@keyframes K4QE8a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes K4QE8a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.K4QE8a_responsiveWrapper {
  width: 100%;
  container-type: inline-size;
}

.K4QE8a_responsiveWrapper .K4QE8a_container {
  width: 100%;
  justify-content: space-between;
  padding: 24px 32px;
  display: flex;
}

.K4QE8a_responsiveWrapper .K4QE8a_introduction {
  width: calc(29.1667% - 8.5px);
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.K4QE8a_responsiveWrapper .K4QE8a_form {
  width: calc(66.6667% - 4px);
}

@container (max-width: 600px) {
  .K4QE8a_responsiveWrapper .K4QE8a_container {
    justify-content: unset;
    flex-direction: column;
    gap: 16px;
  }

  .K4QE8a_responsiveWrapper .K4QE8a_introduction, .K4QE8a_responsiveWrapper .K4QE8a_form {
    width: 100%;
  }
}

@keyframes -TV4Uq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes -TV4Uq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.-TV4Uq_title {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--color-neutral-variant-60);
  align-items: center;
  gap: 8px;
  display: flex;
}

.-TV4Uq_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  white-space: pre-line;
}

@keyframes iExUWa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes iExUWa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.iExUWa_title {
  background-color: var(--color-layer-2);
  height: 16px;
  width: 80px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.iExUWa_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite iExUWa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.iExUWa_text {
  background-color: var(--color-layer-2);
  width: 100%;
  height: 10px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.iExUWa_text:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite iExUWa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.iExUWa_text + .iExUWa_text {
  margin-top: 8px;
}

.iExUWa_field {
  background-color: var(--color-layer-2);
  width: 100%;
  height: 44px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.iExUWa_field:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite iExUWa_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.iExUWa_field + .iExUWa_field {
  margin-top: 24px;
}

@keyframes _V32qG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _V32qG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._V32qG_container {
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow-y: auto;
}

._V32qG_container > :not(:first-child) {
  margin-top: 16px;
}

._V32qG_container ._V32qG_header {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  align-items: center;
  padding: 24px;
  display: flex;
}

._V32qG_container ._V32qG_header ._V32qG_icon {
  background-color: var(--color-layer-2);
  width: 60px;
  height: 60px;
  border-radius: 12px;
  margin-right: 24px;
  position: relative;
  overflow: hidden;
}

._V32qG_container ._V32qG_header ._V32qG_icon:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite _V32qG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

._V32qG_container ._V32qG_header ._V32qG_wrapper {
  flex-direction: column;
  display: flex;
}

._V32qG_container ._V32qG_header ._V32qG_wrapper ._V32qG_title {
  background-color: var(--color-layer-2);
  width: 113px;
  height: 28px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

._V32qG_container ._V32qG_header ._V32qG_wrapper ._V32qG_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite _V32qG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

._V32qG_container ._V32qG_header ._V32qG_wrapper ._V32qG_tags {
  background-color: var(--color-layer-2);
  width: 453px;
  height: 20px;
  border-radius: 8px;
  margin-top: 12px;
  position: relative;
  overflow: hidden;
}

._V32qG_container ._V32qG_header ._V32qG_wrapper ._V32qG_tags:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite _V32qG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

._V32qG_container ._V32qG_header ._V32qG_button {
  background-color: var(--color-layer-2);
  width: 158px;
  height: 44px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

._V32qG_container ._V32qG_header ._V32qG_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite _V32qG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

._V32qG_container ._V32qG_tabBar {
  width: 100%;
  height: 28px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

._V32qG_container ._V32qG_tabBar:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite _V32qG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes _1JPFKW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _1JPFKW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._1JPFKW_container {
  min-height: 100%;
  min-width: min-content;
  flex-direction: column;
  display: flex;
}

._1JPFKW_container > :not(:first-child) {
  margin-top: 16px;
}

._1JPFKW_backLink {
  -webkit-user-select: none;
  user-select: none;
  margin: 4px 0 0 4px;
}

@keyframes sE9p8W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes sE9p8W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.sE9p8W_content {
  min-width: 200px;
  padding: 4px;
}

.sE9p8W_dropdownTitle {
  padding: 12px;
}

.sE9p8W_actionMenuButton {
  display: inline-block;
}

@keyframes UyyloG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes UyyloG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.UyyloG_container {
  color: var(--color-text);
  font: var(--font-body-2);
  cursor: default;
  border-radius: 6px;
  font-family: Roboto Mono, monospace;
  display: inline-block;
}

.UyyloG_container.UyyloG_contained {
  background: var(--color-layer-2);
  padding: 4px 8px;
}

.UyyloG_container.UyyloG_border {
  background: var(--color-layer-2);
  border: 1px solid var(--color-border);
  padding: 4px 12px;
}

.UyyloG_container.UyyloG_fullWidth {
  width: 100%;
}

.UyyloG_container .UyyloG_row {
  cursor: text;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.UyyloG_container .UyyloG_row .UyyloG_content {
  text-overflow: ellipsis;
  flex: 1;
  overflow: hidden;
}

.UyyloG_container .UyyloG_row .UyyloG_content.UyyloG_wrapContent {
  text-overflow: unset;
  word-break: break-all;
}

.UyyloG_container .UyyloG_row .UyyloG_copyToolTipAnchor {
  margin-left: 8px;
}

.UyyloG_container.UyyloG_default .UyyloG_row .UyyloG_copyToolTipAnchor {
  margin-left: 12px;
}

.UyyloG_container.UyyloG_small .UyyloG_row .UyyloG_copyToolTipAnchor {
  margin-left: 4px;
}

.UyyloG_container.UyyloG_small .UyyloG_row .UyyloG_iconButton {
  height: 20px;
  width: 20px;
}

.UyyloG_container.UyyloG_small .UyyloG_row .UyyloG_iconButton .UyyloG_icon svg {
  width: 12px;
  height: 12px;
}

@keyframes ReARlW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ReARlW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ReARlW_header {
  flex: 0;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 24px 32px;
  display: flex;
}

.ReARlW_header .ReARlW_copyId {
  flex: 1;
}

.ReARlW_header .ReARlW_operations {
  align-items: center;
  gap: 12px;
  display: flex;
}

.ReARlW_header .ReARlW_operations svg {
  color: var(--color-text-secondary);
}

.ReARlW_header .ReARlW_metadata {
  flex: 1;
  overflow: hidden;
}

.ReARlW_header .ReARlW_metadata .ReARlW_name {
  font: var(--font-title-1);
  color: var(--color-text);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReARlW_header .ReARlW_metadata .ReARlW_row {
  white-space: nowrap;
  align-items: center;
  gap: 8px;
  display: flex;
}

.ReARlW_header .ReARlW_metadata .ReARlW_row .ReARlW_text {
  font: var(--font-label-2);
  color: var(--color-text-secondary);
}

.ReARlW_header .ReARlW_metadata .ReARlW_row .ReARlW_verticalBar {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 12px;
}

.ReARlW_icon {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
}

@keyframes rotating_a964f6 {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes shimmer_a964f6 {
  100% {
    transform: translateX(100%);
  }
}

.content_a964f6 {
  width: 50%;
  max-width: 960px;
  min-width: 860px;
  background: var(--color-base);
  outline: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.content_a964f6 .wrapper_a964f6 {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.content_a964f6 .wrapper_a964f6 .header_a964f6 {
  background-color: var(--color-layer-1);
  height: 64px;
  flex-shrink: 0;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

.content_a964f6 .wrapper_a964f6 .body_a964f6 {
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow-y: auto;
}

.overlay_a964f6 {
  background: var(--color-drawer-overlay);
  position: fixed;
  inset: 0;
}

.ReactModal__Content[role="drawer"] {
  transition: transform .3s ease-in-out;
  transform: translateX(100%);
}

.ReactModal__Content--after-open[role="drawer"] {
  transform: translateX(0);
}

.ReactModal__Content--before-close[role="drawer"] {
  transform: translateX(100%);
}

@keyframes AFg1_W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes AFg1_W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.AFg1_W_nav {
  border-bottom: 1px solid var(--color-surface-5);
  margin-top: 4px;
  display: flex;
}

@keyframes Vu2b8a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Vu2b8a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Vu2b8a_item {
  align-items: center;
  display: flex;
}

.Vu2b8a_item:not(:last-child) {
  margin-right: 24px;
}

.Vu2b8a_item .Vu2b8a_link {
  font: var(--font-label-2);
  border-radius: 4px;
  margin-bottom: 4px;
  padding: 2px 6px;
}

.Vu2b8a_item .Vu2b8a_link a {
  color: var(--color-neutral-30);
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
}

.Vu2b8a_item .Vu2b8a_link:hover {
  background-color: var(--color-hover-variant);
}

.Vu2b8a_item .Vu2b8a_selected {
  color: var(--color-text-link);
  position: relative;
}

.Vu2b8a_item .Vu2b8a_selected a {
  color: var(--color-text-link);
}

.Vu2b8a_item .Vu2b8a_selected:after {
  content: "";
  border-top: 2px solid var(--color-text-link);
  border-radius: 8px 8px 0 0;
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
}

.Vu2b8a_item .Vu2b8a_errors {
  font: var(--font-label-3);
  color: var(--color-white);
  background-color: var(--color-error-50);
  vertical-align: middle;
  cursor: default;
  border-radius: 10px;
  margin-bottom: 4px;
  margin-left: 2px;
  padding: 2px 6px;
}

@keyframes _7CsCrG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _7CsCrG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._7CsCrG_card {
  background-color: var(--color-layer-1);
  min-width: 220px;
  max-width: 460px;
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 12px;
  display: flex;
}

._7CsCrG_card._7CsCrG_hasButton {
  cursor: default;
}

._7CsCrG_card:not(._7CsCrG_hasButton):hover {
  box-shadow: var(--shadow-2);
}

._7CsCrG_card._7CsCrG_hasBorder {
  border-color: var(--color-divider);
}

._7CsCrG_card ._7CsCrG_header {
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

._7CsCrG_card ._7CsCrG_logo {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}

._7CsCrG_card ._7CsCrG_tagWrapper {
  align-items: center;
  gap: 4px;
  display: flex;
}

._7CsCrG_card ._7CsCrG_infoWrapper {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

._7CsCrG_card ._7CsCrG_flexRow {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

._7CsCrG_card ._7CsCrG_name {
  font: var(--font-label-2);
  color: var(--color-text);
  margin-right: 4px;
}

._7CsCrG_card ._7CsCrG_description {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

._7CsCrG_logoSkeleton {
  width: 48px;
  height: 48px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
}

._7CsCrG_logoSkeleton:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite _7CsCrG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes _5Dl9qW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _5Dl9qW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._5Dl9qW_guideGroup {
  max-width: 1876px;
  flex-direction: column;
  display: flex;
  container-type: inline-size;
}

._5Dl9qW_guideGroup label {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
  margin-bottom: 16px;
}

._5Dl9qW_guideGroup ._5Dl9qW_grid {
  gap: 16px 12px;
  display: grid;
}

@container (max-width: 680px) {
  ._5Dl9qW_guideGroup ._5Dl9qW_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@container (min-width: 681px) and (max-width: 1080px) {
  ._5Dl9qW_guideGroup ._5Dl9qW_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@container (min-width: 1081px) {
  ._5Dl9qW_guideGroup ._5Dl9qW_grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@keyframes UaW6SG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes UaW6SG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.UaW6SG_drawerContainer {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.UaW6SG_header {
  background-color: var(--color-layer-1);
  font: var(--font-title-2);
  color: var(--color-text);
  box-shadow: var(--shadow-1);
  z-index: 1;
  flex: 0 0 64px;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

.UaW6SG_header .UaW6SG_separator {
  border-left: 1px solid var(--color-border);
  height: 20px;
  width: 0;
  margin: 0 20px;
}

.UaW6SG_cardGroup {
  flex: 1;
  padding: 24px;
}

.UaW6SG_guide {
  flex: 1;
  padding: 24px;
  overflow: hidden;
}

@keyframes UAORjW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes UAORjW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.UAORjW_header {
  background-color: var(--color-base);
  width: 100%;
  height: 64px;
  flex-shrink: 0;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

.UAORjW_header .UAORjW_separator {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 20px;
  margin: 0 20px 0 16px;
}

.UAORjW_header .UAORjW_closeIcon {
  color: var(--color-text-secondary);
}

@keyframes GSUL-G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes GSUL-G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.GSUL-G_requestSdkButton {
  margin-right: 60px;
}

@media screen and (max-width: 918px) {
  .GSUL-G_requestSdkButton {
    margin-right: 0;
  }
}

@keyframes K2_WUa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes K2_WUa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.K2_WUa_modalContainer {
  width: 100vw;
  height: 100vh;
  background-color: var(--color-base);
  flex-direction: column;
  display: flex;
  overflow-x: auto;
}

.K2_WUa_modalContainer > * {
  min-width: 750px;
}

.K2_WUa_guide {
  flex: 1;
}

@keyframes nfnr9a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nfnr9a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nfnr9a_permissionPage {
  height: 100%;
}

.nfnr9a_deleteConfirm > :not(:first-child) {
  margin-top: 24px;
}

.nfnr9a_deleteConfirm .nfnr9a_description {
  font: var(--font-body-2);
}

.nfnr9a_deleteConfirm .nfnr9a_highlight {
  color: var(--color-primary-50);
}

@keyframes XhA89q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes XhA89q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.XhA89q_search {
  align-items: center;
  display: flex;
}

.XhA89q_search > :not(:first-child) {
  margin-left: 8px;
}

.XhA89q_search .XhA89q_searchIcon {
  color: var(--color-text-secondary);
}

.XhA89q_search canvas {
  display: none;
}

@keyframes i6FXZq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes i6FXZq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.i6FXZq_container {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.i6FXZq_container .i6FXZq_positionInfo {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.i6FXZq_container .i6FXZq_pagination {
  height: 28px;
  justify-content: right;
  margin: 0;
  padding-inline-start: 16px;
  display: flex;
}

.i6FXZq_container .i6FXZq_pagination li {
  list-style: none;
}

.i6FXZq_container .i6FXZq_pagination li:not(:first-child) {
  margin-left: 8px;
}

.i6FXZq_container .i6FXZq_pagination li .i6FXZq_button {
  min-width: 28px;
  height: 28px;
  text-overflow: unset;
  border: 1px solid var(--color-border);
  border-radius: 6px;
  padding: 0 6px;
  display: block;
}

.i6FXZq_container .i6FXZq_pagination li .i6FXZq_button > span {
  margin: 0 auto;
}

.i6FXZq_container .i6FXZq_pagination li .i6FXZq_button.i6FXZq_active {
  border-color: var(--color-text-link);
  color: var(--color-text-link);
}

.i6FXZq_container li.i6FXZq_disabled {
  cursor: not-allowed;
}

.i6FXZq_container li.i6FXZq_disabled .i6FXZq_button {
  background: var(--color-neutral-95);
}

.i6FXZq_container.i6FXZq_pico .i6FXZq_pagination {
  height: 20px;
}

.i6FXZq_container.i6FXZq_pico .i6FXZq_pagination li .i6FXZq_button {
  height: 20px;
  min-width: unset;
  border: unset;
  background: unset;
  border-radius: 4px;
  padding: 0;
}

@keyframes YeJh6G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes YeJh6G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.YeJh6G_rect {
  background-color: var(--color-layer-2);
  height: 26px;
  max-width: 344px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.YeJh6G_rect:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite YeJh6G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.YeJh6G_row .YeJh6G_itemPreview {
  align-items: center;
  display: flex;
}

.YeJh6G_row .YeJh6G_itemPreview .YeJh6G_avatar {
  background-color: var(--color-layer-2);
  width: 40px;
  height: 40px;
  border-radius: 12px;
  flex-shrink: 0;
  margin-right: 16px;
  position: relative;
  overflow: hidden;
}

.YeJh6G_row .YeJh6G_itemPreview .YeJh6G_avatar:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite YeJh6G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.YeJh6G_row .YeJh6G_itemPreview .YeJh6G_content {
  width: 100%;
}

.YeJh6G_row .YeJh6G_itemPreview .YeJh6G_content .YeJh6G_title {
  background-color: var(--color-layer-2);
  height: 12px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.YeJh6G_row .YeJh6G_itemPreview .YeJh6G_content .YeJh6G_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite YeJh6G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.YeJh6G_row .YeJh6G_itemPreview .YeJh6G_content .YeJh6G_subTitle {
  background-color: var(--color-layer-2);
  height: 8px;
  border-radius: 8px;
  margin-top: 8px;
  position: relative;
  overflow: hidden;
}

.YeJh6G_row .YeJh6G_itemPreview .YeJh6G_content .YeJh6G_subTitle:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite YeJh6G_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.YeJh6G_row .YeJh6G_rect {
  height: 32px;
}

@keyframes yl7C0q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes yl7C0q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.yl7C0q_tableEmptyWrapper {
  border-bottom: unset;
}

.yl7C0q_tableEmptyWrapper .yl7C0q_content {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.yl7C0q_tableEmptyWrapper .yl7C0q_content .yl7C0q_topSpace {
  flex: 2;
}

.yl7C0q_tableEmptyWrapper .yl7C0q_content .yl7C0q_bottomSpace {
  flex: 3;
}

@keyframes KxIFNq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes KxIFNq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.KxIFNq_tableError {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.KxIFNq_tableError > :not(:first-child) {
  margin-top: 8px;
}

.KxIFNq_tableError .KxIFNq_title {
  text-align: center;
  font: var(--font-label-2);
}

.KxIFNq_tableError .KxIFNq_content {
  font: var(--font-body-2);
  color: var(--color-neutral-50);
}

@keyframes Orfd3G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Orfd3G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Orfd3G_container {
  min-width: min-content;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.Orfd3G_tableContainer {
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.Orfd3G_tableContainer .Orfd3G_filterContainer {
  background-color: var(--color-layer-1);
  border-radius: 12px 12px 0 0;
  padding: 12px 12px 0;
}

.Orfd3G_tableContainer .Orfd3G_filterContainer .Orfd3G_filter {
  border-bottom: 1px solid var(--color-divider);
  padding-bottom: 12px;
}

.Orfd3G_tableContainer table {
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  border: none;
}

.Orfd3G_tableContainer .Orfd3G_headerTable {
  background-color: var(--color-layer-1);
  border-radius: 12px 12px 0 0;
  padding: 0 12px;
}

.Orfd3G_tableContainer .Orfd3G_headerTable thead tr th {
  font: var(--font-label-2);
  color: var(--color-text);
  border-bottom: unset;
  text-align: left;
  padding: 12px;
}

.Orfd3G_tableContainer .Orfd3G_headerTable.Orfd3G_hideTopBorderRadius {
  border-radius: 0;
}

.Orfd3G_tableContainer .Orfd3G_bodyTable {
  background-color: var(--color-layer-1);
  border-radius: 0 0 12px 12px;
  padding: 0 12px 12px;
  overflow-y: auto;
}

.Orfd3G_tableContainer .Orfd3G_bodyTable tbody tr {
  cursor: default;
}

.Orfd3G_tableContainer .Orfd3G_bodyTable tbody tr td {
  font: var(--font-body-2);
  border-top: 1px solid var(--color-divider);
  border-bottom: unset;
  padding: 12px;
}

.Orfd3G_tableContainer .Orfd3G_bodyTable tbody tr.Orfd3G_clickable {
  cursor: pointer;
}

.Orfd3G_tableContainer .Orfd3G_bodyTable.Orfd3G_empty, .Orfd3G_tableContainer .Orfd3G_bodyTable.Orfd3G_empty table, .Orfd3G_tableContainer .Orfd3G_bodyTable.Orfd3G_empty table tbody {
  height: 100%;
}

.Orfd3G_tableContainer.Orfd3G_hasBorder .Orfd3G_filterContainer {
  border: 1px solid var(--color-divider);
  border-bottom: unset;
}

.Orfd3G_tableContainer.Orfd3G_hasBorder .Orfd3G_filterContainer .Orfd3G_filter {
  border-bottom: unset;
}

.Orfd3G_tableContainer.Orfd3G_hasBorder .Orfd3G_headerTable {
  border: 1px solid var(--color-divider);
  padding: 0;
}

.Orfd3G_tableContainer.Orfd3G_hasBorder .Orfd3G_headerTable tr th:first-child {
  padding-left: 28px;
}

.Orfd3G_tableContainer.Orfd3G_hasBorder .Orfd3G_headerTable tr th:last-child {
  padding-right: 28px;
}

.Orfd3G_tableContainer.Orfd3G_hasBorder .Orfd3G_bodyTable {
  border: 1px solid var(--color-divider);
  border-top: unset;
  padding: 0;
}

.Orfd3G_tableContainer.Orfd3G_hasBorder .Orfd3G_bodyTable tr {
  height: 56px;
}

.Orfd3G_tableContainer.Orfd3G_hasBorder .Orfd3G_bodyTable tr:first-child td {
  border-top: 1px solid #0000;
}

.Orfd3G_tableContainer.Orfd3G_hasBorder .Orfd3G_bodyTable tr td:first-child {
  padding-left: 28px;
}

.Orfd3G_tableContainer.Orfd3G_hasBorder .Orfd3G_bodyTable tr td:last-child {
  padding-right: 28px;
}

.Orfd3G_tableContainer .Orfd3G_bodyTable tr.Orfd3G_hoverEffect:hover {
  background: var(--color-hover);
}

.Orfd3G_tableContainer .Orfd3G_bodyTable tr.Orfd3G_hoverEffect:hover td, .Orfd3G_tableContainer .Orfd3G_bodyTable tr.Orfd3G_hoverEffect:hover + tr td {
  border-top: 1px solid #0000;
}

.Orfd3G_tableContainer .Orfd3G_bodyTable tr.Orfd3G_hoverEffect:hover td:first-child {
  border-radius: 8px 0 0 8px;
}

.Orfd3G_tableContainer .Orfd3G_bodyTable tr.Orfd3G_hoverEffect:hover td:last-child {
  border-radius: 0 8px 8px 0;
}

.Orfd3G_tableContainer.Orfd3G_hasBorder .Orfd3G_bodyTable tr.Orfd3G_hoverEffect:hover td:first-child, .Orfd3G_tableContainer.Orfd3G_hasBorder .Orfd3G_bodyTable tr.Orfd3G_hoverEffect:hover td:last-child {
  border-radius: 0;
}

.Orfd3G_footer {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.Orfd3G_pagination {
  margin-top: 16px;
}

@keyframes uyn--W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes uyn--W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.uyn--W_placeholder {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.uyn--W_placeholder .uyn--W_image > svg {
  width: 200px;
  height: 200px;
}

.uyn--W_placeholder .uyn--W_title {
  font: var(--font-label-2);
}

.uyn--W_placeholder .uyn--W_description {
  max-width: 600px;
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 4px;
}

.uyn--W_placeholder .uyn--W_action {
  margin-top: 24px;
}

@keyframes ljXalG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ljXalG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ljXalG_empty {
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  display: flex;
}

.ljXalG_empty .ljXalG_title {
  font: var(--font-label-2);
  margin-bottom: 8px;
}

.ljXalG_empty .ljXalG_description {
  font: var(--font-body-2);
  color: var(--color-neutral-50);
  margin-bottom: 8px;
}

.ljXalG_empty.ljXalG_large .ljXalG_image {
  width: 256px;
  height: 256px;
  margin-bottom: 24px;
}

.ljXalG_empty.ljXalG_medium .ljXalG_image {
  width: 200px;
  height: 200px;
  margin-bottom: 24px;
}

.ljXalG_empty.ljXalG_small .ljXalG_image {
  width: 128px;
  height: 128px;
}

.ljXalG_empty .ljXalG_topSpace {
  flex: 2;
}

.ljXalG_empty .ljXalG_bottomSpace {
  flex: 3;
}

@keyframes vPj7DW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes vPj7DW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.vPj7DW_permissionTable {
  color: var(--color-text);
  flex: 1;
  margin-bottom: 24px;
}

.vPj7DW_permissionTable .vPj7DW_filter {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.vPj7DW_permissionTable .vPj7DW_filter .vPj7DW_createButton {
  margin-left: 8px;
}

.vPj7DW_permissionTable .vPj7DW_description {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.vPj7DW_permissionTable .vPj7DW_link {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.vPj7DW_permissionTable .vPj7DW_deleteColumn {
  text-align: right;
}

@keyframes lK2u-q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes lK2u-q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.lK2u-q_container {
  background-color: var(--color-info-container);
  align-items: center;
  gap: 24px;
  margin: 0 -24px -24px;
  padding: 24px;
  display: flex;
}

.lK2u-q_container .lK2u-q_description {
  font: var(--font-body-2);
  flex: 1 0;
}

@keyframes _7BqVIW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _7BqVIW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._7BqVIW_container {
  width: 100%;
  height: 0;
  transition: height .3s ease-out .1s;
  position: sticky;
  bottom: 0;
  overflow: hidden;
}

._7BqVIW_container ._7BqVIW_actionBar {
  height: 60px;
  border: 1px solid var(--color-line-divider);
  background-color: var(--color-float);
  box-shadow: var(--shadow-3);
  border-radius: 12px 12px 0 0;
  justify-content: flex-end;
  padding: 12px 32px;
  transition: transform .3s ease-out;
  display: flex;
  transform: translateY(100%);
}

._7BqVIW_container ._7BqVIW_actionBar > button + button {
  margin-left: 12px;
}

._7BqVIW_container._7BqVIW_active {
  height: 60px;
  overflow: visible;
}

._7BqVIW_container._7BqVIW_active ._7BqVIW_actionBar {
  transition: transform .3s ease-in;
  transform: translateY(0);
}

@keyframes l9fJqW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes l9fJqW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.l9fJqW_container {
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  padding-bottom: 8px;
  display: flex;
}

.l9fJqW_container.l9fJqW_withSubmitActionBar {
  padding-bottom: 0;
}

.l9fJqW_container .l9fJqW_fields {
  flex-direction: column;
  flex: 1;
  gap: 16px;
  display: flex;
}

@keyframes mTaj3G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes mTaj3G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.mTaj3G_item {
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.mTaj3G_item > svg {
  flex-shrink: 0;
}

.mTaj3G_item > div:not(:first-child) {
  margin-left: 12px;
}

.mTaj3G_item .mTaj3G_content {
  align-items: center;
  margin-top: -4px;
  padding-right: 16px;
  display: flex;
  overflow: hidden;
}

.mTaj3G_item .mTaj3G_content > div:not(:last-child) {
  margin-right: 8px;
}

.mTaj3G_item .mTaj3G_content .mTaj3G_meta {
  overflow: hidden;
}

.mTaj3G_item .mTaj3G_content .mTaj3G_meta .mTaj3G_title {
  font: var(--font-body-2);
  color: var(--color-text-link);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

.mTaj3G_item .mTaj3G_content .mTaj3G_meta .mTaj3G_subtitle {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mTaj3G_item.mTaj3G_compact .mTaj3G_content {
  margin-top: unset;
}

.mTaj3G_item.mTaj3G_compact .mTaj3G_content .mTaj3G_meta {
  align-items: baseline;
  display: flex;
}

.mTaj3G_item.mTaj3G_compact .mTaj3G_content .mTaj3G_meta .mTaj3G_title {
  margin-right: 4px;
}

@keyframes KDjGaq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes KDjGaq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.KDjGaq_container {
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  padding-bottom: 24px;
  display: flex;
}

.KDjGaq_headline {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  display: flex;
}

.KDjGaq_table {
  flex: 1;
  margin-top: 16px;
  overflow: hidden;
}

@keyframes iJckjG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes iJckjG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.iJckjG_actionBar {
  width: 100%;
  background-color: var(--color-layer-1);
  box-shadow: var(--shadow-3);
  z-index: 1;
  padding: 16px 24px;
  inset: auto 0 0;
}

.iJckjG_actionBar .iJckjG_wrapper {
  max-width: 858px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.iJckjG_actionBar .iJckjG_text {
  font: var(--font-body-2);
  color: var(--color-text);
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-right: 12px;
  display: -webkit-box;
  overflow: hidden;
}

@keyframes nzzT_q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nzzT_q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nzzT_q_container {
  width: 100%;
}

.nzzT_q_wrapper {
  width: 100%;
  min-width: 750px;
  max-width: 906px;
  margin: 0 auto;
  padding: 24px;
  position: relative;
}

.nzzT_q_groups {
  max-width: 858px;
  flex-direction: column;
  flex: 1;
  padding-bottom: 32px;
  display: flex;
  position: relative;
}

.nzzT_q_groups > div {
  flex: unset;
}

.nzzT_q_guideGroup {
  flex: 1;
}

.nzzT_q_emptyPlaceholder {
  width: 100%;
  height: 70%;
  justify-content: center;
  position: absolute;
}

@keyframes BBV9gW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes BBV9gW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.BBV9gW_container {
  background-color: var(--color-base);
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  display: flex;
  overflow-x: auto;
}

.BBV9gW_container > * {
  min-width: 750px;
}

.BBV9gW_container .BBV9gW_content {
  flex: 1;
  overflow: hidden;
}

@keyframes _2WfC1G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _2WfC1G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._2WfC1G_icon {
  flex-shrink: 0;
}

._2WfC1G_chargeNotification {
  margin-top: 16px;
}

.Sol9Da_hide {
  display: none;
}

@keyframes m0Dypq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes m0Dypq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.m0Dypq_container {
  display: flex;
}

.m0Dypq_container > * {
  flex: 1;
}

.m0Dypq_container > :not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.m0Dypq_container > .m0Dypq_darkMode {
  background-color: #000;
}

.m0Dypq_container .m0Dypq_multiColumn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.m0Dypq_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 4px;
}

.m0Dypq_error {
  color: var(--color-error);
}

@keyframes eOA9Fa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes eOA9Fa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.eOA9Fa_deleteConfirm > :not(:first-child) {
  margin-top: 24px;
}

.eOA9Fa_deleteConfirm .eOA9Fa_description {
  font: var(--font-body-2);
}

.eOA9Fa_deleteConfirm .eOA9Fa_highlight {
  color: var(--color-primary-50);
}

.eOA9Fa_customEndpointNotes {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 24px;
}

.eOA9Fa_fieldButton {
  margin-top: 8px;
}

.eOA9Fa_trailingIcon {
  width: 16px;
  height: 16px;
}

.eOA9Fa_tabContainer {
  flex-direction: column;
  flex-grow: 1;
}

.eOA9Fa_tabContainer[data-active="true"] {
  display: flex;
}

@keyframes awo3XW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes awo3XW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.awo3XW_drawerContainer {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.awo3XW_header {
  background-color: var(--color-layer-1);
  font: var(--font-title-2);
  color: var(--color-text);
  box-shadow: var(--shadow-1);
  z-index: 1;
  flex: 0 0 64px;
  align-items: center;
  padding: 0 24px;
  display: flex;
}

.awo3XW_header .awo3XW_separator {
  border-left: 1px solid var(--color-border);
  height: 20px;
  width: 0;
  margin: 0 20px;
}

.awo3XW_cardGroup {
  flex: 1;
  padding: 24px;
}

.awo3XW_guide {
  flex: 1;
  padding: 24px;
  overflow: hidden;
}

.QCDwsq_link {
  color: var(--color-text-link);
  text-decoration: none;
}

@keyframes _3-KeZq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3-KeZq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3-KeZq_userName {
  font: var(--body-medium);
  color: var(--color-text);
}

._3-KeZq_userName ._3-KeZq_title {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  display: inline-flex;
  overflow: hidden;
}

._3-KeZq_userName ._3-KeZq_title span {
  margin-left: 8px;
}

._3-KeZq_userName ._3-KeZq_link {
  color: var(--color-text-link);
  text-decoration: none;
  display: inline-flex;
}

@keyframes el1x2W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes el1x2W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.el1x2W_select {
  background: var(--color-layer-1);
  border: 1px solid var(--color-border);
  font: var(--font-body-2);
  cursor: pointer;
  border-radius: 8px;
  outline: 3px solid #0000;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 12px;
  transition-property: outline, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: relative;
}

.el1x2W_select.el1x2W_multiple {
  cursor: text;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
  padding: 6px 12px;
}

.el1x2W_select.el1x2W_multiple .el1x2W_tag {
  cursor: auto;
  align-items: center;
  gap: 4px;
  display: flex;
  position: relative;
}

.el1x2W_select.el1x2W_multiple .el1x2W_tag.el1x2W_focused:after {
  content: "";
  background: var(--color-overlay-default-focused);
  position: absolute;
  inset: 0;
}

.el1x2W_select.el1x2W_multiple .el1x2W_close {
  width: 16px;
  height: 16px;
}

.el1x2W_select.el1x2W_multiple .el1x2W_delete {
  width: 20px;
  height: 20px;
  margin-right: -2px;
}

.el1x2W_select.el1x2W_multiple input {
  color: var(--color-text);
  font: var(--font-body-2);
  background: none;
  flex-grow: 1;
  padding: 2px;
}

.el1x2W_select.el1x2W_multiple input::placeholder {
  color: var(--color-placeholder);
}

.el1x2W_select .el1x2W_title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.el1x2W_select.el1x2W_open {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.el1x2W_select.el1x2W_readOnly {
  background: var(--color-inverse-on-surface);
  color: var(--color-text);
  border-color: var(--color-border);
  cursor: default;
}

.el1x2W_select.el1x2W_error {
  border-color: var(--color-error);
}

.el1x2W_select.el1x2W_error.el1x2W_open {
  outline-color: var(--color-danger-focused);
}

.el1x2W_select .el1x2W_icon {
  color: var(--color-text-secondary);
  margin-left: 4px;
  display: flex;
}

.el1x2W_select .el1x2W_clear {
  display: none;
}

.el1x2W_select .el1x2W_arrow svg {
  width: 20px;
  height: 20px;
}

.el1x2W_select.el1x2W_clearable:hover .el1x2W_clear {
  display: block;
}

.el1x2W_select.el1x2W_clearable:hover .el1x2W_arrow {
  display: none;
}

.el1x2W_select.el1x2W_small {
  height: 30px;
}

.el1x2W_select.el1x2W_medium {
  height: 32px;
}

.el1x2W_select.el1x2W_large {
  height: 36px;
}

.el1x2W_dropdown {
  max-height: 288px;
  padding: 4px;
}

.el1x2W_spinner {
  color: var(--color-placeholder);
  margin: 8px;
}

.el1x2W_noResult {
  color: var(--color-placeholder);
  font: var(--font-body-2);
  padding: 8px;
}

.el1x2W_searchInputContainer {
  align-items: center;
  display: flex;
}

.el1x2W_searchInputContainer .el1x2W_search {
  color: var(--color-text-secondary);
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.el1x2W_searchInputContainer .el1x2W_searchInput {
  height: 100%;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  color: var(--color-text);
  font: var(--font-body-2);
  background: none;
  padding: 0;
}

.el1x2W_searchInputContainer .el1x2W_searchInput::placeholder {
  color: var(--color-placeholder);
}

@keyframes ji4gBq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ji4gBq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ji4gBq_eventName {
  white-space: nowrap;
  align-items: center;
  display: flex;
}

.ji4gBq_eventName .ji4gBq_icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 4px;
}

.ji4gBq_eventName .ji4gBq_icon.ji4gBq_success {
  color: var(--color-success-60);
}

.ji4gBq_eventName .ji4gBq_icon.ji4gBq_fail {
  color: var(--color-error-40);
}

.ji4gBq_eventName .ji4gBq_title {
  font: var(--font-body-2);
  color: var(--color-text-link);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  overflow: hidden;
}

@keyframes _l4QRW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _l4QRW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._l4QRW_filter {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

._l4QRW_filter ._l4QRW_title {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
}

._l4QRW_filter ._l4QRW_eventSelector {
  width: 300px;
  margin-left: 8px;
}

._l4QRW_filter ._l4QRW_applicationSelector {
  width: 250px;
  margin-left: 8px;
}

@keyframes Zm2fMa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Zm2fMa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Zm2fMa_logs {
  flex: 1;
  margin-bottom: 24px;
  overflow-y: auto;
}

@keyframes G_cUGW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes G_cUGW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.G_cUGW_container {
  border: 1px solid var(--color-border);
  border-radius: 6px;
  align-items: stretch;
  display: flex;
  overflow: hidden;
}

.G_cUGW_box {
  flex-direction: column;
  flex: 1 1 0;
  display: flex;
  overflow: hidden;
}

.G_cUGW_boxTopBar {
  height: 52px;
  border-bottom: 1px solid var(--color-border);
  align-items: center;
  padding: 0 16px;
  display: flex;
}

.G_cUGW_boxContent {
  flex: 1 1 0;
  overflow-y: auto;
}

.G_cUGW_emptyBoxContent {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.G_cUGW_boxPagination {
  height: 40px;
  border-top: 1px solid var(--color-border);
  padding-right: 16px;
}

.G_cUGW_verticalBar {
  border-left: 1px solid var(--color-border);
  width: 0;
}

@keyframes nsdVTW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nsdVTW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nsdVTW_checkbox {
  align-items: center;
  display: flex;
  position: relative;
}

.nsdVTW_checkbox .nsdVTW_icon {
  width: 20px;
  height: 20px;
}

.nsdVTW_checkbox .nsdVTW_icon .nsdVTW_border {
  fill: var(--color-text-secondary);
}

.nsdVTW_checkbox .nsdVTW_icon .nsdVTW_background {
  fill: var(--color-layer-1);
}

.nsdVTW_checkbox .nsdVTW_icon.nsdVTW_checked .nsdVTW_background {
  fill: var(--color-primary);
}

.nsdVTW_checkbox .nsdVTW_icon.nsdVTW_disabled .nsdVTW_background {
  fill: var(--color-checkbox-disabled-background);
}

.nsdVTW_checkbox .nsdVTW_icon.nsdVTW_disabled .nsdVTW_border {
  fill: var(--color-border);
}

.nsdVTW_checkbox .nsdVTW_icon.nsdVTW_checked.nsdVTW_disabled .nsdVTW_background {
  fill: var(--color-checkbox-checked-disabled-background);
}

.nsdVTW_checkbox .nsdVTW_wrapper {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.nsdVTW_checkbox .nsdVTW_tooltipAnchor {
  align-items: center;
  margin-right: 8px;
  display: flex;
}

.nsdVTW_checkbox .nsdVTW_label {
  font: var(--font-body-2);
  color: var(--color-text);
  white-space: nowrap;
  cursor: inherit;
  align-items: center;
  gap: 4px;
  display: flex;
}

.nsdVTW_checkbox.nsdVTW_disabled .nsdVTW_wrapper {
  cursor: not-allowed;
}

@keyframes BNvetG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes BNvetG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.BNvetG_group {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

@keyframes _4xx24W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _4xx24W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._4xx24W_item {
  font: var(--font-body-2);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  align-items: center;
  padding: 10px 16px;
  display: flex;
}

._4xx24W_item:hover {
  background-color: var(--color-hover);
}

._4xx24W_item ._4xx24W_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

._4xx24W_item ._4xx24W_count {
  color: var(--color-text-secondary);
  flex-shrink: 0;
  margin-left: 8px;
}

.-Tm1cW_search {
  width: 100%;
}

.-Tm1cW_icon {
  color: var(--color-text-secondary);
}

.-Tm1cW_emptyImage {
  width: 128px;
  height: 128px;
}

@keyframes poi3lG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes poi3lG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.poi3lG_item {
  font: var(--font-body-2);
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  padding: 8px 12px 8px 16px;
  display: flex;
}

.poi3lG_item:hover {
  background-color: var(--color-hover);
}

.poi3lG_item .poi3lG_info {
  flex: 1 1 0;
  align-items: center;
  margin-right: 8px;
  display: flex;
  overflow: hidden;
}

.poi3lG_item .poi3lG_info .poi3lG_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.poi3lG_item .poi3lG_info .poi3lG_count {
  color: var(--color-text-secondary);
  flex-shrink: 0;
  margin-left: 8px;
}

.muW9bW_icon {
  color: var(--color-text-secondary);
}

.muW9bW_added {
  font: var(--font-label-2);
}

._1g5OiG_rolesTransfer {
  height: 360px;
}

@keyframes MrHGWq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes MrHGWq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.MrHGWq_rolesTable {
  color: var(--color-text);
  flex: 1;
  margin-bottom: 24px;
}

.MrHGWq_rolesTable .MrHGWq_filter {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.MrHGWq_rolesTable .MrHGWq_description {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.MrHGWq_icon {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.v6CNCW_icon {
  color: var(--color-text-secondary);
}

.wAHLyq_breakable {
  word-break: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

@keyframes yG5A-W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes yG5A-W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.yG5A-W_section {
  flex-direction: column;
  align-items: baseline;
  gap: 12px;
  display: flex;
}

.yG5A-W_addAnother {
  margin-top: 12px;
}

.yG5A-W_empty {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.yG5A-W_title {
  font: var(--font-label-2);
}

.yG5A-W_spinner {
  margin: 8px 0;
}

@keyframes tgjteG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes tgjteG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.tgjteG_dataItem {
  align-items: center;
  padding: 6px 16px;
  display: flex;
}

.tgjteG_dataItem .tgjteG_name {
  font: var(--font-body-2);
  background: var(--color-neutral-95);
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  border-radius: 6px;
  padding: 4px 8px;
  overflow: hidden;
}

.tgjteG_dataItem .tgjteG_icon {
  color: var(--color-text-secondary);
}

@keyframes lfCsgq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes lfCsgq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.lfCsgq_groupItem {
  -webkit-user-select: none;
  user-select: none;
}

.lfCsgq_groupItem .lfCsgq_title {
  align-items: center;
  padding: 6px 16px;
  display: flex;
}

.lfCsgq_groupItem .lfCsgq_title .lfCsgq_group {
  cursor: pointer;
  flex: 1;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.lfCsgq_groupItem .lfCsgq_title .lfCsgq_group .lfCsgq_caret {
  margin-right: 8px;
}

.lfCsgq_groupItem .lfCsgq_title .lfCsgq_group .lfCsgq_name {
  font: var(--font-label-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.lfCsgq_groupItem .lfCsgq_title .lfCsgq_group .lfCsgq_dataInfo {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  flex-shrink: 0;
  margin-left: 8px;
}

.lfCsgq_groupItem .lfCsgq_invisible {
  display: none;
}

.lfCsgq_dataList {
  padding-left: 40px;
}

@keyframes bmBJea_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes bmBJea_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.bmBJea_search {
  width: 100%;
}

.bmBJea_icon {
  color: var(--color-text-secondary);
}

@keyframes qz7dNW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes qz7dNW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.qz7dNW_targetDataItem {
  align-items: center;
  padding: 6px 12px 6px 16px;
  display: flex;
}

.qz7dNW_targetDataItem .qz7dNW_title {
  font: var(--font-body-2);
  flex: 1 1 0;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.qz7dNW_targetDataItem .qz7dNW_title .qz7dNW_name {
  max-width: 204px;
  background: var(--color-neutral-95);
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 6px;
  flex-shrink: 0;
  padding: 4px 8px;
  overflow: hidden;
}

.qz7dNW_targetDataItem .qz7dNW_title .qz7dNW_groupName {
  color: var(--color-text-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 8px;
  overflow: hidden;
}

.qz7dNW_targetDataItem:hover {
  background: var(--color-hover);
}

.nPWvea_added {
  font: var(--font-label-2);
}

.EUu5oq_dataTransferBox {
  height: 360px;
}

@keyframes yN8Ufq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes yN8Ufq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.yN8Ufq_permissionsModal [class*="tableContainer"] [class*="bodyTable"] tr.yN8Ufq_sectionTitleRow {
  height: 36px;
}

.yN8Ufq_permissionsModal [class*="tableContainer"] [class*="bodyTable"] tr.yN8Ufq_sectionTitleRow td {
  font: var(--font-label-2);
  color: var(--color-text-secondary);
  background-color: var(--color-layer-light);
  padding-top: 8px;
  padding-bottom: 8px;
}

.yN8Ufq_label {
  align-items: center;
  gap: 4px;
  display: flex;
}

@keyframes NxrG3q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes NxrG3q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.NxrG3q_addDomain {
  width: 100%;
  display: flex;
}

.NxrG3q_addDomain .NxrG3q_textInput {
  flex: 1;
  margin-right: 12px;
}

.NxrG3q_addDomain .NxrG3q_addButton {
  flex-shrink: 0;
}

@keyframes zsr46a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zsr46a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.zsr46a_tip {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-bottom: 8px;
}

.zsr46a_container {
  background-color: var(--color-layer-light);
  border-radius: 12px;
}

.zsr46a_loading {
  font: var(--font-body-2);
  align-items: center;
  gap: 12px;
  padding: 20px;
  display: flex;
}

.zsr46a_loading .zsr46a_loadingIcon {
  color: var(--color-primary);
}

.zsr46a_table {
  padding: 8px 0;
}

.zsr46a_table .zsr46a_header {
  background-color: unset;
}

.zsr46a_table .zsr46a_bodyTableWrapper {
  background-color: unset;
  padding-bottom: unset;
}

.zsr46a_table .zsr46a_bodyTableWrapper table > tbody > tr > td {
  border: unset;
}

.zsr46a_column {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

@keyframes v0c2IW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes v0c2IW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.v0c2IW_step .v0c2IW_header {
  align-items: center;
  display: flex;
}

.v0c2IW_step .v0c2IW_header .v0c2IW_status {
  flex-shrink: 0;
}

.v0c2IW_step .v0c2IW_header .v0c2IW_title {
  font: var(--font-label-2);
  margin-left: 20px;
}

.v0c2IW_step .v0c2IW_header .v0c2IW_tip {
  margin-left: 2px;
}

.v0c2IW_step .v0c2IW_contentContainer {
  padding: 8px 0 24px 40px;
  position: relative;
}

.v0c2IW_step:not(:last-child) .v0c2IW_contentContainer:before {
  content: "";
  border-left: 1px dashed var(--color-divider);
  display: block;
  position: absolute;
  top: 4px;
  bottom: 4px;
  transform: translateX(-30px);
}

.v0c2IW_stepIcon {
  width: 20px;
  height: 20px;
  background-color: var(--color-surface-variant);
  color: var(--color-text-link);
  font: var(--font-label-3);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.v0c2IW_stepIcon .v0c2IW_icon {
  width: 12px;
  height: 12px;
  color: var(--color-white);
  flex-shrink: 0;
}

.v0c2IW_stepIcon.v0c2IW_finished {
  background-color: var(--color-on-success-container);
}

.v0c2IW_stepIcon.v0c2IW_loading {
  background-color: unset;
}

@keyframes o9ke_a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes o9ke_a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.o9ke_a_container {
  border-top: 1px solid var(--color-divider);
  padding: 20px 24px 0;
}

.o9ke_a_errorNotification {
  margin-bottom: 24px;
}

.o9ke_a_errorNotification .o9ke_a_hint {
  color: var(--color-text-secondary);
  margin-top: 4px;
}

@keyframes _7tMuma_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _7tMuma_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._7tMuma_header {
  align-items: center;
  gap: 24px;
  padding: 20px 24px;
  display: flex;
}

._7tMuma_header ._7tMuma_domain {
  font: var(--font-title-2);
}

._7tMuma_header ._7tMuma_icon {
  width: 16px;
  height: 16px;
  color: var(--color-text-secondary);
}

._7tMuma_strong {
  font: var(--font-title-3);
}

._7tMuma_inUsedDeletionTip {
  margin-top: 20px;
}

@keyframes wMuGfW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes wMuGfW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.wMuGfW_container {
  border: 1px solid var(--color-divider);
  border-radius: 12px;
}

@keyframes U6WLiq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes U6WLiq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.U6WLiq_container {
  position: absolute;
  top: 1px;
  bottom: 1px;
  right: 1px;
}

.U6WLiq_container .U6WLiq_button {
  -webkit-user-select: none;
  user-select: none;
  width: 32px;
  height: 16px;
  margin: 0;
  padding: 0 8px;
}

.U6WLiq_container .U6WLiq_button.U6WLiq_disabled > svg {
  color: var(--color-disabled);
}

.U6WLiq_container .U6WLiq_button:not(.U6WLiq_disabled):hover {
  cursor: pointer;
  background: var(--color-hover);
}

.U6WLiq_container .U6WLiq_button:not(.U6WLiq_disabled):active {
  background: var(--color-pressed);
}

.U6WLiq_container .U6WLiq_button.U6WLiq_up {
  border-radius: 6px 6px 2px 2px;
}

.U6WLiq_container .U6WLiq_button.U6WLiq_down {
  border-radius: 2px 2px 6px 6px;
}

._9-F7yW_sessionDuration {
  width: 135px;
}

._9-F7yW_sessionDuration input {
  width: 86px;
  flex: unset;
}

@keyframes _9JJ_Hq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _9JJ_Hq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._9JJ_Hq_launcher {
  background: var(--color-success-container);
  border: 1px solid var(--color-success-60);
  font: var(--font-body-2);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 12px 16px;
  display: flex;
}

._9JJ_Hq_launcher span {
  white-space: pre-wrap;
}

._9JJ_Hq_launcher ._9JJ_Hq_button {
  background: var(--color-success-60);
  color: var(--color-static-white);
  border: none;
  flex-shrink: 0;
  gap: 6px;
}

._9JJ_Hq_launcher ._9JJ_Hq_button svg {
  color: #fff9;
}

._9JJ_Hq_launcher ._9JJ_Hq_button:active {
  background: var(--color-success-50);
}

._9JJ_Hq_launcher ._9JJ_Hq_button:not(:disabled):not(:active):hover {
  background: var(--color-success-70);
}

._9JJ_Hq_customDomain {
  margin-bottom: 12px;
}

._9JJ_Hq_label {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-bottom: 4px;
}

._9JJ_Hq_hostInUse {
  border: 1px solid var(--color-divider);
  background: var(--color-layer-1);
  border-radius: 8px;
  align-items: center;
  gap: 24px;
  padding: 20px 24px;
  display: flex;
}

._9JJ_Hq_hostInUse ._9JJ_Hq_host {
  font: var(--font-label-1);
  white-space: nowrap;
}

._9JJ_Hq_hostInUse ._9JJ_Hq_status {
  font: var(--font-body-2);
  white-space: nowrap;
  align-items: center;
  gap: 8px;
  display: flex;
}

._9JJ_Hq_hostInUse ._9JJ_Hq_status ._9JJ_Hq_dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

._9JJ_Hq_hostInUse ._9JJ_Hq_status ._9JJ_Hq_dot._9JJ_Hq_success {
  background: var(--color-on-success-container);
}

._9JJ_Hq_hostInUse ._9JJ_Hq_status ._9JJ_Hq_dot._9JJ_Hq_connecting {
  background: var(--color-on-alert-container);
}

._9JJ_Hq_inlineCode {
  font: var(--font-body-2);
  background-color: var(--color-bg-layer-2);
  color: var(--color-text);
  border-radius: 6px;
  padding: 2px 6px;
  font-family: Roboto Mono, monospace;
}

._9JJ_Hq_routes {
  grid-template-columns: repeat(2, 1fr);
  gap: 12px 24px;
  display: grid;
}

._9JJ_Hq_tip ol {
  margin: 0;
  padding-inline-start: 20px;
}

._9JJ_Hq_loadingSkeleton {
  width: 100%;
  border: 1px solid var(--color-divider);
  border-radius: 12px;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  display: flex;
}

._9JJ_Hq_loadingSkeleton ._9JJ_Hq_bone {
  background-color: var(--color-layer-2);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

._9JJ_Hq_loadingSkeleton ._9JJ_Hq_bone:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite _9JJ_Hq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

._9JJ_Hq_loadingSkeleton ._9JJ_Hq_title {
  width: 100%;
  height: 20px;
}

._9JJ_Hq_loadingSkeleton ._9JJ_Hq_description {
  width: 100%;
  height: 16px;
}

@keyframes _3uqoIW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3uqoIW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3uqoIW_modalContainer {
  width: 100vw;
  height: 100vh;
  background-color: var(--color-base);
  flex-direction: column;
  display: flex;
  overflow-x: auto;
}

._3uqoIW_modalContainer > * {
  min-width: 750px;
}

._3uqoIW_guide {
  flex: 1;
}

@keyframes q8OBxa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes q8OBxa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.q8OBxa_container {
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.q8OBxa_container .q8OBxa_title {
  font: var(--font-label-2);
  color: var(--color-text);
  margin-top: 10px;
}

.q8OBxa_container .q8OBxa_subtitle, .q8OBxa_container .q8OBxa_description {
  font: var(--font-body-2);
  flex: 2;
  margin-top: 12px;
}

.q8OBxa_container .q8OBxa_subtitle {
  color: var(--color-text);
}

.q8OBxa_container .q8OBxa_description {
  color: var(--color-text-secondary);
}

.q8OBxa_container.q8OBxa_small .q8OBxa_subtitle, .q8OBxa_container.q8OBxa_small .q8OBxa_description {
  font: var(--font-body-3);
  flex: 2;
  margin-top: 4px;
}

@keyframes lMkVDq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes lMkVDq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.lMkVDq_radioGroup {
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin-top: 8px;
  display: grid;
}

@media screen and (max-width: 668px) {
  .lMkVDq_radioGroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .lMkVDq_radioGroup {
    grid-template-columns: repeat(1, 1fr);
  }
}

.lMkVDq_error {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 8px;
}

@keyframes LxmOYW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes LxmOYW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

form {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.LxmOYW_formFieldWrapper {
  gap: 36px;
  display: flex;
}

.LxmOYW_formFieldWrapper .LxmOYW_index {
  width: 24px;
  height: 24px;
  background: var(--color-function-n-overlay-primary-focused);
  color: var(--color-text-link);
  font: var(--font-title-3);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.LxmOYW_formFieldWrapper .LxmOYW_withDashedLine {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.LxmOYW_formFieldWrapper .LxmOYW_withDashedLine .LxmOYW_dashedLine {
  border-left: 1px dashed var(--color-border);
  flex: 1;
  margin: 6px 0;
}

.LxmOYW_formFieldWrapper .LxmOYW_field {
  flex: 1;
  margin: 0;
  padding-bottom: 24px;
}

.LxmOYW_input {
  background: var(--color-layer-1);
}

.LxmOYW_domainFieldWrapper {
  width: 100%;
  display: flex;
}

.LxmOYW_domainFieldWrapper .LxmOYW_subdomain {
  flex: 1;
}

.LxmOYW_domainFieldWrapper .LxmOYW_subdomain > div {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.LxmOYW_domainFieldWrapper .LxmOYW_domain {
  border: 1px solid var(--color-border);
  height: 36px;
  font: var(--font-body-2);
  color: var(--color-text);
  background-color: var(--color-specific-unselected-disabled);
  border-left: none;
  border-radius: 0 6px 6px 0;
  align-items: center;
  padding: 0 16px;
  display: flex;
}

.LxmOYW_submitButton {
  align-self: flex-end;
}

.LxmOYW_submitButton.LxmOYW_leftAligned {
  align-self: flex-start;
  margin-left: 60px;
}

@keyframes E3m9jq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes E3m9jq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.E3m9jq_container {
  width: 100%;
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.E3m9jq_container label {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
}

.E3m9jq_container .E3m9jq_card {
  background-color: var(--color-layer-1);
  border-radius: 12px;
  align-items: center;
  gap: 24px;
  padding: 24px 32px;
  display: flex;
}

.E3m9jq_container .E3m9jq_card.E3m9jq_hasBorder {
  border: 1px solid var(--color-divider);
}

.E3m9jq_container .E3m9jq_card .E3m9jq_logo {
  width: 48px;
  height: 48px;
}

.E3m9jq_container .E3m9jq_card .E3m9jq_columnWrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.E3m9jq_container .E3m9jq_card .E3m9jq_columnWrapper .E3m9jq_titleRowWrapper {
  align-items: center;
  gap: 12px;
  display: flex;
}

.E3m9jq_container .E3m9jq_card .E3m9jq_columnWrapper .E3m9jq_title {
  font: var(--font-title-2);
  color: var(--color-text);
}

.E3m9jq_container .E3m9jq_card .E3m9jq_columnWrapper .E3m9jq_label {
  font: var(--font-label-2);
  color: var(--color-text);
}

.E3m9jq_container .E3m9jq_card .E3m9jq_columnWrapper .E3m9jq_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

@keyframes aBenmW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes aBenmW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.aBenmW_container {
  width: 100%;
}

.aBenmW_wrapper {
  width: 100%;
  min-height: 100%;
  min-width: 750px;
  max-width: 1406px;
  margin: 0 auto;
  position: relative;
}

.aBenmW_wrapper.aBenmW_hasFilters {
  padding: 24px 274px;
}

.aBenmW_filterAnchor {
  position: absolute;
  inset: 0 auto 0 0;
  transform: translateX(-100%);
}

.aBenmW_filters {
  width: 220px;
  flex-direction: column;
  gap: 16px;
  margin-right: 30px;
  display: flex;
  position: sticky;
  top: 24px;
}

.aBenmW_filters label {
  font: var(--font-label-2);
  color: var(--color-text);
}

.aBenmW_filters .aBenmW_searchInput svg {
  color: var(--color-text-secondary);
}

.aBenmW_filters .aBenmW_checkboxGroupContainer {
  position: relative;
}

.aBenmW_filters .aBenmW_checkboxGroup {
  gap: 16px;
}

.aBenmW_filters .aBenmW_proTag {
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: -2px;
}

.aBenmW_groups {
  flex-direction: column;
  flex: 1;
  display: flex;
  position: relative;
}

.aBenmW_groups > div {
  flex: unset;
}

.aBenmW_protectedAppCard {
  margin-bottom: 16px;
}

.aBenmW_wrapper.aBenmW_hasFilters .aBenmW_groups {
  max-width: 858px;
}

.aBenmW_guideGroup {
  flex: 1;
}

.aBenmW_guideGroup + .aBenmW_guideGroup {
  margin-top: 32px;
}

.aBenmW_emptyPlaceholder {
  width: 100%;
  height: calc(100vh - 188px);
  display: flex;
}

.aBenmW_viewAll {
  margin-top: 32px;
}

@media screen and (max-width: 1406px) {
  .aBenmW_wrapper.aBenmW_hasFilters {
    max-width: 1156px;
    margin-left: 0;
    padding-right: 24px;
  }
}

@keyframes oksFFW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes oksFFW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.oksFFW_container {
  background-color: var(--color-base);
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  display: flex;
  overflow-x: auto;
}

.oksFFW_container > * {
  min-width: 750px;
}

.oksFFW_container .oksFFW_content {
  flex: 1;
  overflow: hidden;
}

@media screen and (max-width: 1406px) {
  .oksFFW_container .oksFFW_footerInnerWrapper {
    margin: 0 0 0 250px;
  }
}

@keyframes _7_IiMW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _7_IiMW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._7_IiMW_chargeNotification {
  margin: 16px 0 0;
}

._7_IiMW_icon {
  flex-shrink: 0;
}

._7_IiMW_applicationName {
  width: 360px;
}

._7_IiMW_tabs {
  margin-top: 16px;
}

._7_IiMW_guideLibraryContainer {
  background: var(--color-layer-1);
  border-radius: 12px;
  flex: 1;
  margin-top: 16px;
  padding: 24px 0;
}

._7_IiMW_guideLibraryContainer ._7_IiMW_title {
  align-items: center;
  margin-bottom: 24px;
}

._7_IiMW_guideLibraryContainer ._7_IiMW_library {
  padding: 0 24px;
}

._1f0U4G_success {
  color: var(--color-success-60);
}

._1f0U4G_fail {
  color: var(--color-error-40);
}

._1f0U4G_icon {
  width: 60px;
  height: 60px;
}

._1f0U4G_label {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  text-align: center;
}

@keyframes tI75Cq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes tI75Cq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.tI75Cq_header {
  padding: 24px;
  display: flex;
}

.tI75Cq_header > :not(:first-child) {
  margin-left: 24px;
}

.tI75Cq_header .tI75Cq_content {
  flex: 1;
}

.tI75Cq_header .tI75Cq_content .tI75Cq_eventName {
  color: var(--color-text);
  font: var(--font-title-1);
}

.tI75Cq_header .tI75Cq_content .tI75Cq_basicInfo {
  flex-wrap: wrap;
  align-items: center;
  padding-top: 16px;
  display: flex;
}

.tI75Cq_header .tI75Cq_content .tI75Cq_basicInfo > * {
  width: 270px;
  margin: 0 16px 16px 0;
}

.tI75Cq_header .tI75Cq_content .tI75Cq_infoItem {
  word-break: break-word;
  color: var(--color-text);
  font: var(--font-body-2);
}

.tI75Cq_header .tI75Cq_content .tI75Cq_infoItem .tI75Cq_label {
  color: var(--color-text-secondary);
  font: var(--font-label-2);
  margin-bottom: 8px;
}

.tI75Cq_body {
  flex-direction: column;
  flex: 1;
  margin-bottom: 24px;
  padding-bottom: 0;
  display: flex;
}

.tI75Cq_body > :not(:first-child) {
  margin-top: 16px;
}

.tI75Cq_body .tI75Cq_main {
  padding-bottom: 16px;
}

@keyframes BnCFfW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes BnCFfW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.BnCFfW_connector {
  font: var(--font-body-2);
  display: flex;
}

.BnCFfW_connector .BnCFfW_content {
  flex: 1;
  margin-left: 12px;
}

.BnCFfW_connector .BnCFfW_content .BnCFfW_name {
  font: var(--font-label-2);
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  padding-right: 12px;
  display: -webkit-box;
  overflow: hidden;
}

.BnCFfW_connector .BnCFfW_content .BnCFfW_connectorId {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
  margin-top: 4px;
}

.BnCFfW_connector .BnCFfW_content .BnCFfW_description {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-top: 4px;
  display: -webkit-box;
  overflow: hidden;
}

@keyframes ZBQxdG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ZBQxdG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ZBQxdG_connectorGroup {
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  display: grid;
}

@media screen and (max-width: 850px) {
  .ZBQxdG_connectorGroup {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 668px) {
  .ZBQxdG_connectorGroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .ZBQxdG_connectorGroup {
    grid-template-columns: repeat(1, 1fr);
  }
}

.ZBQxdG_connectorGroup.ZBQxdG_medium {
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 500px) {
  .ZBQxdG_connectorGroup.ZBQxdG_medium {
    grid-template-columns: repeat(1, 1fr);
  }
}

.ZBQxdG_connectorGroup.ZBQxdG_large {
  grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 668px) {
  .ZBQxdG_connectorGroup.ZBQxdG_large {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .ZBQxdG_connectorGroup.ZBQxdG_large {
    grid-template-columns: repeat(1, 1fr);
  }
}

@keyframes NQKgPa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes NQKgPa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.NQKgPa_platforms {
  background: var(--color-bg-layer-2);
  border-radius: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  margin-top: 16px;
  padding: 16px 24px;
  display: flex;
}

.NQKgPa_platforms .NQKgPa_title {
  font: var(--font-label-2);
}

@keyframes ZBI9IG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ZBI9IG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ZBI9IG_connector {
  border: 1px solid var(--color-neutral-90);
  border-radius: 16px;
  padding: 12px;
}

.ZBI9IG_logo {
  background-color: var(--color-layer-2);
  width: 40px;
  height: 40px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.ZBI9IG_logo:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ZBI9IG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ZBI9IG_name {
  background-color: var(--color-layer-2);
  width: 50px;
  height: 16px;
  border-radius: 8px;
  margin-bottom: 4px;
  position: relative;
  overflow: hidden;
}

.ZBI9IG_name:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ZBI9IG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ZBI9IG_description {
  background-color: var(--color-layer-2);
  height: 14px;
  border-radius: 8px;
  margin-bottom: 2px;
  position: relative;
  overflow: hidden;
}

.ZBI9IG_description:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite ZBI9IG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.ZBI9IG_description.ZBI9IG_shortDescription {
  width: 50%;
}

@keyframes _21Rf0a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _21Rf0a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._21Rf0a_standardLabel {
  font: var(--font-label-2);
  gap: 8px;
  margin: 24px 0 16px;
  display: flex;
}

@keyframes dvBGRa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes dvBGRa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.dvBGRa_markdown li {
  font: var(--font-body-2);
}

.dvBGRa_markdown li ul, .dvBGRa_markdown li ol {
  padding-inline-start: 1ch;
}

.dvBGRa_markdown ul {
  padding-inline-start: 4ch;
}

.dvBGRa_markdown ul > li {
  margin-block: 8px;
  padding-inline-start: 4px;
}

.dvBGRa_markdown ol {
  padding-inline-start: 2ch;
}

.dvBGRa_markdown ol > li {
  margin-block: 12px;
  padding-inline-start: 4px;
}

.dvBGRa_markdown a {
  font: var(--font-body-2);
  color: var(--color-text-link);
  text-underline-offset: 2px;
  text-decoration: none;
}

.dvBGRa_markdown a:hover {
  text-decoration: underline;
}

.dvBGRa_markdown a:active {
  color: var(--color-primary-pressed);
}

.dvBGRa_markdown h1 {
  font: var(--font-title-1);
  margin: 24px 0;
}

.dvBGRa_markdown h2 {
  font: var(--font-title-2);
  color: var(--color-text-secondary);
  margin: 24px 0 12px;
}

.dvBGRa_markdown h3 {
  font: var(--font-title-3);
  margin: 24px 0 12px;
}

.dvBGRa_markdown p {
  font: var(--font-body-2);
  margin: 12px 0;
}

.dvBGRa_markdown table {
  border: 1px solid var(--color-divider);
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
  word-break: break-word;
}

.dvBGRa_markdown table thead th {
  font: var(--font-subhead-2);
  color: var(--color-text);
  border-bottom: 1px solid var(--color-divider);
  text-align: left;
  padding: 12px;
}

.dvBGRa_markdown table tbody td {
  font: var(--font-body-2);
  border-bottom: 1px solid var(--color-divider);
  padding: 12px;
}

.dvBGRa_markdown table + table {
  margin-top: 12px;
}

.dvBGRa_inlineCode {
  background: var(--color-layer-2);
  font: var(--font-body-2);
  border-radius: 4px;
  padding: 4px;
}

@keyframes MeDZeq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes MeDZeq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.MeDZeq_tip {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: 2px;
}

.MeDZeq_error {
  color: var(--color-text);
  font: var(--font-body-2);
  background-color: var(--color-danger-toast-background);
  border-radius: 8px;
  align-items: center;
  margin-top: 2px;
  padding: 12px 16px;
  display: flex;
}

.MeDZeq_error .MeDZeq_icon {
  margin-right: 12px;
}

.MeDZeq_error .MeDZeq_content span {
  font-weight: bold;
}

.MeDZeq_error .MeDZeq_content > ul {
  padding-left: 12px;
}

.MeDZeq_fieldButton {
  margin-top: 8px;
}

.MeDZeq_trailingIcon {
  width: 16px;
  height: 16px;
}

@keyframes pHWkkq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes pHWkkq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.pHWkkq_container {
  border: 1px solid var(--color-border);
  border-radius: 6px;
  outline: 3px solid #0000;
  padding: 8px 12px;
}

.pHWkkq_container:focus-within {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.pHWkkq_container.pHWkkq_error {
  border-color: var(--color-error);
}

.pHWkkq_container.pHWkkq_error:focus-within {
  outline-color: var(--color-danger-focused);
}

.pHWkkq_container textarea {
  width: 100%;
  height: 100%;
  color: var(--color-text);
  font: var(--font-body-2);
  resize: none;
  box-sizing: content-box;
  background: none;
  border: none;
  outline: none;
  padding: 0 10px 0 0;
}

.pHWkkq_container textarea::placeholder {
  color: var(--color-placeholder);
}

@keyframes FmIcgq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes FmIcgq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.FmIcgq_description {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: 2px;
}

@keyframes ne1fZq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ne1fZq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ne1fZq_copyToClipboard {
  display: block;
}

.ne1fZq_description {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: 2px;
}

@keyframes MRFoga_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes MRFoga_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.MRFoga_fields {
  align-items: flex-end;
  margin-bottom: 4px;
  display: flex;
}

.MRFoga_fields .MRFoga_textField {
  width: 100%;
}

.MRFoga_fields .MRFoga_send {
  margin-bottom: 1px;
  margin-left: 6px;
}

.MRFoga_error {
  font: var(--font-body-2);
  color: var(--color-error);
}

.MRFoga_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

@keyframes FnKWBW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes FnKWBW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.FnKWBW_description {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: 2px;
}

@keyframes O1NQ5W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes O1NQ5W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.O1NQ5W_tabs {
  align-items: center;
  display: flex;
}

.O1NQ5W_tabs .O1NQ5W_tab {
  font: var(--font-label-2);
  color: var(--color-text);
  background: var(--color-layer-1);
  border: 1px solid var(--color-surface-5);
  border-radius: 100px;
  align-items: center;
  padding: 6px 12px;
  text-decoration: none;
  display: flex;
}

.O1NQ5W_tabs .O1NQ5W_tab:not(:last-child) {
  margin-right: 16px;
}

.O1NQ5W_tabs .O1NQ5W_tab .O1NQ5W_icon {
  width: 16px;
  height: 16px;
  object-fit: cover;
  color: var(--color-text-link);
  margin-right: 4px;
}

.O1NQ5W_tabs .O1NQ5W_tab.O1NQ5W_active {
  background: var(--color-inverse-primary);
  color: var(--color-white);
  border-color: var(--color-inverse-primary);
}

.O1NQ5W_tabs .O1NQ5W_tab.O1NQ5W_active .O1NQ5W_icon {
  color: var(--color-button-icon);
}

@keyframes _84lriq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _84lriq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._84lriq_container {
  font: var(--font-label-2);
  align-items: center;
  gap: 8px;
  display: flex;
}

._84lriq_container > svg {
  flex-shrink: 0;
}

@keyframes ke03AG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ke03AG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ke03AG_readme {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  margin: 24px;
  padding: 0 24px;
}

@keyframes LfdwjW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes LfdwjW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.LfdwjW_notice {
  margin: 16px 0 0;
}

@keyframes _3V_yJa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3V_yJa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3V_yJa_container {
  align-items: center;
  display: flex;
}

._3V_yJa_logoContainer {
  width: 40px;
  height: 40px;
  background-color: var(--color-hover);
  border-radius: 8px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

._3V_yJa_logo {
  width: 28px;
  height: 28px;
}

._3V_yJa_previewTitle {
  align-items: center;
  display: flex;
}

._3V_yJa_previewTitle > :first-child {
  margin-right: 8px;
}

._3V_yJa_platforms, ._3V_yJa_platforms ._3V_yJa_platform {
  align-items: center;
  display: flex;
}

._3V_yJa_platforms ._3V_yJa_platform:not(:last-child):after {
  content: "";
  width: 0;
  height: 12px;
  border-right: 1px solid var(--color-border);
  margin: 0 4px;
}

._3V_yJa_platforms ._3V_yJa_platform svg {
  margin-right: 4px;
}

@keyframes _6N10NW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _6N10NW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._6N10NW_field {
  align-items: center;
  display: flex;
}

._6N10NW_field ._6N10NW_tipButton {
  margin-left: 2px;
}

._6N10NW_title {
  font: var(--font-label-2);
}

._6N10NW_content {
  font: var(--font-body-2);
}

@keyframes _2-O1KW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _2-O1KW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._2-O1KW_container {
  background-color: var(--color-base);
  height: 100vh;
  flex-direction: column;
  display: flex;
  overflow-x: auto;
}

._2-O1KW_container ._2-O1KW_header {
  height: 64px;
  background: none;
  align-items: center;
  padding: 0 84px 0 8px;
  display: flex;
}

._2-O1KW_container ._2-O1KW_header button {
  margin-left: 16px;
}

._2-O1KW_container ._2-O1KW_header ._2-O1KW_separator {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 20px;
  margin: 0 20px 0 16px;
}

._2-O1KW_container ._2-O1KW_header ._2-O1KW_closeIcon {
  color: var(--color-text-secondary);
}

._2-O1KW_container ._2-O1KW_content {
  min-width: min-content;
  flex: 1;
  justify-content: center;
  padding: 8px 24px 24px;
  display: flex;
  overflow: auto;
}

._2-O1KW_container ._2-O1KW_content > * {
  max-width: 800px;
  min-width: 400px;
  flex: 1;
}

._2-O1KW_container ._2-O1KW_content ._2-O1KW_readme {
  background-color: var(--color-layer-1);
  border: 1.5px solid var(--color-focused-variant);
  border-radius: 16px;
  flex-direction: column;
  margin: 0 24px 0 0;
  display: flex;
  position: sticky;
  top: 0;
  overflow-y: auto;
}

._2-O1KW_container ._2-O1KW_content ._2-O1KW_readme ._2-O1KW_readmeTitle {
  font: var(--font-title-2);
  border-bottom: 1px solid var(--color-focused-variant);
  padding: 20px 24px 16px;
}

._2-O1KW_container ._2-O1KW_content ._2-O1KW_readme ._2-O1KW_readmeContent {
  flex: 1;
  padding: 0 24px 16px;
}

._2-O1KW_container ._2-O1KW_content ._2-O1KW_readme ._2-O1KW_readmeContent h2 {
  color: var(--color-text);
}

._2-O1KW_container ._2-O1KW_content ._2-O1KW_readme ._2-O1KW_readmeContent h3 {
  color: var(--color-text-secondary);
}

._2-O1KW_container ._2-O1KW_content ._2-O1KW_setup {
  padding-bottom: 24px;
}

._2-O1KW_container ._2-O1KW_content ._2-O1KW_setup ._2-O1KW_block {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 0 24px 24px;
}

._2-O1KW_container ._2-O1KW_content ._2-O1KW_setup ._2-O1KW_block ._2-O1KW_blockTitle {
  font: var(--font-title-2);
  align-items: center;
  gap: 16px;
  padding: 20px 0 24px;
  display: flex;
}

._2-O1KW_container ._2-O1KW_content ._2-O1KW_setup ._2-O1KW_block ._2-O1KW_blockTitle ._2-O1KW_number {
  width: 28px;
  height: 28px;
  background-color: var(--color-focused-variant);
  color: var(--color-primary);
  font: var(--font-title-2);
  text-align: center;
  border-radius: 50%;
  line-height: 28px;
}

._2-O1KW_container ._2-O1KW_content ._2-O1KW_setup ._2-O1KW_footer {
  justify-content: right;
  padding-bottom: 40px;
  display: flex;
}

._2-O1KW_container ._2-O1KW_content form + div {
  margin-top: 24px;
}

@keyframes _78fBFG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _78fBFG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._78fBFG_notice {
  margin-top: 16px;
}

@keyframes G05ORa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes G05ORa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.G05ORa_container .G05ORa_tabs {
  margin-top: 16px;
}

.G05ORa_container .G05ORa_connectorName {
  width: 360px;
}

.G05ORa_container .G05ORa_empty {
  text-align: center;
  font-size: var(--font-body-2);
}

.G05ORa_container .G05ORa_emptyLine {
  padding: 8px 0;
}

@keyframes Mn-v3W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Mn-v3W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Mn-v3W_container {
  background-color: var(--color-surface-1);
  color: var(--color-text);
  height: 100vh;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  display: flex;
  overflow-y: auto;
}

.Mn-v3W_container > * {
  margin-top: 24px;
}

.Mn-v3W_container > svg {
  height: 256px;
  width: 256px;
  flex-shrink: 0;
  margin-top: 120px;
}

.Mn-v3W_container label {
  font: var(--font-title-1);
}

.Mn-v3W_container .Mn-v3W_summary {
  max-width: 470px;
  font: var(--font-body-2);
  text-align: center;
  align-items: center;
  margin-top: 16px;
  display: flex;
}

.Mn-v3W_container .Mn-v3W_summary .Mn-v3W_expander {
  color: var(--color-primary);
  cursor: pointer;
  align-items: center;
  margin-left: 8px;
  display: inline-flex;
}

.Mn-v3W_container .Mn-v3W_summary svg {
  width: 20px;
  height: 20px;
  color: var(--color-primary);
}

.Mn-v3W_container .Mn-v3W_details {
  width: 470px;
  background-color: var(--color-layer-1);
  font: var(--font-body-2);
  white-space: pre-wrap;
  word-break: break-all;
  border-radius: 16px;
  padding: 24px;
}

@keyframes bk57eW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes bk57eW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.bk57eW_number {
  font: var(--font-headline-3);
}

.bk57eW_delta {
  font: var(--font-title-2);
  color: var(--color-success-50);
  align-items: center;
  display: flex;
}

.bk57eW_delta.bk57eW_down {
  color: var(--color-error-50);
}

.bk57eW_block {
  flex: 1;
}

.bk57eW_block:not(:last-child) {
  margin-right: 16px;
}

.bk57eW_block.bk57eW_bordered {
  border: 1px solid var(--color-divider);
  width: 360px;
  flex: unset;
}

.bk57eW_block .bk57eW_title {
  font: var(--font-title-2);
  align-items: center;
  margin-bottom: 24px;
  display: flex;
}

.bk57eW_block .bk57eW_title .bk57eW_toggleTipButton {
  margin-left: 2px;
}

.bk57eW_block .bk57eW_content {
  align-items: baseline;
  display: flex;
}

.bk57eW_block .bk57eW_content .bk57eW_number {
  flex: 1;
}

.bk57eW_block.bk57eW_plain {
  padding: 0;
}

.bk57eW_block.bk57eW_plain .bk57eW_title {
  font: var(--font-title-2);
  margin-bottom: 24px;
}

.bk57eW_block.bk57eW_plain .bk57eW_content {
  align-items: center;
  display: flex;
}

.bk57eW_block.bk57eW_plain .bk57eW_content .bk57eW_number {
  flex: 0;
  margin-right: 8px;
}

@keyframes hGh_LW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes hGh_LW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.hGh_LW_chartTooltip {
  background: var(--color-float);
  border: 1px solid var(--color-divider);
  box-shadow: var(--shadow-2);
  text-align: center;
  border-radius: 4px;
  padding: 8px 12px;
}

.hGh_LW_chartTooltip .hGh_LW_value {
  font: var(--font-label-2);
  color: var(--color-text);
}

.hGh_LW_chartTooltip .hGh_LW_label {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
}

@keyframes grVxOq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes grVxOq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.grVxOq_title {
  background-color: var(--color-layer-2);
  height: 24px;
  border-radius: 8px;
  margin-bottom: 24px;
  position: relative;
  overflow: hidden;
}

.grVxOq_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite grVxOq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.grVxOq_number {
  background-color: var(--color-layer-2);
  height: 32px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.grVxOq_number:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite grVxOq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.grVxOq_blocks {
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.grVxOq_block {
  flex: 1;
}

.grVxOq_block:not(:last-child) {
  margin-right: 16px;
}

.grVxOq_dau {
  width: 300px;
}

.grVxOq_curve {
  background-color: var(--color-layer-2);
  height: 168px;
  border-radius: 8px;
  margin: 40px 0 24px;
  position: relative;
  overflow: hidden;
}

.grVxOq_curve:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite grVxOq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.grVxOq_activeBlocks {
  align-items: center;
  display: flex;
}

.grVxOq_activeBlocks .grVxOq_block {
  border: 1px solid var(--color-divider);
  width: 360px;
  flex: unset;
}

@keyframes aADheW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes aADheW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.aADheW_container {
  flex-direction: column;
  padding-bottom: 24px;
  display: flex;
  overflow-y: auto;
}

.aADheW_header {
  margin-bottom: 24px;
}

.aADheW_header .aADheW_title {
  font: var(--font-title-1);
  color: var(--color-text);
}

.aADheW_header .aADheW_subtitle {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 4px;
  padding-right: 24px;
}

.aADheW_blocks {
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.aADheW_activeCard {
  position: relative;
}

.aADheW_activeCard .aADheW_datePicker {
  width: 160px;
  position: absolute;
  top: 24px;
  right: 24px;
}

.aADheW_curve {
  width: 100%;
  height: 168px;
  margin: 40px 0 24px;
}

.KNePoW_container {
  background-color: var(--color-hover);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.KNePoW_logo {
  flex-shrink: 0;
}

@keyframes EhFvKG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes EhFvKG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.EhFvKG_ssoConnector {
  font: var(--font-body-2);
  display: flex;
}

.EhFvKG_ssoConnector .EhFvKG_content {
  flex: 1;
  margin-left: 12px;
}

.EhFvKG_ssoConnector .EhFvKG_content .EhFvKG_name {
  font: var(--font-label-2);
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  padding-right: 12px;
  display: -webkit-box;
  overflow: hidden;
}

.EhFvKG_ssoConnector .EhFvKG_content .EhFvKG_description {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  margin-top: 4px;
  display: -webkit-box;
  overflow: hidden;
}

.EhFvKG_container {
  width: 40px;
  height: 40px;
  background-color: var(--color-hover);
  border-radius: 8px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.EhFvKG_container > img {
  width: 28px;
  height: 28px;
}

.EhFvKG_logo {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

@keyframes zOIz_a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zOIz_a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.zOIz_a_ssoConnectorGroup {
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  display: grid;
}

@media screen and (max-width: 850px) {
  .zOIz_a_ssoConnectorGroup {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 668px) {
  .zOIz_a_ssoConnectorGroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .zOIz_a_ssoConnectorGroup {
    grid-template-columns: repeat(1, 1fr);
  }
}

.zOIz_a_ssoConnectorGroup.zOIz_a_medium {
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 500px) {
  .zOIz_a_ssoConnectorGroup.zOIz_a_medium {
    grid-template-columns: repeat(1, 1fr);
  }
}

.zOIz_a_ssoConnectorGroup.zOIz_a_large {
  grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 668px) {
  .zOIz_a_ssoConnectorGroup.zOIz_a_large {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .zOIz_a_ssoConnectorGroup.zOIz_a_large {
    grid-template-columns: repeat(1, 1fr);
  }
}

@keyframes TBsm3a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes TBsm3a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.TBsm3a_textDivider {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin: 24px 0 16px;
}

@keyframes EpHMJG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes EpHMJG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.EpHMJG_type {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.EpHMJG_container {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.EpHMJG_container > img {
  width: 28px;
  height: 28px;
}

.EpHMJG_logo {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
}

.EpHMJG_domains {
  flex-wrap: wrap;
  gap: 8px;
  display: flex;
}

@keyframes zuDSTG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zuDSTG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.zuDSTG_copyToClipboard {
  display: block;
}

@keyframes Qn5Lwq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Qn5Lwq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Qn5Lwq_container {
  background-color: var(--color-layer-light);
  border-radius: 8px;
  padding: 24px;
}

.Qn5Lwq_container > div {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.Qn5Lwq_container > div .Qn5Lwq_title {
  font: var(--font-title-3);
}

.Qn5Lwq_container > div .Qn5Lwq_content {
  max-width: 100%;
  font: var(--font-body-2);
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.Qn5Lwq_container > :not(:last-child) {
  margin-bottom: 24px;
}

@keyframes tsEUTq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes tsEUTq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.tsEUTq_copyToClipboard {
  display: block;
}

.tsEUTq_oidcConfigPreview {
  margin-top: 12px;
}

@keyframes v3-9YW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes v3-9YW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.v3-9YW_copyToClipboard {
  display: block;
}

.v3-9YW_table {
  width: 100%;
}

.v3-9YW_row {
  width: 100%;
  flex-direction: row;
  gap: 16px;
  padding-bottom: 12px;
  display: flex;
}

.v3-9YW_row > td, .v3-9YW_row th {
  width: calc(50% - 8px);
}

.v3-9YW_header {
  width: 100%;
}

.v3-9YW_header > tr {
  padding-bottom: 12px;
}

.v3-9YW_header * > th {
  font: var(--font-label-2);
  color: var(--color-text);
  text-align: left;
}

.v3-9YW_body {
  width: 100%;
}

@keyframes _6EOEtq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _6EOEtq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._6EOEtq_fileInput {
  display: none;
}

._6EOEtq_preview {
  max-width: 400px;
  background: var(--color-layer-1);
  color: var(--color-text);
  border: 1px solid var(--color-border);
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  padding: 20px 20px 20px 16px;
  display: flex;
}

._6EOEtq_preview ._6EOEtq_fileInfo {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 2px;
  margin-left: 8px;
  display: flex;
}

._6EOEtq_preview ._6EOEtq_fileInfo ._6EOEtq_fileName {
  font: var(--font-label-2);
}

._6EOEtq_preview ._6EOEtq_fileInfo ._6EOEtq_fileSize {
  font: var(--font-label-3);
  color: var(--color-text-secondary);
}

._6EOEtq_preview ._6EOEtq_delete {
  width: 28px;
  height: 28px;
  background-color: #0000;
  border: none;
  border-radius: 6px;
  padding: 4px;
  display: flex;
}

._6EOEtq_preview ._6EOEtq_delete ._6EOEtq_icon {
  color: var(--color-text-secondary);
}

._6EOEtq_preview ._6EOEtq_delete:hover {
  background: var(--color-hover);
  border-radius: 6px;
}

._6EOEtq_error {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
}

@keyframes JgiM0G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes JgiM0G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.JgiM0G_container {
  background-color: var(--color-layer-light);
  border-radius: 8px;
  padding: 24px;
}

.JgiM0G_container > div {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.JgiM0G_container > div .JgiM0G_title {
  font: var(--font-title-3);
}

.JgiM0G_container > div .JgiM0G_content {
  max-width: 100%;
  font: var(--font-body-2);
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.JgiM0G_container > :not(:last-child) {
  margin-bottom: 24px;
}

.JgiM0G_certificatePreview {
  font: var(--font-body-2);
  flex-direction: row;
  align-items: center;
  display: flex;
}

.JgiM0G_certificatePreview .JgiM0G_indicator {
  width: 10px;
  height: 10px;
  background: var(--color-on-success-container);
  border-radius: 50%;
  margin-right: 8px;
}

.JgiM0G_certificatePreview .JgiM0G_errorStatus {
  background: var(--color-on-error-container);
}

.JgiM0G_certificatePreview .JgiM0G_copyToClipboard {
  margin-left: 4px;
}

@keyframes eYEHqq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes eYEHqq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.eYEHqq_dropdownIcon {
  color: var(--color-text-link);
}

.eYEHqq_dropdown {
  min-width: unset;
}

.eYEHqq_icon {
  width: 20px;
  height: 20px;
  color: #0000;
  flex-shrink: 0;
}

.eYEHqq_icon.eYEHqq_selected {
  color: var(--color-primary-40);
}

.eYEHqq_title {
  align-items: center;
  margin-left: 4px;
  display: flex;
}

.eYEHqq_title .eYEHqq_optionText {
  font: var(--font-body-2);
  margin-left: 12px;
}

.eYEHqq_title.eYEHqq_selected {
  color: var(--color-primary-40);
}

@keyframes VZM6aG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes VZM6aG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.VZM6aG_description {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: 2px;
}

.VZM6aG_certificatePreview {
  margin-top: 4px;
}

@keyframes PniY7q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes PniY7q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.PniY7q_samlMetadataForm > div:not(:first-child) {
  margin-top: 24px;
}

@keyframes _8qpGpW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _8qpGpW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._8qpGpW_input {
  background: var(--color-layer-1);
  border: 1px solid var(--color-border);
  font: var(--font-body-2);
  cursor: pointer;
  border-radius: 8px;
  outline: 3px solid #0000;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px 0 12px;
  transition-property: outline, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: relative;
}

._8qpGpW_input._8qpGpW_multiple {
  cursor: text;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 8px;
  padding: 6px 12px;
}

._8qpGpW_input._8qpGpW_multiple ._8qpGpW_tag {
  cursor: auto;
  align-items: center;
  gap: 4px;
  display: flex;
  position: relative;
}

._8qpGpW_input._8qpGpW_multiple ._8qpGpW_tag._8qpGpW_focused:after {
  content: "";
  background: var(--color-overlay-default-focused);
  position: absolute;
  inset: 0;
}

._8qpGpW_input._8qpGpW_multiple ._8qpGpW_tag._8qpGpW_info {
  background: var(--color-error-container);
}

._8qpGpW_input._8qpGpW_multiple ._8qpGpW_close {
  width: 16px;
  height: 16px;
}

._8qpGpW_input._8qpGpW_multiple ._8qpGpW_delete {
  width: 20px;
  height: 20px;
  margin-right: -2px;
}

._8qpGpW_input._8qpGpW_multiple input {
  color: var(--color-text);
  font: var(--font-body-2);
  background: none;
  flex-grow: 1;
  padding: 2px;
}

._8qpGpW_input._8qpGpW_multiple input::placeholder {
  color: var(--color-placeholder);
}

._8qpGpW_input:focus-within {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

._8qpGpW_input._8qpGpW_error {
  border-color: var(--color-error);
}

._8qpGpW_input._8qpGpW_error:focus-within {
  outline-color: var(--color-danger-focused);
}

._8qpGpW_errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
}

@keyframes wBBHtG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes wBBHtG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.wBBHtG_container {
  flex-direction: column;
  display: flex;
}

.wBBHtG_uploader {
  display: flex;
}

.wBBHtG_uploader .wBBHtG_logoUploader {
  flex: 1;
}

.wBBHtG_uploader .wBBHtG_logoUploader .wBBHtG_frame {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.wBBHtG_uploader .wBBHtG_logoUploader .wBBHtG_frameBackground {
  background: #fff;
}

.wBBHtG_uploader .wBBHtG_logoDarkUploader {
  flex: 1;
}

.wBBHtG_uploader .wBBHtG_logoDarkUploader .wBBHtG_frameDark {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.wBBHtG_uploader .wBBHtG_logoDarkUploader .wBBHtG_frameDarkBackground {
  background: #000;
}

.wBBHtG_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 4px;
}

.wBBHtG_error {
  color: var(--color-error);
}

@keyframes _32dlKq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _32dlKq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._32dlKq_description {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: 4px;
}

._32dlKq_inlineNotification {
  margin-top: 24px;
}

@keyframes -ViIsG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes -ViIsG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.-ViIsG_content {
  flex: 1;
  position: relative;
}

.-ViIsG_content section {
  margin: 24px 0;
}

.-ViIsG_content section:first-child {
  margin-top: 0;
}

.-ViIsG_content section:last-child {
  margin-bottom: 0;
}

.-ViIsG_content h3 {
  font: var(--font-title-2);
  color: var(--color-text-secondary);
  margin: 24px 0 12px;
}

.-ViIsG_content p {
  font: var(--font-body-2);
  margin: 16px 0;
}

.-ViIsG_content ul, .-ViIsG_content ol {
  margin-block: 16px;
  padding-inline-start: 16px;
}

.-ViIsG_content ul > li, .-ViIsG_content ol > li {
  font: var(--font-body-2);
  margin-block: 8px;
  padding-inline-start: 4px;
}

.-ViIsG_content table {
  border-spacing: 0;
  border: 1px solid var(--color-border);
  font: var(--font-body-2);
}

.-ViIsG_content table tr {
  width: 100%;
}

.-ViIsG_content table td, .-ViIsG_content table th {
  padding: 8px 16px;
}

.-ViIsG_content table thead {
  font: var(--font-title-3);
}

.-ViIsG_content table tbody td {
  border-top: 1px solid var(--color-border);
}

.-ViIsG_content code:not(pre > code) {
  background: var(--color-layer-2);
  font: var(--font-body-2);
  border-radius: 4px;
  padding: 4px;
}

.-ViIsG_content img {
  width: 90%;
  border-radius: 8px;
}

@keyframes _6rYuna_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _6rYuna_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._6rYuna_readme {
  margin: 24px;
}

._6rYuna_container {
  width: 60px;
  height: 60px;
  border-radius: 12px;
}

._6rYuna_container > img, ._6rYuna_logo {
  width: 42px;
  height: 42px;
}

@keyframes TxnFKG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes TxnFKG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.TxnFKG_container {
  border: 1px solid var(--color-divider);
  border-radius: 12px;
  overflow: hidden;
}

.TxnFKG_container .TxnFKG_separator {
  border-bottom: 1px solid var(--color-divider);
}

.TxnFKG_container .TxnFKG_form {
  background: var(--color-layer-light);
  padding: 24px 32px 32px 44px;
}

.TxnFKG_container .TxnFKG_protectedApps {
  background: var(--color-success-container);
  flex-direction: column;
  gap: 12px;
  padding: 20px 36px;
  display: flex;
}

.TxnFKG_container .TxnFKG_protectedApps .TxnFKG_label {
  font: var(--font-label-2);
  white-space: pre-wrap;
  align-items: center;
  display: flex;
}

.TxnFKG_container .TxnFKG_protectedApps .TxnFKG_list {
  flex-wrap: wrap;
  gap: 12px 32px;
  display: flex;
}

.TxnFKG_container .TxnFKG_protectedApps .TxnFKG_app {
  font: var(--font-body-2);
  align-items: center;
  gap: 12px;
  padding-left: 20px;
  display: flex;
}

.TxnFKG_container .TxnFKG_protectedApps .TxnFKG_app .TxnFKG_status {
  width: 10px;
  height: 10px;
  background: var(--color-on-success-container);
  border-radius: 50%;
  margin-right: -4px;
}

.TxnFKG_container .TxnFKG_protectedApps .TxnFKG_app .TxnFKG_hostName {
  color: var(--color-text);
  margin-right: -12px;
  text-decoration: none;
}

.TxnFKG_container .TxnFKG_protectedApps .TxnFKG_app .TxnFKG_hostName:hover {
  text-decoration: underline;
}

.TxnFKG_loadingSkeleton {
  border: 1px solid var(--color-divider);
  border-radius: 12px;
  align-items: center;
  gap: 24px;
  padding: 24px 32px;
  display: flex;
}

.TxnFKG_loadingSkeleton .TxnFKG_bone {
  background-color: var(--color-layer-2);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.TxnFKG_loadingSkeleton .TxnFKG_bone:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite TxnFKG_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.TxnFKG_loadingSkeleton .TxnFKG_columnWrapper {
  flex-direction: column;
  flex: 1;
  gap: 8px;
  display: flex;
}

.TxnFKG_loadingSkeleton .TxnFKG_icon {
  width: 48px;
  height: 48px;
}

.TxnFKG_loadingSkeleton .TxnFKG_title {
  width: 100%;
  height: 20px;
}

.TxnFKG_loadingSkeleton .TxnFKG_description {
  width: 100%;
  height: 16px;
}

@keyframes vUugRG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes vUugRG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.vUugRG_container {
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
  display: flex;
  overflow-y: auto;
}

.vUugRG_title {
  font: var(--font-title-1);
  color: var(--color-text);
}

.vUugRG_subtitle {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-bottom: -12px;
}

.vUugRG_header {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.vUugRG_card {
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  display: flex;
  container-type: inline-size;
}

.vUugRG_card .vUugRG_borderBox {
  border: 1px solid var(--color-divider);
  border-radius: 16px;
  align-items: center;
  gap: 24px;
  padding: 24px 32px;
  display: flex;
}

.vUugRG_card .vUugRG_borderBox .vUugRG_icon {
  width: 48px;
  height: 48px;
}

.vUugRG_card .vUugRG_borderBox .vUugRG_title {
  font: var(--font-title-2);
}

.vUugRG_card .vUugRG_rowWrapper {
  align-items: center;
  gap: 24px;
  display: flex;
}

.vUugRG_card .vUugRG_columnWrapper {
  flex-direction: column;
  display: flex;
}

.vUugRG_card .vUugRG_grid {
  width: 100%;
  gap: 16px 12px;
  display: grid;
}

@container (max-width: 960px) {
  .vUugRG_card .vUugRG_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@container (min-width: 961px) {
  .vUugRG_card .vUugRG_grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@container (max-width: 720px) {
  .vUugRG_card .vUugRG_buttons {
    flex-direction: column;
    align-items: flex-end;
    gap: 16px;
  }
}

@keyframes d6tymq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes d6tymq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.d6tymq_notification {
  padding: 24px;
}

@keyframes Dwh-rW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Dwh-rW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Dwh-rW_factorTitle {
  font: var(--font-label-2);
  align-items: center;
  display: inline-flex;
}

.Dwh-rW_factorIcon {
  color: var(--color-text-secondary);
  margin-right: 12px;
}

.Dwh-rW_factorTip {
  margin-left: 4px;
}

@keyframes zXM0ta_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zXM0ta_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.zXM0ta_webauthnTipContent {
  margin: 0;
  padding-inline-start: 16px;
}

@keyframes dqcVIG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes dqcVIG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.dqcVIG_factorLabel {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.dqcVIG_factorDescription {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

@keyframes Pc7KcW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Pc7KcW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Pc7KcW_factorField {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.Pc7KcW_backupCodeField {
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
  display: flex;
}

.Pc7KcW_backupCodeField .Pc7KcW_backupCodeDescription {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.Pc7KcW_unlockMfaNotice {
  margin-top: 16px;
}

@keyframes _6tXdya_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _6tXdya_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._6tXdya_container {
  min-height: 100%;
  flex-direction: column;
  display: flex;
}

._6tXdya_container ._6tXdya_cardTitle {
  flex-shrink: 0;
  margin-bottom: 16px;
}

@keyframes JK0d5G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes JK0d5G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.JK0d5G_container {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

.wvr_2q_icon {
  color: var(--color-specific-icon-bg);
}

@keyframes qq06Ba_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes qq06Ba_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.qq06Ba_modalContainer {
  height: 100vh;
  width: 100vw;
  background: var(--color-base);
  flex-direction: column;
  display: flex;
}

.qq06Ba_stepContainer {
  width: 100%;
  flex: 1;
  padding: 24px;
  overflow: hidden;
}

.qq06Ba_content {
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: flex;
}

.qq06Ba_content .qq06Ba_card {
  width: 100%;
  max-width: 858px;
  min-width: 750px;
  flex-direction: column;
  gap: 24px;
  padding: 48px;
  display: flex;
}

.qq06Ba_content .qq06Ba_card .qq06Ba_section {
  width: 100%;
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.qq06Ba_content .qq06Ba_card .qq06Ba_image {
  width: 100%;
}

.qq06Ba_content .qq06Ba_card .qq06Ba_icon {
  width: 48px;
  height: 48px;
  flex-shrink: 0;
}

.qq06Ba_content .qq06Ba_card .qq06Ba_title {
  font: var(--font-title-1);
}

.qq06Ba_content .qq06Ba_card .qq06Ba_subtitle {
  font: var(--font-title-2);
  color: var(--color-text-secondary);
}

.qq06Ba_content .qq06Ba_card .qq06Ba_description {
  font: var(--font-body-2);
}

.qq06Ba_content .qq06Ba_card .qq06Ba_fieldGroup {
  width: 100%;
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.qq06Ba_content .qq06Ba_card .qq06Ba_fieldGroup > div {
  margin: 0;
}

.qq06Ba_content .qq06Ba_card ul {
  margin: 0;
  padding-inline-start: 2ch;
}

.qq06Ba_content .qq06Ba_card ul > li {
  padding-inline-start: 4px;
}

@keyframes V12j3W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes V12j3W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.V12j3W_flexBox {
  width: 100%;
  display: flex;
}

.V12j3W_flexBox.V12j3W_column {
  flex-direction: column;
}

.V12j3W_flexBox.V12j3W_equallyDivided > * {
  flex: 1;
}

@keyframes Eorspq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Eorspq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Eorspq_panel {
  border: 1px solid var(--color-on-info-container);
  border-radius: 8px;
  overflow: hidden;
}

.Eorspq_panel .Eorspq_header {
  font: var(--font-title-2);
  color: var(--color-white);
  background: var(--color-on-info-container);
  text-align: center;
  line-height: 36px;
}

.Eorspq_panel .Eorspq_body {
  background: var(--color-layer-1);
  padding: 32px;
}

.Eorspq_panel.Eorspq_small .Eorspq_header {
  font: var(--font-label-3);
  line-height: 24px;
}

.Eorspq_panel.Eorspq_small .Eorspq_body {
  padding: 24px;
}

.Eorspq_panel.Eorspq_blue {
  border-color: #579dff;
}

.Eorspq_panel.Eorspq_blue .Eorspq_header {
  background: #579dff;
}

.Eorspq_panel.Eorspq_purple {
  border-color: #947dff;
}

.Eorspq_panel.Eorspq_purple .Eorspq_header {
  background: #947dff;
}

@keyframes WNEnQa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes WNEnQa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.WNEnQa_permission {
  background: var(--color-bg-info-tag);
  font: var(--font-title-3);
  color: var(--color-text);
  text-align: center;
  border-radius: 6px;
  padding: 4px 8px;
}

.WNEnQa_permission.WNEnQa_monoSpace {
  font-family: Roboto Mono, monospace;
  font-weight: normal;
}

@keyframes nnhCmG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nnhCmG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nnhCmG_role {
  height: 100%;
  background: var(--color-bg-float);
  border: 1px solid var(--color-on-info-container);
  cursor: default;
  opacity: .3;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 16px;
  transition: border-color .3s ease-in-out, opacity .3s ease-in-out;
}

.nnhCmG_role .nnhCmG_label {
  font: var(--font-title-3);
  white-space: nowrap;
}

.nnhCmG_role .nnhCmG_permissions {
  flex: 1;
  align-items: center;
}

.nnhCmG_role.nnhCmG_small {
  background: var(--color-layer-2);
  border-color: #0000;
  padding: 16px 0;
}

.nnhCmG_role.nnhCmG_small .nnhCmG_label {
  font: var(--font-label-3);
}

.nnhCmG_role.nnhCmG_active {
  opacity: 1;
}

.nnhCmG_role.nnhCmG_interactive {
  cursor: pointer;
}

.nnhCmG_role.nnhCmG_interactive:hover {
  border-color: var(--color-on-info-container);
}

@keyframes LUuDUa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes LUuDUa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.LUuDUa_user {
  width: 80px;
  height: 80px;
  background: var(--color-layer-2);
  color: var(--color-on-info-container);
  cursor: default;
  opacity: .3;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 16px;
  transition: border-color .3s ease-in-out, opacity .3s ease-in-out;
  display: flex;
}

.LUuDUa_user .LUuDUa_avatar {
  width: 28px;
  height: 28px;
}

.LUuDUa_user .LUuDUa_name {
  font: var(--font-label-3);
  color: var(--color-text);
}

.LUuDUa_user.LUuDUa_blue {
  color: #579dff;
}

.LUuDUa_user.LUuDUa_pink {
  color: #ff897a;
}

.LUuDUa_user.LUuDUa_blue, .LUuDUa_user.LUuDUa_pink {
  width: 67px;
  height: 80px;
  border: 1px solid #0000;
  flex: 0 0 67px;
}

.LUuDUa_user.LUuDUa_blue.LUuDUa_small, .LUuDUa_user.LUuDUa_pink.LUuDUa_small {
  width: 50px;
  height: 56px;
  flex: 0 0 50px;
  margin: 0;
  padding: 8px;
}

.LUuDUa_user.LUuDUa_blue.LUuDUa_small .LUuDUa_avatar, .LUuDUa_user.LUuDUa_pink.LUuDUa_small .LUuDUa_avatar {
  height: 20px;
}

.LUuDUa_user.LUuDUa_blue:hover, .LUuDUa_user.LUuDUa_pink:hover {
  border-color: var(--color-on-info-container);
}

.LUuDUa_user.LUuDUa_active {
  opacity: 1;
}

.LUuDUa_user.LUuDUa_interactive {
  cursor: pointer;
}

.pKzp0W_vector {
  opacity: .3;
  flex-shrink: 0;
  transition: opacity .3s ease-in-out;
}

.pKzp0W_vector.pKzp0W_active {
  opacity: 1;
}

.pKzp0W_vector .pKzp0W_textWrapper {
  fill: var(--color-layer-1);
}

.pKzp0W_vector .pKzp0W_text {
  font: var(--font-body-3);
  fill: var(--color-text-secondary);
}

@keyframes QBcT2a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes QBcT2a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.QBcT2a_sectionTitle {
  font: var(--font-title-2);
}

.QBcT2a_description {
  font: var(--font-body-2);
}

.QBcT2a_panel {
  flex: 1;
}

@keyframes g_WjkG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes g_WjkG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.g_WjkG_section {
  width: 100%;
  background: var(--color-layer-light);
  border-radius: 12px;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  display: flex;
}

.g_WjkG_section .g_WjkG_title {
  font: var(--font-title-3);
}

.g_WjkG_section .g_WjkG_description {
  font: var(--font-body-2);
}

@keyframes XAQ4ba_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes XAQ4ba_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.XAQ4ba_container .XAQ4ba_title {
  font: var(--font-title-1);
}

.XAQ4ba_container .XAQ4ba_sectionTitle {
  font: var(--font-title-2);
}

.XAQ4ba_container .XAQ4ba_description {
  font: var(--font-body-2);
}

.XAQ4ba_container .XAQ4ba_panel {
  flex: 1;
}

.XAQ4ba_container .XAQ4ba_compact {
  padding: 24px;
}

@keyframes FQBl7a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes FQBl7a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.FQBl7a_roleOption {
  align-items: center;
  gap: 4px;
  display: flex;
}

.FQBl7a_roleOption.FQBl7a_large {
  gap: 12px;
}

.FQBl7a_roleOption > svg {
  flex-shrink: 0;
}

.FQBl7a_roleOption > div {
  font: var(--font-label-2);
}

@keyframes Y0u9ea_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Y0u9ea_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Y0u9ea_item {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  padding: 10px 16px;
  display: flex;
}

.Y0u9ea_item .Y0u9ea_icon {
  width: 20px;
  height: 20px;
  border-radius: 6px;
}

.Y0u9ea_item .Y0u9ea_title {
  font: var(--font-body-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: -moz-fit-content;
  max-width: fit-content;
  flex: 1 1 0;
  margin-left: 8px;
  overflow: hidden;
}

.Y0u9ea_item .Y0u9ea_suspended {
  margin-left: 4px;
}

.Y0u9ea_item:hover {
  background: var(--color-hover);
}

.Nr_Nla_search {
  width: 100%;
}

.Nr_Nla_icon {
  color: var(--color-text-secondary);
}

@keyframes -f8eNq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes -f8eNq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.-f8eNq_item {
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  padding: 8px 12px 8px 16px;
  display: flex;
}

.-f8eNq_item .-f8eNq_meta {
  flex: 1;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.-f8eNq_item .-f8eNq_meta .-f8eNq_icon {
  width: 20px;
  height: 20px;
  border-radius: 6px;
}

.-f8eNq_item .-f8eNq_meta .-f8eNq_title {
  font: var(--font-body-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: -moz-fit-content;
  max-width: fit-content;
  flex: 1 1 0;
  margin-left: 8px;
  overflow: hidden;
}

.-f8eNq_item .-f8eNq_meta .-f8eNq_suspended {
  margin: 0 4px;
}

.-f8eNq_item:hover {
  background: var(--color-hover);
}

.OoY2hq_added {
  font: var(--font-label-2);
}

@keyframes zPwfGa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zPwfGa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.zPwfGa_rolesTransfer {
  height: 360px;
}

.zPwfGa_errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
}

@keyframes da450q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes da450q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.da450q_icon {
  width: 20px;
  height: 20px;
  border-radius: 6px;
}

.da450q_title {
  font: var(--font-body-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: -moz-fit-content;
  max-width: fit-content;
  flex: 1 1 0;
  margin-left: 8px;
  overflow: hidden;
}

.da450q_suspended {
  margin-left: 4px;
}

@keyframes HFfI9G_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes HFfI9G_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.HFfI9G_roles {
  flex-wrap: wrap;
  gap: 8px;
  display: flex;
}

.HFfI9G_filter {
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  display: flex;
}

@keyframes sCS0zG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes sCS0zG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.sCS0zG_page {
  height: 100%;
  padding-bottom: 24px;
}

.sCS0zG_header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.sCS0zG_header .sCS0zG_metadata {
  align-items: center;
  gap: 24px;
  display: flex;
}

.sCS0zG_header .sCS0zG_row {
  align-items: center;
  gap: 4px;
  display: flex;
}

.sCS0zG_header .sCS0zG_name {
  font: var(--font-title-1);
  color: var(--color-text);
}

.sCS0zG_header .sCS0zG_label {
  font: var(--font-label-2);
  color: var(--color-text-secondary);
}

@keyframes UErFpq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes UErFpq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.UErFpq_entities {
  align-items: center;
  display: flex;
}

.UErFpq_entities .UErFpq_avatars {
  flex-direction: row-reverse;
  align-items: center;
  display: flex;
}

.UErFpq_entities .UErFpq_avatars .UErFpq_avatar {
  border: 2px solid var(--color-layer-1);
  box-sizing: content-box;
  position: relative;
}

.UErFpq_entities .UErFpq_avatars .UErFpq_avatar:not(:last-child) {
  margin-left: -8px;
}

.UErFpq_entities .UErFpq_avatars .UErFpq_applicationIcon {
  width: 24px;
  height: 24px;
  border-radius: 8px;
}

.UErFpq_entities .UErFpq_count {
  color: var(--color-text);
  font: var(--font-body-2);
  margin-left: 9px;
}

.UErFpq_empty {
  padding-left: 2px;
}

@keyframes MBDhfG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes MBDhfG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.MBDhfG_placeholder {
  width: 600px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.MBDhfG_placeholder .MBDhfG_image {
  width: 200px;
  height: 200px;
}

.MBDhfG_placeholder .MBDhfG_title {
  font: var(--font-label-2);
}

.MBDhfG_placeholder .MBDhfG_text {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  text-align: center;
  margin-top: 4px;
}

.MBDhfG_placeholder .MBDhfG_button {
  margin-top: 24px;
}

@keyframes _63e_tG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _63e_tG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._63e_tG_permissions {
  flex-wrap: wrap;
  gap: 8px;
  display: flex;
}

@keyframes rexQaG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes rexQaG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.rexQaG_content {
  padding: 16px 0;
}

.rexQaG_content > div + div {
  margin-top: 16px;
}

@keyframes ynTgOa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ynTgOa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ynTgOa_tabs {
  margin-top: 16px;
}

.ynTgOa_emptyCardContainer {
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  display: flex;
}

@keyframes _3H-apq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3H-apq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3H-apq_formContainer {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

._3H-apq_title {
  font: var(--font-label-2);
}

._3H-apq_item {
  width: 100%;
  align-items: center;
  gap: 8px;
  display: flex;
}

._3H-apq_item ._3H-apq_fieldWrapper {
  background: var(--color-layer-light);
  border-radius: 12px;
  flex: 1;
  padding: 16px;
}

._3H-apq_skeleton {
  width: 100%;
  padding: 4px 0;
}

._3H-apq_skeleton ._3H-apq_group {
  width: 100%;
  flex-direction: column;
  gap: 12px;
  display: flex;
}

._3H-apq_skeleton ._3H-apq_group + ._3H-apq_group {
  margin-top: 24px;
}

._3H-apq_skeleton ._3H-apq_title {
  width: 200px;
  height: 20px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

._3H-apq_skeleton ._3H-apq_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite _3H-apq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

._3H-apq_skeleton ._3H-apq_field {
  width: 100%;
  height: 36px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

._3H-apq_skeleton ._3H-apq_field:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite _3H-apq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.kSGQ4G_text {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

@keyframes Qw8NkG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Qw8NkG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Qw8NkG_container {
  width: 100%;
  font: var(--font-label-2);
}

.Qw8NkG_container .Qw8NkG_title {
  margin-bottom: 4px;
}

.Qw8NkG_container .Qw8NkG_actionButton {
  display: inline-flex;
}

.Qw8NkG_container .Qw8NkG_actionButton + .Qw8NkG_actionButton {
  margin-left: 16px;
}

.Qw8NkG_container .Qw8NkG_wrapper {
  align-items: center;
  display: flex;
}

.Qw8NkG_container .Qw8NkG_icon {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}

.Qw8NkG_container table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid var(--color-neutral-variant-90);
  border-radius: 8px;
}

.Qw8NkG_container table td {
  font: var(--font-body-2);
  height: 64px;
  border-bottom: 1px solid var(--color-neutral-variant-90);
  padding: 0 24px;
}

.Qw8NkG_container table td:first-child {
  max-width: 248px;
  width: 25%;
  font: var(--font-label-2);
}

.Qw8NkG_container table td:last-child {
  text-align: right;
  width: 25%;
}

.Qw8NkG_container table td:last-child .Qw8NkG_wrapper {
  justify-content: flex-end;
}

.Qw8NkG_container table td > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 320px;
  display: inline-block;
  overflow: hidden;
}

.Qw8NkG_container table tr:last-child td {
  border-bottom: none;
}

.Qw8NkG_container:not(:first-child) {
  margin-top: 24px;
}

@keyframes XeRJzW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes XeRJzW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.XeRJzW_wrapper {
  align-items: center;
  display: flex;
}

.XeRJzW_wrapper svg {
  flex-shrink: 0;
  margin-right: 8px;
}

.XeRJzW_wrapper span {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 292px;
  overflow: hidden;
}

@keyframes NgX8vq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes NgX8vq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.NgX8vq_container {
  flex-direction: column;
  margin-top: 16px;
  padding-bottom: 24px;
  display: flex;
}

.NgX8vq_section {
  background: var(--color-layer-1);
  border-radius: 12px;
  padding: 24px 32px;
  display: flex;
}

.NgX8vq_section:not(:first-child) {
  margin-top: 16px;
}

.NgX8vq_titleWrapper {
  width: 296px;
  flex-shrink: 0;
  margin-right: 56px;
  padding-bottom: 24px;
}

.NgX8vq_titleWrapper > :not(:first-child) {
  margin-top: 8px;
}

.NgX8vq_title {
  background-color: var(--color-layer-2);
  width: 200px;
  height: 20px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.NgX8vq_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite NgX8vq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.NgX8vq_card {
  flex-grow: 1;
}

.NgX8vq_card .NgX8vq_label {
  background-color: var(--color-layer-2);
  width: 250px;
  height: 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;
}

.NgX8vq_card .NgX8vq_label:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite NgX8vq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.NgX8vq_card .NgX8vq_item {
  background-color: var(--color-layer-2);
  width: 350px;
  height: 20px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.NgX8vq_card .NgX8vq_item:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite NgX8vq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.NgX8vq_card .NgX8vq_table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid var(--color-neutral-variant-90);
  border-radius: 8px;
}

.NgX8vq_card .NgX8vq_table td {
  font: var(--font-body-2);
  height: 64px;
  border-bottom: 1px solid var(--color-neutral-variant-90);
  padding: 0 24px;
}

.NgX8vq_card .NgX8vq_table td:first-child {
  max-width: 248px;
  width: 25%;
}

.NgX8vq_card .NgX8vq_table td:first-child .NgX8vq_item {
  width: 150px;
}

.NgX8vq_card .NgX8vq_table td:last-child {
  text-align: right;
  width: 25%;
}

.NgX8vq_card .NgX8vq_table td:last-child .NgX8vq_item {
  width: 100px;
}

.NgX8vq_card .NgX8vq_table tr:last-child td {
  border-bottom: none;
}

@media screen and (max-width: 1080px) {
  .NgX8vq_section {
    flex-direction: column;
  }

  .NgX8vq_titleWrapper {
    width: 100%;
    margin-right: unset;
  }

  .NgX8vq_card {
    flex-grow: unset;
  }
}

@keyframes shIkWq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes shIkWq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.shIkWq_container {
  font: var(--font-body-2);
}

p {
  margin: 0;
}

p + p {
  margin-top: 24px;
}

.shIkWq_mail {
  font-weight: bold;
}

@keyframes HzVHqa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes HzVHqa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.HzVHqa_content {
  margin-top: 16px;
  padding-bottom: 24px;
}

.HzVHqa_content > div + div {
  margin-top: 16px;
}

.HzVHqa_deleteAccount {
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  flex: 1;
  align-items: center;
  padding: 16px;
  display: flex;
}

.HzVHqa_deleteAccount .HzVHqa_description {
  font: var(--font-body-2);
  margin-right: 8px;
}

@keyframes _79gXNq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _79gXNq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._79gXNq_container {
  background: var(--color-base);
  padding: 20px 0;
}

._79gXNq_wrapper {
  width: 640px;
  min-height: 640px;
  background: var(--color-layer-1);
  border-radius: 16px;
  flex-direction: column;
  padding: 104px 120px;
  display: flex;
  position: relative;
}

._79gXNq_wrapper > * {
  margin-bottom: 16px;
}

._79gXNq_backButton {
  color: var(--color-text);
  align-items: center;
  display: flex;
  position: absolute;
  top: 24px;
  left: 16px;
}

._79gXNq_backButton:active {
  color: var(--color-primary-pressed);
}

._79gXNq_backButton:not(:disabled):hover {
  color: var(--color-primary-hover);
  text-decoration: none;
}

._79gXNq_subtitle {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

._79gXNq_title {
  font: var(--font-headline-2);
}

._79gXNq_title + ._79gXNq_subtitle {
  margin-top: -12px;
}

._79gXNq_strong {
  font-weight: 600;
}

@keyframes oZB0cq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes oZB0cq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.oZB0cq_wrapper {
  justify-content: space-between;
  display: flex;
}

.oZB0cq_input {
  width: 44px;
  height: 44px;
  text-align: center;
  color: var(--color-text-primary);
  border: 1px solid var(--color-border);
  caret-color: var(--color-primary);
  -webkit-appearance: textfield;
  appearance: textfield;
  background: none;
  border-radius: 8px;
  outline: 3px solid #0000;
}

.oZB0cq_input:focus {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.oZB0cq_input::-webkit-outer-spin-button, .oZB0cq_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.oZB0cq_errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
  margin-left: 2px;
}

.KTc0zW_message {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
}

.KTc0zW_link:not(:disabled):hover {
  text-decoration: none;
}

.bsdY_q_link:not(:disabled):hover {
  text-decoration: none;
}

@keyframes Tm4JdG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Tm4JdG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Tm4JdG_withTable {
  height: 100%;
}

@keyframes l-2Klq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes l-2Klq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.l-2Klq_applicationsTable {
  color: var(--color-text);
  flex: 1;
  margin-bottom: 24px;
}

.l-2Klq_applicationsTable .l-2Klq_filter {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.l-2Klq_applicationsTable tbody td {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.l-2Klq_icon {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

@keyframes HOGL5a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes HOGL5a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.HOGL5a_sourceScopeItem {
  align-items: center;
  padding: 6px 16px 6px 46px;
  display: flex;
}

.HOGL5a_sourceScopeItem .HOGL5a_name {
  font: var(--font-body-2);
  background: var(--color-neutral-95);
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  border-radius: 6px;
  padding: 4px 8px;
  overflow: hidden;
}

.HOGL5a_sourceScopeItem .HOGL5a_icon {
  color: var(--color-text-secondary);
}

@keyframes kfGtNa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes kfGtNa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.kfGtNa_resourceItem {
  -webkit-user-select: none;
  user-select: none;
}

.kfGtNa_resourceItem .kfGtNa_title {
  align-items: center;
  padding: 6px 16px;
  display: flex;
}

.kfGtNa_resourceItem .kfGtNa_title .kfGtNa_resource {
  cursor: pointer;
  flex: 1;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.kfGtNa_resourceItem .kfGtNa_title .kfGtNa_resource .kfGtNa_caret {
  margin-right: 8px;
}

.kfGtNa_resourceItem .kfGtNa_title .kfGtNa_resource .kfGtNa_name {
  font: var(--font-label-2);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.kfGtNa_resourceItem .kfGtNa_title .kfGtNa_resource .kfGtNa_scopeInfo {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  flex-shrink: 0;
  margin-left: 8px;
}

.kfGtNa_resourceItem .kfGtNa_invisible {
  display: none;
}

.lgwVMa_search {
  width: 100%;
}

.lgwVMa_icon {
  color: var(--color-text-secondary);
}

@keyframes WBz-qW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes WBz-qW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.WBz-qW_targetScopeItem {
  align-items: center;
  padding: 6px 12px 6px 16px;
  display: flex;
}

.WBz-qW_targetScopeItem .WBz-qW_title {
  font: var(--font-body-2);
  flex: 1 1 0;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.WBz-qW_targetScopeItem .WBz-qW_title .WBz-qW_name {
  max-width: 204px;
  background: var(--color-neutral-95);
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 6px;
  flex-shrink: 0;
  padding: 4px 8px;
  overflow: hidden;
}

.WBz-qW_targetScopeItem .WBz-qW_title .WBz-qW_resourceName {
  color: var(--color-text-secondary);
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0 8px;
  overflow: hidden;
}

.WBz-qW_targetScopeItem:hover {
  background: var(--color-hover);
}

._5nfMBq_added {
  font: var(--font-label-2);
}

@keyframes _4f4-IW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _4f4-IW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._4f4-IW_roleScopesTransfer {
  height: 360px;
}

@keyframes FlVJdW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes FlVJdW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.FlVJdW_usersTable {
  color: var(--color-text);
  flex: 1;
  margin-bottom: 24px;
}

.FlVJdW_usersTable .FlVJdW_filter {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.FlVJdW_usersTable tbody td {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@keyframes IGa9CW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes IGa9CW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.IGa9CW_roleTypes {
  gap: 24px;
  display: flex;
}

.IGa9CW_roleTypeSelectionSwitch {
  margin-top: 8px;
}

.IGa9CW_trailingIcon {
  width: 16px;
  height: 16px;
}

.IGa9CW_trailingIcon > svg {
  width: 100%;
  height: 100%;
}

.IGa9CW_proTag {
  align-items: center;
  margin: 0;
  display: flex;
}

@keyframes EXktkG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes EXktkG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.EXktkG_type, .EXktkG_description {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.od0qPW_defaultIcon {
  color: var(--color-text-secondary);
}

.od0qPW_disabledDefaultIcon {
  color: var(--color-neutral-70);
}

@keyframes R5BQyG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes R5BQyG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.R5BQyG_preview {
  min-width: 480px;
  background: var(--color-layer-1);
  border-radius: 16px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.R5BQyG_preview .R5BQyG_header {
  margin: 24px 24px 4px;
  display: flex;
}

.R5BQyG_preview .R5BQyG_header .R5BQyG_title {
  font: var(--font-label-1);
  flex: 1;
}

.R5BQyG_preview .R5BQyG_header .R5BQyG_selects {
  align-items: center;
  display: flex;
}

.R5BQyG_preview .R5BQyG_header .R5BQyG_selects .R5BQyG_language {
  width: 112px;
}

.R5BQyG_preview .R5BQyG_header .R5BQyG_selects > :not(:last-child) {
  margin-right: 8px;
}

.R5BQyG_preview .R5BQyG_nav {
  border-bottom: unset;
  margin-top: 24px;
  padding: 0 24px 1px;
}

@keyframes yNlmJq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes yNlmJq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.yNlmJq_tabContent > :not(:first-child) {
  margin-top: 12px;
}

@keyframes MAlhiG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes MAlhiG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.MAlhiG_title {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--color-neutral-variant-60);
}

@keyframes _66Qfba_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _66Qfba_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._66Qfba_container {
  flex-direction: column;
  display: flex;
}

._66Qfba_uploader {
  gap: 8px;
  display: flex;
}

._66Qfba_uploader ._66Qfba_logoUploader {
  flex: 2 0;
}

._66Qfba_uploader ._66Qfba_faviconUploader {
  flex: 1;
}

._66Qfba_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 4px;
}

._66Qfba_error {
  color: var(--color-error);
}

@keyframes IXqKQG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes IXqKQG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.IXqKQG_darkModeTip {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  align-items: baseline;
  margin-top: 4px;
  display: flex;
}

._84-wRa_customCssCodeEditor {
  max-height: calc(100vh - 260px);
  min-height: 132px;
}

@keyframes mFK_Wq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes mFK_Wq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.mFK_Wq_languageDetails {
  flex-direction: column;
  display: flex;
}

.mFK_Wq_languageDetails .mFK_Wq_title {
  font: var(--font-title-1);
  color: var(--color-text);
  flex-shrink: 0;
  justify-content: space-between;
  padding: 24px 20px;
  display: flex;
}

.mFK_Wq_languageDetails .mFK_Wq_title .mFK_Wq_languageInfo {
  align-items: center;
  display: flex;
}

.mFK_Wq_languageDetails .mFK_Wq_title .mFK_Wq_languageInfo > span {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin: 8px;
}

.mFK_Wq_languageDetails .mFK_Wq_container {
  border-top: 1px solid var(--color-divider);
  flex-direction: column;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.mFK_Wq_languageDetails .mFK_Wq_container .mFK_Wq_tableWrapper {
  padding: 0;
}

.mFK_Wq_languageDetails .mFK_Wq_container .mFK_Wq_content {
  flex: 1;
}

.mFK_Wq_languageDetails .mFK_Wq_container .mFK_Wq_content .mFK_Wq_customValuesColumn {
  align-items: center;
  display: flex;
}

.mFK_Wq_languageDetails .mFK_Wq_container .mFK_Wq_content .mFK_Wq_clearButton {
  margin-left: 4px;
}

.mFK_Wq_languageDetails .mFK_Wq_container .mFK_Wq_content .mFK_Wq_clearIcon {
  width: 16px;
  height: 16px;
}

.mFK_Wq_languageDetails .mFK_Wq_container .mFK_Wq_content .mFK_Wq_sectionTitle {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
  font: var(--font-section-head-2);
  color: var(--color-neutral-variant-60);
  background-color: var(--color-layer-light);
}

.mFK_Wq_languageDetails .mFK_Wq_container .mFK_Wq_content .mFK_Wq_sectionDataKey {
  font: var(--font-body-2);
  color: var(--color-text);
  padding: 16px 20px;
}

.mFK_Wq_languageDetails .mFK_Wq_container .mFK_Wq_content .mFK_Wq_sectionBuiltInText {
  border: 1px solid var(--color-border);
  color: var(--color-text);
  background: var(--color-layer-2);
  border-radius: 6px;
  padding: 8px 12px;
}

.mFK_Wq_languageDetails .mFK_Wq_container .mFK_Wq_content .mFK_Wq_inputCell {
  position: relative;
}

.mFK_Wq_languageDetails .mFK_Wq_container .mFK_Wq_content .mFK_Wq_sectionInputArea {
  position: absolute;
  inset: 8px 20px;
}

.mFK_Wq_languageDetails .mFK_Wq_container .mFK_Wq_content table {
  border: none;
}

.mFK_Wq_languageDetails .mFK_Wq_container .mFK_Wq_content table thead tr > th {
  font: var(--font-label-2);
  color: var(--color-text);
  background-color: var(--color-layer-1);
  border-bottom: 1px solid var(--color-divider);
  padding: 4px 20px;
}

.mFK_Wq_languageDetails .mFK_Wq_container .mFK_Wq_content table tbody tr td {
  word-wrap: break-word;
  border: none;
  padding: 8px 20px;
}

.mFK_Wq_languageDetails .mFK_Wq_container .mFK_Wq_footer {
  border-top: 1px solid var(--color-divider);
  height: 60px;
  flex-direction: row-reverse;
  flex-shrink: 0;
  align-items: center;
  padding-right: 20px;
  display: flex;
}

@keyframes hqOtrW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes hqOtrW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.hqOtrW_languageSelector .hqOtrW_input {
  position: relative;
}

.hqOtrW_languageSelector .hqOtrW_input .hqOtrW_addLanguageButton {
  width: 100%;
  height: 38px;
  background: unset;
}

.hqOtrW_languageSelector .hqOtrW_input .hqOtrW_buttonIcon {
  color: var(--color-text-secondary);
}

.hqOtrW_languageSelector .hqOtrW_dropDown {
  width: 168px;
  background: var(--color-float);
  border: 1px solid var(--color-divider);
  max-height: 288px;
  box-shadow: var(--shadow-2);
  border-radius: 8px;
  margin: 4px 0;
  padding: 4px;
  position: absolute;
  overflow-y: auto;
}

.hqOtrW_languageSelector .hqOtrW_dropDown .hqOtrW_dropDownItem {
  width: 100%;
  cursor: pointer;
  font: var(--font-body-2);
  border-radius: 8px;
  padding: 8px;
}

.hqOtrW_languageSelector .hqOtrW_dropDown .hqOtrW_dropDownItem:hover {
  background: var(--color-hover);
}

.hqOtrW_languageSelector .hqOtrW_dropDown .hqOtrW_dropDownItem .hqOtrW_languageName {
  color: var(--color-text);
}

.hqOtrW_languageSelector .hqOtrW_dropDown .hqOtrW_dropDownItem .hqOtrW_languageTag {
  color: var(--color-text-secondary);
}

.hqOtrW_languageSelector .hqOtrW_dropDown .hqOtrW_noDataPlaceholder {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  -webkit-user-select: none;
  user-select: none;
  padding: 8px;
}

@keyframes taG0zG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes taG0zG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.taG0zG_languageItem {
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 6px 12px;
}

.taG0zG_languageItem .taG0zG_languageName {
  color: var(--color-text);
  font: var(--font-label-2);
}

.taG0zG_languageItem .taG0zG_languageTag {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
}

.taG0zG_languageItem:hover {
  background-color: var(--color-hover-variant);
}

.taG0zG_languageItem.taG0zG_selected {
  background-color: var(--color-focused-variant);
}

.taG0zG_languageItem.taG0zG_selected .taG0zG_languageName, .taG0zG_languageItem.taG0zG_selected .taG0zG_languageTag {
  color: var(--color-text-link);
}

@keyframes G0xB0W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes G0xB0W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.G0xB0W_languageNav {
  width: 185px;
  background-color: var(--color-layer-light);
  border-right: 1px solid var(--color-divider);
  flex-direction: column;
  flex-shrink: 0;
  padding: 12px 8px;
  display: flex;
}

.G0xB0W_languageNav .G0xB0W_languageItemList {
  flex: 1;
  margin-top: 12px;
  overflow-y: auto;
}

@keyframes _0c66Ga_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _0c66Ga_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._0c66Ga_modalOverlay {
  background: var(--color-overlay);
  position: fixed;
  inset: 0;
}

._0c66Ga_modalOverlay ._0c66Ga_modalContent {
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  position: fixed;
  inset: 0;
}

._0c66Ga_modalOverlay ._0c66Ga_editor {
  max-width: 1224px;
  max-height: 85vh;
  box-shadow: var(--shadow-3);
  flex-direction: column;
  margin: 0 auto;
  display: flex;
  overflow: hidden;
}

._0c66Ga_modalOverlay ._0c66Ga_editor ._0c66Ga_header {
  flex-shrink: 0;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  display: flex;
}

._0c66Ga_modalOverlay ._0c66Ga_editor ._0c66Ga_content {
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  flex-direction: row;
  flex: 1;
  display: flex;
  overflow: hidden;
}

@keyframes _9_FG3q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _9_FG3q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._9_FG3q_title {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--color-neutral-variant-60);
}

._9_FG3q_manageLanguageButton {
  margin-top: 4px;
}

._9_FG3q_defaultLanguageDescription {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  padding-top: 8px;
}

@keyframes _2v160W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _2v160W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._2v160W_formFieldDescription {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin: 4px 0 8px;
}

@keyframes _4qS6hG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _4qS6hG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._4qS6hG_minLength > div[class*="container"] {
  max-width: 156px;
}

._4qS6hG_characterTypes {
  gap: 24px;
  display: flex;
}

._4qS6hG_textarea {
  margin-top: 8px;
  margin-left: 28px;
}

._4qS6hG_passwordOption {
  font: var(--font-body-2);
  margin-top: 12px;
}

._4qS6hG_passwordOption > div[role="checkbox"] {
  align-items: flex-start;
}

._4qS6hG_passwordOption ._4qS6hG_label {
  white-space: normal;
}

._4qS6hG_passwordOption ._4qS6hG_label ._4qS6hG_title {
  color: var(--color-text);
}

._4qS6hG_passwordOption ._4qS6hG_label ._4qS6hG_description {
  color: var(--color-text-secondary);
  margin-top: 2px;
}

@keyframes K8RUKW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes K8RUKW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.K8RUKW_setUpHint {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 8px;
}

.K8RUKW_setUpHint .K8RUKW_setup {
  margin: 0 4px;
}

._2SKKNa_dragging ._2SKKNa_item:hover {
  background: initial;
}

@keyframes myI5la_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes myI5la_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.myI5la_draggleItemContainer {
  transform: translate(0);
}

.myI5la_signInMethodItem {
  align-items: center;
  margin: 8px 0;
  display: flex;
}

.myI5la_signInMethod {
  height: 44px;
  width: 100%;
  background-color: var(--color-layer-2);
  cursor: move;
  color: var(--color-text);
  border-radius: 8px;
  align-items: center;
  margin-right: 8px;
  padding: 12px 8px;
  display: flex;
}

.myI5la_signInMethod.myI5la_error {
  outline: 1px solid var(--color-error);
}

.myI5la_signInMethod .myI5la_identifier {
  width: 130px;
  font: var(--font-label-2);
  align-items: center;
  display: flex;
}

.myI5la_signInMethod .myI5la_authentication {
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  display: flex;
}

.myI5la_signInMethod .myI5la_authentication .myI5la_swapButton {
  flex-grow: 1;
  justify-content: center;
  display: flex;
}

.myI5la_signInMethod .myI5la_authentication.myI5la_verifyCodePrimary {
  flex-direction: row-reverse;
}

.myI5la_signInMethod .myI5la_draggableIcon {
  color: var(--color-text-secondary);
  margin-right: 4px;
}

.myI5la_plusIcon {
  color: var(--color-text-secondary);
}

.myI5la_addAnotherSignInMethodDropdown {
  min-width: 208px;
}

.myI5la_addSignInMethodDropDown {
  min-width: unset;
}

.myI5la_errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
}

@keyframes ImK0rq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ImK0rq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ImK0rq_notice {
  margin: 8px 0;
}

@keyframes aN8gOa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes aN8gOa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.aN8gOa_title {
  color: var(--color-text-secondary);
  font: var(--font-section-head-1);
  letter-spacing: .1em;
  text-transform: uppercase;
  color: var(--color-neutral-variant-60);
}

.aN8gOa_socialOnlyDescription {
  color: var(--color-text-secondary);
  margin-left: 4px;
}

.aN8gOa_selections > :not(:first-child) {
  margin-top: 12px;
}

@keyframes NCe_Nq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes NCe_Nq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.NCe_Nq_dropdown {
  min-width: unset;
}

.NCe_Nq_addAnotherDropdown {
  min-width: 208px;
}

.NCe_Nq_plusIcon {
  color: var(--color-text-secondary);
}

.NCe_Nq_title {
  align-items: center;
  display: flex;
}

.NCe_Nq_title .NCe_Nq_name {
  font: var(--font-body-2);
  margin-left: 12px;
}

.NCe_Nq_title .NCe_Nq_icon {
  width: 16px;
  height: 16px;
  object-fit: cover;
  color: var(--color-text-secondary);
  margin-left: 4px;
}

@keyframes cL_q8W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes cL_q8W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.cL_q8W_item {
  align-items: center;
  margin: 8px 0;
  display: flex;
}

.cL_q8W_item .cL_q8W_info {
  height: 44px;
  width: 100%;
  background-color: var(--color-layer-2);
  cursor: move;
  color: var(--color-text);
  border-radius: 8px;
  align-items: center;
  margin-right: 8px;
  padding: 12px 8px;
  display: flex;
}

.cL_q8W_item .cL_q8W_info .cL_q8W_draggableIcon {
  color: var(--color-text-secondary);
  margin-right: 12px;
}

.cL_q8W_item .cL_q8W_info .cL_q8W_name {
  font: var(--font-label-2);
  margin-left: 12px;
}

.cL_q8W_item .cL_q8W_info .cL_q8W_icon {
  width: 16px;
  height: 16px;
  object-fit: cover;
  color: var(--color-text-secondary);
  margin-left: 4px;
}

@keyframes Ob65FG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Ob65FG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Ob65FG_draggleItemContainer {
  transform: translate(0);
}

.Ob65FG_setUpHint {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 8px;
}

.Ob65FG_setUpHint .Ob65FG_setup {
  margin: 0 4px;
}

@keyframes _6ugWbW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _6ugWbW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._6ugWbW_title {
  font: var(--font-title-3);
}

._6ugWbW_list {
  padding-left: 24px;
}

._6ugWbW_list li {
  word-break: break-word;
}

._6ugWbW_red {
  background-color: #dd37304d;
}

._6ugWbW_green {
  background-color: #68be6c66;
}

@keyframes oxLM0a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes oxLM0a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.oxLM0a_container .oxLM0a_description {
  font: var(--font-body-2);
}

.oxLM0a_container .oxLM0a_content {
  font: var(--font-body-2);
  border-radius: 8px;
  justify-content: space-between;
  align-items: stretch;
  column-gap: 12px;
  margin-top: 12px;
  display: flex;
}

.oxLM0a_container .oxLM0a_content .oxLM0a_section {
  background: var(--color-layer-2);
  color: var(--color-text);
  border-radius: 8px;
  flex: 1;
  padding: 20px;
}

.oxLM0a_container .oxLM0a_content .oxLM0a_section .oxLM0a_title {
  font: var(--font-title-2);
  margin: 4px 0;
}

@keyframes KVwOca_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes KVwOca_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.KVwOca_tabs {
  margin: 16px 0;
}

.KVwOca_content {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.KVwOca_content .KVwOca_contentTop {
  flex: 1;
  margin-bottom: 24px;
  display: flex;
}

.KVwOca_content .KVwOca_contentTop.KVwOca_withSubmitActionBar {
  margin-bottom: 12px;
}

.KVwOca_content .KVwOca_contentTop > * {
  min-width: 510px;
  flex: 1;
}

.KVwOca_content .KVwOca_contentTop .KVwOca_form {
  margin-right: 12px;
}

.KVwOca_content .KVwOca_contentTop .KVwOca_preview {
  align-self: flex-start;
  position: sticky;
  top: 16px;
}

@keyframes Wc-taa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Wc-taa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Wc-taa_container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.Wc-taa_container .Wc-taa_cardTitle {
  flex-shrink: 0;
}

.Wc-taa_container .Wc-taa_error {
  margin-top: 16px;
}

.Wc-taa_container .Wc-taa_tabs {
  margin: 16px 0;
}

.Wc-taa_container .Wc-taa_content {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.Wc-taa_container .Wc-taa_content .Wc-taa_contentTop {
  flex: 1;
  margin-bottom: 24px;
  display: flex;
}

.Wc-taa_container .Wc-taa_content .Wc-taa_contentTop.Wc-taa_withSubmitActionBar {
  margin-bottom: 12px;
}

.Wc-taa_container .Wc-taa_content .Wc-taa_contentTop > * {
  min-width: 510px;
  flex: 1;
}

.Wc-taa_container .Wc-taa_content .Wc-taa_contentTop .Wc-taa_form {
  margin-right: 12px;
}

.Wc-taa_container .Wc-taa_content .Wc-taa_contentTop .Wc-taa_preview {
  align-self: flex-start;
  position: sticky;
  top: 16px;
}

@keyframes L9Tt1a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes L9Tt1a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.L9Tt1a_container .L9Tt1a_tabBar {
  width: 100%;
  height: 28px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.L9Tt1a_container .L9Tt1a_tabBar:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite L9Tt1a_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_card {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  padding: 24px;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_card > :not(:first-child) {
  margin-top: 24px;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_card .L9Tt1a_title {
  background-color: var(--color-layer-2);
  width: 80px;
  height: 16px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_card .L9Tt1a_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite L9Tt1a_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_card .L9Tt1a_field {
  background-color: var(--color-layer-2);
  width: 100%;
  height: 44px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_card .L9Tt1a_field:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite L9Tt1a_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_card:not(:first-child) {
  margin-top: 12px;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview {
  flex: 1;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_header {
  width: 100%;
  height: 103px;
  background-color: var(--color-layer-1);
  border-radius: 16px 16px 0 0;
  padding: 24px;
  display: flex;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_header .L9Tt1a_info .L9Tt1a_title {
  width: 113px;
  height: 20px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_header .L9Tt1a_info .L9Tt1a_title:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite L9Tt1a_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_header .L9Tt1a_info .L9Tt1a_subtitle {
  width: 177px;
  height: 16px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 16px;
  position: relative;
  overflow: hidden;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_header .L9Tt1a_info .L9Tt1a_subtitle:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite L9Tt1a_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_header .L9Tt1a_button {
  width: 104px;
  height: 30px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-left: 10px;
  position: relative;
  overflow: hidden;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_header .L9Tt1a_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite L9Tt1a_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_previewContent {
  background: var(--color-surface-variant);
  height: 450px;
  border-radius: 0 0 16px 16px;
  padding: 24px;
  overflow: hidden;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_previewContent .L9Tt1a_mobile {
  width: 375px;
  height: 667px;
  background: var(--color-surface);
  transform-origin: top;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 24px;
  display: flex;
  transform: scale(.6);
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_previewContent .L9Tt1a_mobile .L9Tt1a_logo {
  width: 64px;
  height: 64px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 64px;
  position: relative;
  overflow: hidden;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_previewContent .L9Tt1a_mobile .L9Tt1a_logo:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite L9Tt1a_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_previewContent .L9Tt1a_mobile .L9Tt1a_slogan {
  width: 177px;
  height: 16px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin: 12px 0 40px;
  position: relative;
  overflow: hidden;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_previewContent .L9Tt1a_mobile .L9Tt1a_slogan:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite L9Tt1a_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_previewContent .L9Tt1a_mobile .L9Tt1a_field {
  width: 100%;
  height: 36px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 12px;
  position: relative;
  overflow: hidden;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_previewContent .L9Tt1a_mobile .L9Tt1a_field:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite L9Tt1a_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_previewContent .L9Tt1a_mobile .L9Tt1a_button {
  width: 100%;
  height: 36px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 40px;
  position: relative;
  overflow: hidden;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_previewContent .L9Tt1a_mobile .L9Tt1a_button:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite L9Tt1a_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_previewContent .L9Tt1a_mobile .L9Tt1a_social {
  width: 180px;
  height: 24px;
  background-color: var(--color-layer-2);
  border-radius: 8px;
  margin-top: 12px;
  position: relative;
  overflow: hidden;
}

.L9Tt1a_container .L9Tt1a_content .L9Tt1a_preview .L9Tt1a_previewContent .L9Tt1a_mobile .L9Tt1a_social:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite L9Tt1a_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes f_EMiW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes f_EMiW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.f_EMiW_container {
  background-color: var(--color-surface-1);
  height: 100vh;
  flex-direction: column;
  display: flex;
}

.f_EMiW_container .f_EMiW_header {
  background-color: var(--color-layer-1);
  height: 64px;
  flex-shrink: 0;
  align-items: center;
  padding: 0 84px 0 8px;
  display: flex;
}

.f_EMiW_container .f_EMiW_header button {
  margin-left: 16px;
}

.f_EMiW_container .f_EMiW_header .f_EMiW_separator {
  border-left: 1px solid var(--color-border);
  width: 0;
  height: 20px;
  margin: 0 20px 0 16px;
}

.f_EMiW_container .f_EMiW_header .f_EMiW_closeIcon {
  color: var(--color-text-secondary);
}

.f_EMiW_container .f_EMiW_content {
  flex-direction: column;
  flex: 1;
  align-items: center;
  overflow-y: auto;
}

.f_EMiW_container .f_EMiW_content .f_EMiW_reminder {
  width: 550px;
  margin: 8px auto 32px;
}

.f_EMiW_container .f_EMiW_content > .f_EMiW_form {
  padding: 24px 68px 108px;
  position: relative;
}

.f_EMiW_container .f_EMiW_main {
  max-width: 1168px;
  min-width: 560px;
  align-items: flex-start;
  margin: 0 auto;
  display: flex;
}

.f_EMiW_container .f_EMiW_main .f_EMiW_form {
  flex: 1;
}

.f_EMiW_container .f_EMiW_main .f_EMiW_form > :not(:first-child) {
  margin-top: 12px;
}

.f_EMiW_container .f_EMiW_main .f_EMiW_preview {
  flex: 1;
  margin-left: 32px;
  position: sticky;
  top: 16px;
}

.f_EMiW_container .f_EMiW_footer {
  background: var(--color-layer-1);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.f_EMiW_container .f_EMiW_footer .f_EMiW_footerContent {
  max-width: 1168px;
  min-width: 560px;
  justify-content: right;
  margin: 0 auto;
  padding: 24px 0;
  display: flex;
}

@keyframes VDVoSq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes VDVoSq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.VDVoSq_container {
  min-height: 528px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.VDVoSq_content {
  justify-content: center;
  align-items: center;
  display: flex;
}

.VDVoSq_content .VDVoSq_icon {
  width: 200px;
  height: 200px;
}

.VDVoSq_content .VDVoSq_wrapper {
  margin-left: 24px;
}

.VDVoSq_content .VDVoSq_title {
  font: var(--font-label-2);
}

.VDVoSq_content .VDVoSq_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  max-width: 350px;
  margin-bottom: 24px;
}

@keyframes D9ZC6a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes D9ZC6a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.D9ZC6a_container {
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
}

.D9ZC6a_cardTitle {
  flex-shrink: 0;
}

.D9ZC6a_tabs {
  margin: 16px 0;
}

@keyframes uOOVxa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes uOOVxa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.uOOVxa_container {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

@keyframes nXAoga_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes nXAoga_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.nXAoga_container > :not(:first-child) {
  margin: 24px 0 0;
}

.nXAoga_description {
  font: var(--font-body-2);
}

.nXAoga_list {
  background-color: var(--color-layer-2);
  border-radius: 12px;
  padding: 16px;
  list-style-position: inside;
}

.nXAoga_list > li:not(:first-child) {
  margin-top: 12px;
}

.nXAoga_buttonLink {
  text-decoration: none;
}

@keyframes m-BY5a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes m-BY5a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.m-BY5a_planInfo {
  margin-bottom: 24px;
}

.m-BY5a_planInfo .m-BY5a_name {
  font: var(--font-title-1);
}

.m-BY5a_planInfo .m-BY5a_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 4px;
}

.m-BY5a_notification {
  margin-top: 24px;
}

@keyframes _3owZga_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3owZga_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3owZga_check {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

._3owZga_check svg + span {
  margin-left: 4px;
}

@keyframes wBb2ka_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes wBb2ka_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.wBb2ka_quotaValue {
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
}

.wBb2ka_quotaValue.wBb2ka_leftAligned {
  justify-content: flex-start;
}

.wBb2ka_extraInfo {
  font: var(--font-body-3);
  color: var(--color-text-secondary);
}

@keyframes iMtKeW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes iMtKeW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.iMtKeW_container {
  background-color: var(--color-layer-1);
  border-radius: 16px;
  margin-bottom: 12px;
  padding: 12px;
}

.iMtKeW_container table {
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  border: none;
}

.iMtKeW_container table thead {
  border-radius: 6px;
  padding: 0 12px;
}

.iMtKeW_container table thead tr th {
  font: var(--font-title-2);
  padding: 12px;
}

.iMtKeW_container table tbody tr td {
  font: var(--font-body-2);
  text-align: center;
  padding: 12px;
}

.iMtKeW_container table tbody .iMtKeW_groupLabel {
  font: var(--font-title-2);
  text-align: left;
  background-color: var(--color-layer-light);
  border-radius: 6px;
}

.iMtKeW_container table tbody .iMtKeW_quotaKeyColumn {
  text-align: left;
  padding: 16px 24px;
}

@keyframes SQ3WJa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes SQ3WJa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.SQ3WJa_quotaListItem {
  font: var(--font-body-2);
  margin-left: 16px;
}

.SQ3WJa_quotaListItem .SQ3WJa_content {
  align-items: center;
  gap: 8px;
  display: flex;
}

.SQ3WJa_quotaListItem .SQ3WJa_icon {
  width: 16px;
  height: 16px;
}

.SQ3WJa_quotaListItem .SQ3WJa_icon.SQ3WJa_notCapable {
  color: var(--color-error);
}

.SQ3WJa_quotaListItem .SQ3WJa_lineThrough {
  text-decoration: line-through;
}

.SQ3WJa_quotaListItem.SQ3WJa_withIcon {
  margin-left: unset;
  list-style-type: none;
}

@keyframes THG6CW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes THG6CW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.THG6CW_planQuotaList {
  margin-block: 0;
  padding-inline: 0;
}

.THG6CW_planQuotaList > li:not(:first-child) {
  margin-top: 12px;
}

@keyframes iok-RW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes iok-RW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.iok-RW_container {
  background-color: var(--color-layer-2);
  border-radius: 8px;
  flex: 1;
  padding: 20px;
}

.iok-RW_container .iok-RW_title {
  font: var(--font-title-2);
  margin-bottom: 12px;
}

@keyframes _5Fvp8W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _5Fvp8W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._5Fvp8W_container > :not(:first-child) {
  margin: 24px 0 0;
}

._5Fvp8W_description {
  font: var(--font-body-2);
}

._5Fvp8W_content {
  justify-content: space-between;
  gap: 12px;
  display: flex;
}

@keyframes _7NtmNG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _7NtmNG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._7NtmNG_container {
  background-color: var(--color-layer-1);
  box-shadow: var(--shadow-2);
  width: 100%;
  border-radius: 12px 12px 0 0;
  justify-content: space-between;
  padding: 16px 12px 16px 16px;
  display: flex;
  position: sticky;
  bottom: 0;
}

._7NtmNG_container > * {
  flex: 1;
  justify-content: center;
  display: flex;
}

._7NtmNG_buttonLink {
  text-decoration: none;
}

@keyframes Av1axa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Av1axa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Av1axa_container {
  position: relative;
}

.Av1axa_container > div:not(:last-child) {
  margin-bottom: 16px;
}

@keyframes Wld4Dq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Wld4Dq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Wld4Dq_deletionButtonContainer {
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  align-items: center;
  padding: 16px;
  display: flex;
}

.Wld4Dq_deletionButtonContainer .Wld4Dq_description {
  font: var(--font-body-2);
  flex: 1;
  margin-right: 8px;
}

@keyframes yuWCpq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes yuWCpq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.yuWCpq_deleteConfirmModal > :not(:first-child) {
  margin-top: 24px;
}

.yuWCpq_deleteConfirmModal .yuWCpq_content > p {
  margin-bottom: 20px;
}

.yuWCpq_deleteConfirmModal .yuWCpq_description {
  font: var(--font-body-2);
  gap: 8px;
}

.yuWCpq_deleteConfirmModal .yuWCpq_bold {
  font: var(--font-title-3);
}

.yuWCpq_deleteConfirmModal .yuWCpq_highlight {
  font: var(--font-label-2);
  color: var(--color-primary-80);
}

@keyframes IFpuaG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes IFpuaG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.IFpuaG_container {
  border: 1px solid var(--color-divider);
  border-radius: 12px;
  padding: 22px 24px;
}

.IFpuaG_description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 8px;
}

@keyframes _3Smarq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3Smarq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3Smarq_container {
  border: 1px solid var(--color-divider);
  border-radius: 12px;
  padding: 22px 24px;
}

._3Smarq_region {
  font: var(--font-label-1);
}

._3Smarq_region ._3Smarq_icon {
  margin-right: 8px;
}

._3Smarq_regionTip {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 8px;
}

@keyframes LT-eRq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes LT-eRq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.LT-eRq_idWrapper {
  font: var(--font-body-2);
  color: var(--color-text);
  background-color: var(--color-bg-info-tag);
  border-radius: 6px;
  padding: 0 8px;
  font-family: Roboto Mono, monospace;
  line-height: 26px;
  display: inline-block;
}

.LT-eRq_deleteIcon {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.LT-eRq_rotateKey {
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  align-items: center;
  gap: 8px;
  padding: 16px;
  display: flex;
}

.LT-eRq_rotateKey .LT-eRq_description {
  font: var(--font-body-2);
  flex: 1;
}

.LT-eRq_bone {
  background-color: var(--color-layer-2);
  height: 26px;
  max-width: 344px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.LT-eRq_bone:after {
  content: "";
  background-image: linear-gradient(90deg, rgba(var(--color-skeleton-shimmer-rgb), 0) 0, rgba(var(--color-skeleton-shimmer-rgb), .2) 20%, rgba(var(--color-skeleton-shimmer-rgb), .5) 60%, var(--color-layer-2));
  animation: 2s infinite LT-eRq_shimmer;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
}

@keyframes tIHRNq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes tIHRNq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.tIHRNq_container {
  min-width: 540px;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 8px;
  display: flex;
}

.tIHRNq_container.tIHRNq_withSubmitActionBar {
  padding-bottom: 0;
}

.tIHRNq_container > :not(:first-child) {
  margin-top: 16px;
}

.tIHRNq_container .tIHRNq_fields {
  flex-grow: 1;
}

.tIHRNq_container .tIHRNq_fields > :not(:first-child) {
  margin-top: 16px;
}

@keyframes Ju3gQq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Ju3gQq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Ju3gQq_container {
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  align-items: center;
  gap: 24px;
  padding: 20px 24px;
  display: flex;
}

.Ju3gQq_container .Ju3gQq_domain {
  font: var(--font-title-2);
}

@keyframes SzQhjG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes SzQhjG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.SzQhjG_container > div:not(:first-child) {
  margin-top: 16px;
}

.SzQhjG_notes {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: 12px;
}

.SzQhjG_upsellNotification {
  margin-top: 16px;
}

@keyframes _3ZkXhW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3ZkXhW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3ZkXhW_content {
  font: var(--font-body-2);
}

._3ZkXhW_content ._3ZkXhW_info {
  background: var(--color-neutral-variant-90);
  border-radius: 8px;
  margin-top: 24px;
  padding: 20px;
}

._3ZkXhW_content ._3ZkXhW_info ._3ZkXhW_infoLine {
  font: var(--font-body-2);
  align-items: center;
  display: flex;
}

._3ZkXhW_content ._3ZkXhW_info ._3ZkXhW_infoLine:not(:last-child) {
  margin-bottom: 8px;
}

._3ZkXhW_content ._3ZkXhW_info ._3ZkXhW_infoLine ._3ZkXhW_infoContent {
  font: var(--font-label-2);
  padding-left: 4px;
}

._3ZkXhW_content ._3ZkXhW_info ._3ZkXhW_infoLine ._3ZkXhW_operation {
  padding-left: 4px;
}

._3ZkXhW_content ._3ZkXhW_info ._3ZkXhW_infoLine ._3ZkXhW_eyeIcon {
  color: var(--color-text-secondary);
}

@keyframes tRtS8W_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes tRtS8W_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.tRtS8W_resourceLayout {
  height: 100%;
}

@keyframes _0fF0WG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _0fF0WG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._0fF0WG_logs {
  flex: 1;
  margin-bottom: 24px;
  overflow-y: auto;
}

@keyframes ch4mzq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ch4mzq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ch4mzq_roles {
  flex-wrap: wrap;
  gap: 8px;
  display: flex;
}

.ch4mzq_rolesHeader {
  align-items: center;
  gap: 2px;
  display: flex;
}

@keyframes vRnWHW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes vRnWHW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.vRnWHW_rolesTable {
  color: var(--color-text);
  flex: 1;
  margin-bottom: 24px;
}

.vRnWHW_rolesTable .vRnWHW_filter {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.vRnWHW_rolesTable .vRnWHW_description {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@keyframes OrZ14a_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes OrZ14a_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.OrZ14a_fieldDescription {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin: 4px 0 8px;
}

@keyframes Q4-sxa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Q4-sxa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Q4-sxa_description {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-bottom: 4px;
}

.Q4-sxa_connectorName {
  align-items: center;
  display: flex;
}

.Q4-sxa_connectorName .Q4-sxa_icon {
  width: 20px;
  height: 20px;
  background: none;
}

.Q4-sxa_connectorName .Q4-sxa_icon > img {
  width: 100%;
  height: 100%;
}

.Q4-sxa_connectorName .Q4-sxa_name {
  margin-left: 12px;
}

.Q4-sxa_userId {
  font: var(--font-body-2);
  align-items: center;
  font-family: Roboto Mono, monospace;
  line-height: 32px;
  display: flex;
}

.Q4-sxa_userId span {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

@keyframes ZN2xuG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes ZN2xuG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.ZN2xuG_error {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 24px;
}

@keyframes _3gAc1q_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _3gAc1q_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._3gAc1q_containsTableLayout {
  height: 100%;
}

@keyframes Ridwca_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes Ridwca_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.Ridwca_logs {
  flex: 1;
  margin-bottom: 24px;
  overflow-y: auto;
}

.Ridwca_filter {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.Ridwca_filter .Ridwca_title {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
}

.Ridwca_filter .Ridwca_eventSelector {
  width: 300px;
  margin-left: 8px;
}

@keyframes pJ1nTW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes pJ1nTW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.pJ1nTW_formFieldDescription {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin: 4px 0 8px;
}

.pJ1nTW_errorMessage {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 16px;
}

@keyframes oTX9DG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes oTX9DG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.oTX9DG_field {
  margin-bottom: 12px;
}

.oTX9DG_field .oTX9DG_input {
  align-items: center;
  gap: 8px;
  display: flex;
}

.oTX9DG_field .oTX9DG_input .oTX9DG_keyInput {
  flex: 1;
}

.oTX9DG_field .oTX9DG_input .oTX9DG_valueInput {
  flex: 2;
}

.oTX9DG_field .oTX9DG_error {
  font: var(--font-body-2);
  color: var(--color-error);
  margin-top: 4px;
}

@keyframes -OPSRq_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes -OPSRq_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.-OPSRq_regenerateButton {
  margin-top: 12px;
}

.-OPSRq_signingKeyField {
  width: 100%;
}

@keyframes RV9bSa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes RV9bSa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.RV9bSa_container {
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  align-items: center;
  padding: 16px;
  display: flex;
}

.RV9bSa_description {
  font: var(--font-body-2);
  margin-right: 12px;
}

.RV9bSa_result {
  margin-top: 12px;
}

@keyframes _PklPG_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes _PklPG_shimmer {
  100% {
    transform: translateX(100%);
  }
}

._PklPG_icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

._PklPG_requests {
  font: var(--font-body-2);
  text-align: right;
  margin-right: 16px;
}

@keyframes lQ-hZW_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes lQ-hZW_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.lQ-hZW_content {
  flex-grow: 1;
  display: flex;
  overflow: hidden;
}

.lQ-hZW_overlayScrollbarWrapper {
  flex-grow: 1;
}

.lQ-hZW_main {
  width: 100%;
  height: 100%;
  padding: 0 24px 0 8px;
}

.lQ-hZW_main > *, .lQ-hZW_main [class="appInsightsWrapper"] > * {
  max-width: 1168px;
  min-width: 560px;
  margin: 0 auto;
}

@keyframes hwnTFa_rotating {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes hwnTFa_shimmer {
  100% {
    transform: translateX(100%);
  }
}

.hwnTFa_container {
  background-color: var(--color-surface-1);
  height: 100vh;
  min-width: 1280px;
  min-height: 806px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;
  flex-direction: column;
  display: flex;
}

.hwnTFa_container.hwnTFa_light {
  background-image: url("welcome.15c5fcff.svg");
}

.hwnTFa_container.hwnTFa_dark {
  background-image: url("welcome-dark.7e87b861.svg");
}

.hwnTFa_header {
  width: 100%;
  padding: 48px;
  position: absolute;
  top: 0;
  left: 0;
}

.hwnTFa_logo {
  height: 32px;
  width: auto;
  color: var(--color-text);
}

main {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.hwnTFa_placeholderTop {
  flex: 1;
}

.hwnTFa_placeholderBottom {
  flex: 2;
}

.hwnTFa_content {
  width: 585px;
  margin-left: 98px;
}

.hwnTFa_title {
  letter-spacing: -.25px;
  color: var(--color-text);
  margin: 8px 0 12px;
  font-size: 57px;
  font-weight: 600;
  line-height: 64px;
}

.hwnTFa_description {
  font: var(--font-body-1);
  color: var(--color-text-secondary);
  margin-bottom: 32px;
}

.hwnTFa_button {
  width: 400px;
}

/*# sourceMappingURL=index.a263ae0a.css.map */
